import type { MiddlewareFunction } from '@router/middlewarePipeline'
import { Role } from '@user-profile/config'

// Open page only if user with this roles has access

export const accessRoles = (roles: Role[]) => {
  const middleware: MiddlewareFunction = ({ to, next, store, nextMiddleware }) => {
    const redirect = () => next(to.meta.redirectToRoute || { name: 'Home' })
    if (!store.user.profile.data.value?.role) {
      return redirect
    }
    if (roles.includes(store.user.profile.data.value.role)) {
      return nextMiddleware()
    }
    return redirect()
  }

  return middleware
}

export const denyRoles = (roles: Role[]) => {
  const middleware: MiddlewareFunction = ({ to, next, store, nextMiddleware }) => {
    const redirect = () => next(to.meta.redirectToRoute || { name: 'Home' })
    if (!roles.includes(store.user.profile.data.value?.role ?? Role.Student)) {
      return nextMiddleware()
    }
    return redirect()
  }

  return middleware
}
