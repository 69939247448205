import type { MunicipalityRemoteData } from '@dict/services/types'
import BaseDictionary from '@dict/models/BaseDictionary'
import type { DadataInfo } from './DadataInfoInterface'
import Region from './Region'

export default class Municipality extends BaseDictionary implements DadataInfo {
  regionId: number | null
  fromDadata: boolean
  nameDadata: string
  regionObj: Region | null

  constructor(data: MunicipalityRemoteData) {
    super(data)
    this.regionId = data.region || null
    this.fromDadata = data.from_dadata ?? false
    this.nameDadata = data.name_dadata ?? ''
    this.regionObj = data.region_embeded ? new Region(data.region_embeded) : null
  }
}
