import { callApi } from '@core/api/apiWrapper'
import type { ApiAnswerStandart } from '@core/api/types'
import type {
  AuthLoginRemoteData,
  LoginFormData,
  // AuthVerifyRemoteData,
  // VerifyFormData,
  // ResetVerifyRemoteData,
  // ResetVerifyData,
  // AuthVerifyErrorRemoteData,
  RegisterCompleteFormData,
  RegisterFormData,
  RegisterRemoteData,
  RegisterCompleteRemoteData,
  RegisterCompleteErrorRemoteData,
  PasswordRecoveryFormData,
  PasswordRecoveryRemoteData,
  PasswordNewFormData,
  PasswordNewRemoteData,
  AuthLoginErrorRemoteData,
  RegisterErrorRemoteData
} from '@user-auth/services/types'
// import type { CallApiReturn } from '@core/api/types'

export const loginUser = async (data: LoginFormData) => {
  const response = await callApi<AuthLoginRemoteData, AuthLoginErrorRemoteData | string[]>({
    method: 'post',
    url: 'login/',
    data,
    authRequired: false
  })
  // const response = new Promise<
  //   CallApiReturn<Partial<AuthLoginRemoteData>, AuthLoginErrorRemoteData | string[]>
  // >(resolve => {
  //   setTimeout(() => {
  //     resolve({
  //       data: { need_token: true },
  //       errors: null,
  //       status: 200
  //     })
  //     // resolve({
  //     //   data: null,
  //     //   errors: ['Текст ошибки 1', 'Текст ошибки 2'],
  //     //   status: 403
  //     // })
  //   }, 400)
  // })
  return response
}

export const logoutUser = async () => {
  const response = await callApi<ApiAnswerStandart>({
    method: 'get',
    url: 'logout/'
  })
  return response
}

export const register = async (data: RegisterFormData) => {
  const response = await callApi<RegisterRemoteData, RegisterErrorRemoteData | string[]>({
    method: 'post',
    url: 'signup/',
    data,
    authRequired: false
  })
  return response
}

export const completeRegistration = async (formData: RegisterCompleteFormData) => {
  const data = {
    token: formData.token,
    password: formData.password,
    first_name: formData.firstName,
    middle_name: formData.middleName,
    last_name: formData.lastName,
    region: formData.regionId,
    municipalitet: formData.municipalityId,
    organization: formData.organizationId,
    personal_data_accept: formData.accept
  }
  const response = await callApi<
    RegisterCompleteRemoteData,
    RegisterCompleteErrorRemoteData | string[]
  >({
    method: 'post',
    url: 'register/',
    data,
    authRequired: false
  })
  return response
}

export const recoverPassword = async (data: PasswordRecoveryFormData) => {
  const response = await callApi<PasswordRecoveryRemoteData>({
    method: 'post',
    url: 'recover/',
    data,
    authRequired: false
  })
  return response
}

export const changePassword = async (formData: PasswordNewFormData) => {
  const data = {
    token: formData.token,
    password: formData.password
  }
  const response = await callApi<PasswordNewRemoteData>({
    method: 'post',
    url: 'recover/password/',
    data,
    authRequired: false
  })
  return response
}

export const fetchEmailByRecoveryToken = async (token: string) => {
  const response = await callApi<{ email: string }>({
    method: 'get',
    url: `email-by-recovery-token/${token}/`,
    authRequired: false
  })
  return response
}
