import type { NotificationRemoteData } from '@notifications/services/types'
import { NotificationType } from '@notifications/config'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const validate = (data: NotificationRemoteData) => {
  if (!data.id && data.id !== 0) throw new Error('Invalid AbstractNotification remote data.')
}

export default abstract class AbstractNotification {
  id: number
  date: dayjs.Dayjs
  isRead: boolean
  type: NotificationType
  abstract title: string | null
  abstract message: string

  protected constructor(data: NotificationRemoteData) {
    validate(data)
    this.id = data.id
    this.date = dayjs(data.created)
    this.isRead = !!data.state
    this.type = data.event_type
  }

  get relativeDate() {
    return this.date.fromNow()
  }

  get showActionButton() {
    return this.type !== NotificationType.Unknown
  }

  get showReadButton() {
    return false
  }

  abstract onActionClick(): void
}
