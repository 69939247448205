import type { SubjectRemoteData } from '@dict/services/types'
import BaseDictionary from '@dict/models/BaseDictionary'

export default class Subject extends BaseDictionary {
  color: string

  constructor(data: SubjectRemoteData) {
    super(data)
    this.color = data.color ?? ''
  }
}
