import dayjs from 'dayjs'
import type { DiagnosticsItemRemoteData } from '@diagnostics/services/types'
import DiagnosticsThemeState from '@diagnostics/models/DiagnosticsThemeState'
import CourseProfile from '@courses/models/CourseProfile'
import { DiagnosticsAquirementCode, DIAGNOSTICS_AQUIREMENTS_DATA } from '@diagnostics/config'
import CourseAquirement from '@courses/models/CourseAquirement'
import getDatesFormatted from '@courses/helpers/course-dates-formatted'
import AppealConfig from '@appeals/models/AppealConfig'

const validate = (data: DiagnosticsItemRemoteData) => {
  if (!data.id) throw new Error('Invalid diagnostics item data.')
}

export default class DiagnosticsItem {
  id: number
  title: string
  descriptionFull: string
  descriptionShort: string
  duration: number
  price: number
  coverUrl: string
  coverUrlSmall: string
  dateFrom: dayjs.Dayjs | null
  dateTo: dayjs.Dayjs | null
  attempts: number
  participantsCount: number
  questionsCount: number
  hasCertificate: boolean
  themes: DiagnosticsThemeState[]
  competenceName: string
  profile: CourseProfile | null
  appeal: AppealConfig | null

  constructor(data: DiagnosticsItemRemoteData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
    this.descriptionFull = data.description ?? ''
    this.descriptionShort = data.short_description ?? ''
    this.duration = data.duration ?? 0
    this.price = data.price ?? 0
    this.coverUrl = data.cover ?? ''
    this.coverUrlSmall = data.icon ?? ''
    this.dateFrom = data.start_at ? dayjs(data.start_at) : null
    this.dateTo = data.finish_to ? dayjs(data.finish_to) : null
    this.attempts = data.attempts ?? 1
    this.participantsCount = data.participants ?? 0
    this.questionsCount = data.total_quizzes ?? 0
    this.hasCertificate = data.has_certificate ?? false
    this.themes = data.themes ? data.themes.map(o => new DiagnosticsThemeState(o)) : []
    this.competenceName = data.competence_sub_type ?? ''
    this.profile = data.profile_type ? new CourseProfile(data.profile_type) : null
    this.appeal = data.appeal_config ? new AppealConfig(data.appeal_config) : null
  }

  get aquirements() {
    const aquirements = []
    if (this.hasCertificate)
      aquirements.push(
        new CourseAquirement(DIAGNOSTICS_AQUIREMENTS_DATA[DiagnosticsAquirementCode.Certificate])
      )
    aquirements.push(
      new CourseAquirement(DIAGNOSTICS_AQUIREMENTS_DATA[DiagnosticsAquirementCode.Analysis])
    )
    return aquirements
  }

  get datesFormatted(): string | null {
    return getDatesFormatted(this.dateFrom, this.dateTo)
  }
}
