import type { MiddlewareFunction } from '../middlewarePipeline'

// Redirects to home page if user is authenticated.
const isNotAuthenticated: MiddlewareFunction = ({ to, next, store, nextMiddleware }) => {
  const redirect = () => next(to.meta.redirectToRoute || { name: 'Home' })

  if (!store.user.isAuthenticated.value) {
    return nextMiddleware()
  }

  return redirect()
}

export default isNotAuthenticated
