import type { MiddlewareFunction } from '../middlewarePipeline'

// Try to authenticate user if auth token is present and user is not already authenticated.
const tryAuthenticate: MiddlewareFunction = async ({ store, nextMiddleware }) => {
  if (!store.user.isAuthenticated.value && store.user.auth.getToken()) {
    await store.user.profile.request()
  }

  return nextMiddleware()
}

export default tryAuthenticate
