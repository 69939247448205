export enum LearnType {
  Course = 'course',
  Diagnostic = 'diagnostic'
}

export const EXTENSIONS_DOC = ['doc', 'docx', 'xlsx', 'odt', 'ods', 'pdf']
export const EXTENSIONS_IMAGE = ['jpeg', 'jpg', 'png']
export const EXTENSIONS_VIDEO = ['mp4', 'avi', 'mkv']
export const EXTENSIONS_AUDIO = ['mp3', 'mpeg', 'wav', 'x-wav']

export const EXTENSIONS_NON_EXECUTED = [
  ...EXTENSIONS_DOC,
  ...EXTENSIONS_IMAGE,
  ...EXTENSIONS_VIDEO,
  ...EXTENSIONS_AUDIO
]
