import type { WebPageRemoteData } from '@web-pages/services/types'
import dayjs from 'dayjs'
import type { RouteLocationRaw } from 'vue-router'

const validate = (data: WebPageRemoteData) => {
  if (!data.id) throw new Error('Invalid web page data.')
}

export default class WebPage {
  id: number
  title: string
  content: string
  dateCreated: dayjs.Dayjs | null

  constructor(data: WebPageRemoteData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
    this.content = data.content ?? ''
    this.dateCreated = data.created ? dayjs(data.created) : null
  }

  get label() {
    return this.title
  }

  get route(): RouteLocationRaw {
    return {
      name: 'WebPagesItem',
      params: {
        id: this.id
      }
    }
  }
}
