import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Course from '@courses/models/Course'
import router from '@/router'

export default class NotificationCourseAccessTutor extends AbstractNotification {
  course: Course

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.course) {
      throw new Error('Invalid NotificationCourseAccessTutor data.')
    }
    this.course = new Course(data.reference_data.course)
  }

  get title() {
    return `${this.course.title}`
  }

  get message() {
    return `Вы назначены тьютором на курс.`
  }

  onActionClick() {
    router.push({
      name: 'CoursesItem',
      params: {
        courseId: this.course.id
      }
    })
  }
}
