<template lang="pug">
.uiSelectComponent(
  v-click-outside="onClickOutside"
  :class="[openedClass, ...componentClasses]"
)
  .handle(
    ref="handleRef"
    @click="onHandleClick"
  )
    ui-loader(v-if="isLoading" :size="5")
    template(v-else)
      .text {{ handleText }}
      .chevron
        ui-svg-icon(name="chevron")
  .dropdown(
    v-if="isOpened"
    :class="dropdownPositionsClass"
  )
    .inner
      template(v-if="withSearch")
        .search
          ui-input(
            variant="outlined"
            placeholder-text="Поиск...",
            v-model="searchQuery"
          )
      .options
        .option(
          v-for="(option, index) in filteredOptions"
          @click="onOptionClick(option)"
        )
          ui-select-option(
            :key="index"
            :option="option"
            :is-selected="isSelected(option)"
            :is-disabled="isDisabled || isLoading"
            :is-multiple="isMultiple"
          )
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import type { SelectOptionValue, ISelectOption } from '@/components/_ui/types'
import { ValidationStatus } from '@/components/_ui/types'
import UiSelectOption from '@/components/_ui/UiSelect/UiSelectOption.vue'
import useFlag from '@core/hooks/useFlag'

interface Props {
  modelValue?: number | string | boolean | null | number[] | string[]
  options?: ISelectOption[]
  isDisabled?: boolean
  isLoading?: boolean
  placeholderText?: string
  isMultiple?: boolean
  withSearch?: boolean
  variant?: 'default'
  validationStatus?: ValidationStatus | null
  allowEmpty?: boolean
}

const searchQuery = ref<string>('')

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  options: () => [],
  isDisabled: false,
  isLoading: false,
  placeholderText: 'Выберите...',
  isMultiple: false,
  withSearch: false,
  variant: 'default',
  allowEmpty: false,
  validationStatus: ValidationStatus.None
})

const componentClasses = computed(() => [
  `_${props.variant}`,
  {
    _disabled: props.isDisabled,
    _valid: props.validationStatus === ValidationStatus.Valid,
    _invalid: props.validationStatus === ValidationStatus.Invalid
  }
])

const filteredOptions = computed(() => {
  if (!props.withSearch || !searchQuery.value) return props.options
  return props.options.filter(o => o.label.toLowerCase().includes(searchQuery.value.toLowerCase()))
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: SelectOptionValue | SelectOptionValue[]): void
}>()

// Dropodown Logic.
const { flag: isOpened, cssClass: openedClass, off: close, toggle } = useFlag('_opened')

const onHandleClick = () => toggle()
const onClickOutside = () => close()

watch(isOpened, val => {
  if (!val) searchQuery.value = ''
})

const handleRef = ref<HTMLElement | null>(null)

const dropdownPositionsClass = ref<'_middle' | '_left' | '_right'>('_middle')
const defineDropdownPositionClass = () => {
  const left = getCenterPosition()
  if (left < window.innerWidth / 3) {
    dropdownPositionsClass.value = '_left'
  } else if (left > (window.innerWidth / 3) * 2) {
    dropdownPositionsClass.value = '_right'
  } else {
    dropdownPositionsClass.value = '_middle'
  }
}

onMounted(() => {
  defineDropdownPositionClass()
})

watch(isOpened, isOpened => {
  if (isOpened) defineDropdownPositionClass()
})

const getCenterPosition = () => {
  const rect = handleRef.value?.getBoundingClientRect()
  if (rect) {
    return (rect.right - rect.left) / 2 + rect.left
  }
  return 0
}

// Select Logic.
const isSelected = (option: ISelectOption) => {
  if (props.isMultiple) {
    return (props.modelValue as SelectOptionValue[])?.includes(option.value)
  }
  return props.modelValue === option.value
}

const selectOption = (option: ISelectOption) => {
  if (props.isMultiple) {
    const selectedValues = [...(props.modelValue as SelectOptionValue[]), option.value]
    const newValue = props.options.filter(o => selectedValues.includes(o.value)).map(o => o.value)
    emit('update:modelValue', newValue)
  } else {
    emit('update:modelValue', option.value)
  }
}

const deselectOption = (option: ISelectOption) => {
  if (props.isMultiple) {
    const newValue = (props.modelValue as SelectOptionValue[]).filter(o => o !== option.value)
    if (props.allowEmpty || newValue.length > 0) emit('update:modelValue', newValue)
  } else {
    if (props.allowEmpty) emit('update:modelValue', null)
  }
}

const toggleOption = (option: ISelectOption) => {
  isSelected(option) ? deselectOption(option) : selectOption(option)
}

const onOptionClick = (option: ISelectOption) => {
  if (!props.isDisabled && !props.isLoading) toggleOption(option)
  if (!props.isMultiple) close()
}

const handleText = computed(() => {
  if (props.isMultiple) {
    const modelValue = props.modelValue as SelectOptionValue[]
    return modelValue?.length
      ? props.options
          .filter(o => modelValue.includes(o.value))
          .map(o => o.label)
          .join(', ')
      : props.placeholderText
  }
  return props.options.find(o => isSelected(o))?.label ?? props.placeholderText
})
</script>

<style lang="sass" scoped>
$maxWidth: 100*$u
.uiSelectComponent
  max-width: $maxWidth
  min-width: 30*$u
  width: fit-content
  display: inline-block
  position: relative
  margin: 0.5*$u 2*$u
  user-select: none

  > .handle
    height: 7*$u
    display: flex
    align-items: center
    border-radius: $brXS
    background-color: transparent
    border: 1px solid $colorGray
    padding: 0 2*$u
    cursor: pointer

    > .text
      @include font(t14)
      flex: 1
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    > .chevron
      width: 3*$u
      height: 3*$u
      flex: 0 0 3*$u
      margin-left: 3*$u

  > .dropdown
    max-width: $maxWidth
    position: absolute
    top: calc(100% + #{4*$u})
    left: 50%
    transform: translateX(-50%)
    z-index: $zIndexDropdownSelect

    &._middle
      transform: translateX(-50%)
    &._left
      transform: translateX(-40%)
    &._right
      transform: translateX(-60%)

    > .inner
      position: relative
      background-color: $colorWhite
      width: max-content
      border-radius: $brXS
      box-shadow: 0 0 4px rgb(0 0 0 / 35%)
      min-width: 50*$u
      max-width: 100%

      &::before
        content: ''
        width: 4*$u
        height: 4*$u
        background: $colorWhite
        position: absolute
        top: -2*$u
        left: 50%
        transform: translateX(-50%) rotate(45deg)
        box-shadow: 0 0 4px rgb(0 0 0 / 35%)
        z-index: 1

      &::after
        content: ''
        width: 7*$u
        height: 3*$u
        background: $colorWhite
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        z-index: 2

      > .search
        padding: 2*$u
        position: relative
        z-index: 9

      > .options
        padding: 2*$u 0
        @include font(t14)
        max-height: 50*$u
        overflow-y: auto
        position: relative
        z-index: 9

  &._valid
    > .handle
      background-color: $colorCalendar3
      border-color: $colorSecondary
  &._invalid
    > .handle
      background-color: $colorCalendar1
      border-color: $colorDanger

  &._opened
    > .handle
      > .chevron
        transform: rotate(180deg)
</style>
