<template lang="pug">
.userNotificationsComponent(
  v-if="profile"
  v-click-outside="closeDropdown"
  :class="componentClasses"
)
  .button(
    @click="onClick"
    :class="buttonClasses"
  )
    ui-svg-icon(name="bell")
  transition(name="fade")
    .dropdown(v-if="isOpened")
      user-notifications-dropdown.inner(
        :controller="controller"
        @item-action="onItemAction"
        @item-read="onItemRead"
      )
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import useFlag from '@core/hooks/useFlag'
import type UserController from '@user/controllers/UserController'
import NotificationsController from '@notifications/controllers/NotificationsController'
import UserNotificationsDropdown from '@user/components/UserNotifications/UserNotificationsDropdown.vue'

interface Props {
  user: ReturnType<typeof UserController>
  variant?: 'default' | 'white'
}
const props = defineProps<Props>()

const controller = NotificationsController()
const { counter, requestCounter } = controller
requestCounter()

const profile = computed(() => props.user.profile.data.value)

const {
  flag: isOpened,
  cssClass: openedCssClass,
  off: closeDropdown,
  toggle: onClick
} = useFlag('_opened', false)

const componentClasses = computed(() => [openedCssClass.value, `_${props.variant}`])
const buttonClasses = computed(() => ({ _unread: counter.value }))

const onItemAction = () => {
  closeDropdown()
}
const onItemRead = () => {
  requestCounter()
}
</script>

<style lang="sass" scoped>
.userNotificationsComponent
  display: flex
  align-items: center
  position: relative

  > .button
    cursor: pointer
    width: 10*$u
    height: 10*$u
    border-radius: 50%
    padding: 2*$u
    position: relative

    &._unread
      &:after
        display: block
        content: ""
        position: absolute
        top: 0
        right: 0
        width: 2*$u
        height: 2*$u
        border: 2px solid $colorWhite
        border-radius: 50%

  > .dropdown
    position: absolute
    top: calc(100% + #{5*$u})
    right: -61.5*$u
    width: 165*$u
    background: $colorWhite
    box-shadow: -3px 0px 13px rgba($colorText, 0.2)
    border-radius: $brXS
    z-index: $zIndexNotifications
    color: $colorText
    padding: 7*$u 8*$u 8*$u

    &:before
      content: ''
      border-left: 2*$u $colorWhite solid
      border-top: 2*$u $colorWhite solid
      border-right: 2*$u transparent solid
      border-bottom: 2*$u transparent solid
      position: absolute
      top: -2*$u
      transform-origin: center
      transform: rotate(45deg)
      right: 64*$u
      box-shadow: -4*$u -4*$u 4*$u rgba($colorText, .1)

    > .inner
      overflow-y: auto
      max-height: 97*$u
      padding-right: 8*$u
      margin-right: -8*$u

  &._default
    > .button
      background: $colorPrimary
      color: $colorWhite

      &:after
        background: $colorPrimary
        border-color: $colorWhite

  &._white
    > .button
      background: $colorWhite
      color: $colorPrimary

      &:after
        background: $colorWhite
        border-color: $colorPrimary

  @media (max-width: $screenSizeL)
    > .dropdown
      width: 130*$u
      right: -14*$u
      padding: 3*$u

      &:before
        right: 16.5*$u

      > .inner
        padding-right: 0
        margin-right: 0

  @media (max-width: $screenSizeM)
    > .dropdown
      width: 93*$u

  @media (max-width: $screenSizeM)
    > .dropdown
      width: 80*$u
</style>
