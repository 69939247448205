import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import UserProfile from '@user-profile/models/UserProfile'
import TutorTask from '@expert/models/TutorTask'
import router from '@/router'

export default class NotificationTestPending extends AbstractNotification {
  task: TutorTask
  user: UserProfile

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_id || !data.actor) {
      throw new Error('Invalid NotificationTestPending data.')
    }
    this.task = new TutorTask({ quiz_chain: data.reference_id })
    this.user = new UserProfile(data.actor)
  }

  get title() {
    return null
  }

  get message() {
    return `Доступны новые задания для проверки.`
  }

  onActionClick() {
    router.push({
      name: 'CabinetTasksItem',
      params: {
        taskId: this.task.id,
        userId: this.user.id
      }
    })
  }
}
