import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import authToken from '@router/middleware/authToken'
import middlewarePipeline from '@/router/middlewarePipeline'
import store from '@/store'
import tryAuthenticate from '@router/middleware/tryAuthenticate'
import authRoutes from '@user-auth/pages'
import coursesRoutes from '@courses/pages'
import diagnosticsRoutes from '@diagnostics/pages'
import userProfileRoutes from '@user/pages'
import cabinetRoutes from '@cabinet/pages'
import newsRoutes from '@news/pages'
import faqRoutes from '@faq/pages'
import homepageRoutes from '@/modules/Home/pages'
import examplesRoutes from '@examples/pages'
import hasEmail from './middleware/hasEmail'
import knowledgesRoutes from '@/modules/Knowledges/pages'
import simulatorsRoutes from '@/modules/Simulators/pages'
import webPagesRoutes from '@web-pages/pages'
import selfAssessmentsRoutes from '@self-assessments/pages'
import forumRoutes from '@courses-forum/pages'

const routes: RouteRecordRaw[] = [
  ...homepageRoutes,
  ...authRoutes,
  ...coursesRoutes,
  ...diagnosticsRoutes,
  ...userProfileRoutes,
  ...cabinetRoutes,
  ...newsRoutes,
  ...faqRoutes,
  ...knowledgesRoutes,
  ...simulatorsRoutes,
  ...webPagesRoutes,
  ...selfAssessmentsRoutes,
  ...forumRoutes
]

if (import.meta.env.VITE_APP_MODE === 'development') {
  routes.push(...examplesRoutes)
}

routes.push({
  path: '/:notFound(.*)',
  name: 'NotFound',
  component: () => import('@/pages/NotFound.vue')
})

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const sharedMiddleware = [authToken, tryAuthenticate, hasEmail]

  let middleware = sharedMiddleware

  if (to.meta.middleware)
    middleware = middleware.concat(
      Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    )

  if (!middleware.length) return next()

  const context = {
    to,
    from,
    next,
    store
  }

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  })
})

export default router
