import { TRAINERS_STAGE_TYPES } from '@courses/config'
import type { CourseStateStagesAccessRemoteData, StageRoute } from '@courses/services/types'

export interface ResultsTrainer {
  stageId: number
  label: string
  maxScore: number
  score: number
  quizChainStateId: number | null
}

export interface ResultGroup {
  id: number
  label: string
  trainers: ResultsTrainer[]
}

const generateTrainerResultsMap = (
  data: CourseStateStagesAccessRemoteData[],
  isCompact: boolean
) => {
  const results: ResultGroup[] = []
  for (const group of data) {
    const resultGroup: ResultGroup = {
      id: group.id,
      label: group.name,
      trainers: []
    }
    const _addStage = (stage: StageRoute) => {
      if (TRAINERS_STAGE_TYPES.includes(stage.stage_type)) {
        resultGroup.trainers.push({
          stageId: stage.id,
          label: stage.name ?? '',
          maxScore: stage.max_value ?? 0,
          score: stage.user_value ?? 0,
          quizChainStateId: stage.quiz_chain_state_id ?? null
        })
      }
    }
    if (isCompact && group.stages) {
      group.stages.forEach(stage => _addStage(stage))
    } else if (group.topics) {
      group.topics.forEach(topic => {
        topic.lessons.forEach(lesson => {
          lesson.stages.forEach(stage => _addStage(stage))
        })
      })
    }
    if (resultGroup.trainers.length) results.push(resultGroup)
  }
  return results
}

export default generateTrainerResultsMap
