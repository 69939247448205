import { fromJsonString } from '@shared/helpers/from-json'
import TextInputTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/TextInputTrainerTask/TextInputTrainerTask.vue'
import { TaskType } from '@abstract-trainer/config'
import type {
  TextInputTaskDataLocalData,
  TextInputTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import { markRaw, type Component, type UnwrapRef } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'

export default class TrainerTaskTextInputShort extends AbstractTrainerTask {
  type: TaskType.TextInputShort
  data: TextInputTaskDataLocalData
  component: Component

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.TextInputShort) {
      throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    } else {
      this.type = data.test_type
    }

    this.data = formatData(data.data as TextInputTaskDataRemoteData)

    this.component = markRaw(TextInputTrainerTask)
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null) {
    return []
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    const parsedAnswer = fromJsonString(answer?.answer, null)
    return parsedAnswer as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    return !!answerValue
  }
}

const formatData = (data: TextInputTaskDataRemoteData): TextInputTaskDataLocalData => {
  return {
    placeholderText: data.placeholder ?? ''
  }
}
