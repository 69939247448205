import useEventEmitter from '@core/hooks/useEventEmitter'
import useApiRequest from '@core/hooks/useApiRequest'
import { fetchFeedbackThemes, sendFeedback } from '@/modules/Feedback/services/feedback'
import { computed, reactive, ref } from 'vue'
import FeedbackTheme from '@/modules/Feedback/models/FeedbackTheme'
import type { FeedbackFormData } from '@feedback/services/types'
import { AnswerType } from '@feedback/services/types'

export const initialFormData: FeedbackFormData = {
  theme: 1,
  comment: '',
  answerType: AnswerType.Email
}

const FeedbackController = (initialPhone?: string | null, initialEmail?: string | null) => {
  const { $emit } = useEventEmitter()

  // form data
  const formData = reactive({ ...initialFormData, phone: initialPhone, email: initialEmail })
  formData.files = []

  // themes
  const themes = ref<FeedbackTheme[] | null>(null)
  const themesOptions = computed(() => themes.value?.map(o => ({ label: o.label, value: o.id })))
  const feedbackThemesRequest = useApiRequest(fetchFeedbackThemes)
  feedbackThemesRequest.$on('success', responseData => {
    themes.value = responseData.map(item => new FeedbackTheme(item))
    $emit('feedback-themes-success')
  })
  feedbackThemesRequest.$on('error', errors => {
    $emit('feedback-themes-error', errors)
  })
  const requestFeedbackThemes = () => {
    feedbackThemesRequest.request()
  }

  // send
  const errors = ref<string[]>([])
  const sendFeedbackRequest = useApiRequest(sendFeedback)
  sendFeedbackRequest.$on('success', responseData => {
    $emit('send-feedback-success', responseData)
  })
  sendFeedbackRequest.$on('error', responseErrors => {
    $emit('send-feedback-error', responseErrors)
    errors.value = responseErrors
  })

  const requestSendFeedback = () => {
    sendFeedbackRequest.request(formData)
  }

  return {
    isLoadingFeedbackThemes: computed(() => feedbackThemesRequest.isLoading),
    isLoadingSendFeedback: computed(() => sendFeedbackRequest.isLoading),
    isSentSendFeedback: computed(() => sendFeedbackRequest.isFullfilled),
    requestFeedbackThemes,
    themes,
    themesOptions,
    formData,
    errors,
    requestSendFeedback
  }
}

export default FeedbackController
