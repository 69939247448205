<template lang="pug">
.checkboxSelectComponent(:class="componentClasses")
  .option(
    v-for="(option, index) in options"
    :key="index"
    @click="onOptionClick(option)"
  )
    ui-checkbox.option(
      :modelValue="modelValue.includes(option.value)"
      :isDisabled="isDisabled"
      :validationStatus="option.validationStatus"
      :isDecorative="true"
      :font="font"
    ) 
      .optionWrapper 
        .text {{option.label}}
        img(
          v-if="option.image"
          :src="option.image"
        )
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { SelectOptionValue, ISelectOption, IOptionWithValidation } from '../types'

interface Props {
  modelValue: SelectOptionValue[]
  options?: IOptionWithValidation[]
  isDisabled?: boolean
  allowEmpty?: boolean
  variant?: 'default' | 'inline'
  font?: 's' | 'm'
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  isDisabled: false,
  isLoading: false,
  allowEmpty: false,
  variant: 'default',
  font: 'm'
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: SelectOptionValue[]): void
}>()

const onOptionClick = (option: ISelectOption) => {
  if (props.isDisabled) return
  if (props.modelValue.includes(option.value)) {
    emit(
      'update:modelValue',
      props.modelValue.filter(v => v !== option.value)
    )
  } else {
    const newValue = props.options
      .filter(o => props.modelValue.includes(o.value) || o.value === option.value)
      .map(o => o.value)
    emit('update:modelValue', newValue)
  }
}

const componentClasses = computed(() => [`_${props.variant}`])
</script>

<style lang="sass" scoped>
.checkboxSelectComponent
  > .option:not(:last-child)
    margin-bottom: 4*$u

  &._inline
    display: flex
    align-items: center
    > .option:not(:last-child)
      margin-bottom: 0
      margin-right: 4*$u
  > .option
    img
      max-width: 100%
      margin-top: 2*$u
</style>
