import { ref, computed } from 'vue'
import Modal, { type ModalConstructorData } from '@ui-modalQueue/models/Modal'

export default function ModalQueueController(debugMode = false) {
  // Очередь модалок
  const queue = ref<Modal[]>([])

  // Активная модалка (первый элемент из очереди, либо null)
  const activeModal = computed(() => {
    if (debugMode) console.log('Modal queue: Active', queue.value?.length ? queue.value[0] : null)
    return queue.value[0] ?? null
  })

  // Добавление модалки в очередь, если модалки с переданным ключом еще нет в очереди
  const addModal = (options: ModalConstructorData) => {
    const data = new Modal(options)
    if (!queue.value.find(o => o.key === data.key)) {
      queue.value.push(data)
      if (debugMode) console.log('Modal queue: Push', data)
    }
  }

  // Удалание модалки из очереди по ключу
  const removeModal = (key: string) => {
    const index = queue.value.findIndex(o => o.key === key)
    if (index > -1) {
      queue.value.splice(index, 1)
      if (debugMode) console.log('Modal queue: Remove', key)
    }
  }

  // Удалание всех модалок из очереди
  const removeAllModals = () => {
    queue.value = []
  }

  // Возвращаемый объект
  const output = {
    activeModal,
    push: addModal,
    remove: removeModal,
    flush: removeAllModals
  }

  return output
}
