<template lang="pug">
.sliderComponent
  ui-button-arrow.arrow._left(
    direction="left"
    :class="getArrowClassName(name, 'prev')"
  )

  .wrapper
    ui-slider-container(
      :name="name"
      :config="config"
      :options="options"
    )
      slot

  ui-button-arrow.arrow._right(
    direction="right"
    :class="getArrowClassName(name, 'next')"
  )
</template>

<script lang="ts" setup>
import type { SliderConfig } from '@/components/_ui/UiSlider/types'
import { generateRandomString, getArrowClassName } from '@/components/_ui/UiSlider/helpers'
import type { SliderOptions } from '@/components/_ui/UiSlider/types'

interface Props {
  config: SliderConfig
  options?: SliderOptions
  name?: string
}

const props = defineProps<Props>()

const name = props.name || generateRandomString()
</script>

<style lang="sass" scoped>
.sliderComponent
  @include appContainer(false)
  max-width: initial
  margin: 0 -10*$u
  display: grid
  grid-gap: 2*$u
  grid-template-columns: 8*$u 1fr 8*$u
  grid-template-areas: "arrowLeft wrapper arrowRight"

  > .wrapper
    grid-area: wrapper
    overflow: hidden

  > .arrow
    align-self: center

    &._left
      grid-area: arrowLeft

    &._right
      grid-area: arrowRight
      justify-self: end

  @media (max-width: $screenSizeL)
    margin: 0 -9*$u
    grid-gap: $u

  @media (max-width: $screenSizeXS)
    margin: 0
    grid-template-columns: 1fr 1fr
    grid-template-areas: "wrapper wrapper" "arrowLeft arrowRight"
    grid-gap: 4*$u
</style>
