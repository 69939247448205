import { API_BASE_URL } from '@/config'
import store from '@/store'
import type {
  CallApiParams,
  CallApiParamsFormData,
  CallApiParamsObject,
  CallApiReturn
} from './types'
// import {
// NOTICE_ERROR_500_TITLE,
//   NOTICE_ERROR_500_CONTENT,
//   NOTICE_ERROR_403_TITLE
//   NOTICE_ERROR_403_CONTENT
// } from '@ui-notices/config'
import QueryString from 'query-string'

const _buildRequestUrl = (url: string, params?: object, remote = false) => {
  let requestUrl = remote ? url : `${API_BASE_URL}${url}`
  if (params) {
    const strParams = QueryString.stringify(params, {
      skipNull: true
    })
    requestUrl = requestUrl + '?' + strParams
  }
  return requestUrl
}

const _buildRequestBody = (data: object | FormData): FormData => {
  if (data instanceof FormData) return data

  const formData = new FormData()

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      formData.append(key, data[key as keyof object])
    }
  }

  return formData
}
async function callApi<T, E = string[]>(params: CallApiParamsObject): Promise<CallApiReturn<T, E>>
async function callApi<T, E = string[]>(params: CallApiParamsFormData): Promise<CallApiReturn<T, E>>
async function callApi<T, E = string[]>({
  method,
  url,
  data,
  params,
  remote = false,
  authRequired = true,
  formData = false,
  headers = undefined,
  denyLogout = false,
  denyNotice = false
}: CallApiParams): Promise<CallApiReturn<T, E>> {
  const authToken = store.user.auth.getToken()

  const response: CallApiReturn<T, E> = {
    data: null,
    errors: null,
    status: 0
  }
  const requestConfig: RequestInit = {
    headers: {
      Accept: 'application/json, text/plain, */*'
    },
    method: method.toUpperCase()
  }

  const requestUrl = _buildRequestUrl(url, params, remote)

  // request headers configuration
  if (authRequired && authToken)
    requestConfig.headers = { ...requestConfig.headers, Authorization: `Token ${authToken}` }

  if (headers) {
    requestConfig.headers = { ...requestConfig.headers, ...headers }
  }

  // request body building
  if (data) {
    if (formData) requestConfig.body = _buildRequestBody(data)
    else {
      requestConfig.headers = { ...requestConfig.headers, 'Content-Type': `application/json` }
      requestConfig.body = JSON.stringify(data)
    }
  }

  // calling
  try {
    const fetchResponse = await fetch(requestUrl, requestConfig)
    response.status = fetchResponse.status

    // success handling
    if (fetchResponse.ok) {
      response.data = await fetchResponse.json()
    } else {
      if (fetchResponse.headers.get('Content-Type')?.match('application/json')) {
        response.errors = await fetchResponse.json()
      }
    }

    // error handling
    if (response.status === 401 && !denyLogout) store.user.auth.logout()
    if (response.status === 403 && !denyNotice) {
      // store.ui.notices.newError(NOTICE_ERROR_403_TITLE, NOTICE_ERROR_403_CONTENT)
      // store.ui.notices.newError(NOTICE_ERROR_403_TITLE, '', { duration: 4000 })
    }
    if (response.status >= 500 && !denyNotice) {
      // store.ui.notices.newError(NOTICE_ERROR_500_TITLE, NOTICE_ERROR_500_CONTENT)
      // don't include error data in response
      response.errors = null
    }
  } catch (exception) {
    console.log(exception)
    // store.ui.notices.newError(NOTICE_ERROR_500_TITLE, NOTICE_ERROR_500_CONTENT)
  }

  return response
}

export { callApi }
