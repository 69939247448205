<template lang="pug">
.uiButtonArrowComponent(:class="buttonClasses")
  .icon
    ui-svg-icon(name="arrow")
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  direction?: 'left' | 'right'
  isDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  direction: 'right',
  isDisabled: false
})

const buttonClasses = computed(() => {
  return [`_${props.direction}`, { _disabled: props.isDisabled }]
})
</script>

<style lang="sass" scoped>
.uiButtonArrowComponent
  width: 8*$u
  height: 8*$u
  border-radius: 50%
  border: .25*$u solid transparent
  color: $colorPrimary
  cursor: pointer
  display: grid
  transition: $appAnimationSpeed

  > .icon
    width: 5*$u
    height: 5*$u
    margin: auto

  &:hover
    background-color: $colorSecondary
    color: $colorWhite

  &:active
    background-color: transparent
    border-color: $colorSecondary
    color: $colorPrimary

  &._left
    transform: rotate(180deg)

  &._disabled
    &_outline
      cursor: initial
      background: transparent
      border: none

      >.icon
        color: $colorGray
</style>
