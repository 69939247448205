export const API_BASE_URL =
  <string>import.meta.env.VITE_APP_API_BASE_URL ?? 'http://localhost:8000/api/v2/'
export const BACKEND_BASE_URL =
  <string>import.meta.env.VITE_APP_BACKEND_BASE_URL ?? 'http://localhost:8000'
export const ADMIN_URL =
  <string>import.meta.env.VITE_APP_ADMIN_URL ?? 'http://localhost:8000/admin/'

export const PUBLIC_PATH = '/'

export const DADATA_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest'
export const DADATA_API_KEY = <string>import.meta.env.VITE_APP_DADATA_API_KEY ?? ''

export const SHOW_EVENT_APP = false
