import type store from '@/store'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export interface MiddlewareFunctionContext {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
  next: NavigationGuardNext
  store: typeof store
}

export interface MiddlewareFunctionOptions extends MiddlewareFunctionContext {
  nextMiddleware: NavigationGuardNext
}

export type MiddlewarePipeline = (
  context: MiddlewareFunctionContext,
  middleware: MiddlewareFunction[],
  index: number,
  debug?: boolean
) => NavigationGuardNext

export type MiddlewareFunction = (options: MiddlewareFunctionOptions) => void

const middlewarePipeline: MiddlewarePipeline = (context, middleware, index, debug = false) => {
  if (debug) {
    console.warn(`Router middleware ${index}:`)
    console.log(middleware[index - 1])
  }
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) return context.next

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1, debug)
    nextMiddleware({ ...context, nextMiddleware: nextPipeline })
  }
}

export default middlewarePipeline
