<template lang="pug">
a.attachmentComponent(
  :href="url?.toString()",
  target="_blank",
  :class="attachmentClasses"
)
  .icon(v-if="iconName")
    ui-svg-icon(
      :name="iconName",
      :is-responsive="true"
    )
  .label {{ label }}
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { getIconName } from '@/components/_ui/UiAttachment/helpers'

interface Props {
  url?: string | null
  type?: string | null
  label?: string | null
  variant?: 'small' | 'big'
  wordsWrap?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  url: '',
  type: '',
  label: '',
  variant: 'small',
  wordsWrap: false
})

const attachmentClasses = computed(() => [`_${props.variant}`, { _wordsWrap: props.wordsWrap }])

const iconName = getIconName(props.type)
</script>

<style lang="sass" scoped>
.attachmentComponent
  display: flex
  align-items: center
  width: fit-content
  white-space: nowrap
  max-width: 100%

  > .label
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &._small
    > .icon
      flex: 0 0 4*$u
      width: 4*$u
      height: 4*$u
      color: $colorGray
      margin-right: 1*$u
    > .label
      @include font(t12)

  &._big
    > .icon
      flex: 0 0 8*$u
      width: 8*$u
      height: 8*$u
      color: $colorPrimary
      margin-right: 2*$u
    > .label
      @include font(t14)

  &._wordsWrap
    > .label
      white-space: initial
      word-break: break-all
</style>
