<template lang="pug">
.courseProgressBarComponent
  .bar
    .inner(:style="innerBarStyles")
  .value(v-if="displayValue") {{ percentCompleted }}%
</template>

<script lang="ts" setup>
import { computed } from 'vue'
interface Props {
  percentCompleted: number
  displayValue?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  percentCompleted: 0,
  displayValue: true
})

const innerBarStyles = computed(() => ({ width: `${props.percentCompleted}%` }))
</script>

<style lang="sass" scoped>
.courseProgressBarComponent
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  @include font(t14)

  > .bar
    height: 3*$u
    width: 100%
    background-color: $colorWhite
    border-radius: $brS
    border: 0.25*$u solid $colorWhite

    > .inner
      height: 100%
      background-color: $colorSecondary
      border-radius: $brS

  > .value
    margin-left: 2*$u
    color: $colorWhite

  &._alternative
    > .value
      color: $colorSecondary
</style>
