<template lang="pug">
.radioSelectComponent(:class="componentClasses")
  .option(
    v-for="(option, index) in options"
    :key="index"
    @click="onOptionClick(option)"
  )
    ui-checkbox.option(
      type="radio"
      :modelValue="option.value === modelValue"
      :isDisabled="isDisabled"
      :validationStatus="option.validationStatus"
      :isDecorative="true"
      :font="font"
    ) 
      .optionWrapper 
        .text {{option.label}}
        img(
          v-if="option.image"
          :src="option.image"
        )

</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { SelectOptionValue, ISelectOption, IOptionWithValidation } from '../types'

interface Props {
  modelValue?: SelectOptionValue
  options?: IOptionWithValidation[]
  isDisabled?: boolean
  allowEmpty?: boolean
  variant?: 'default' | 'inline'
  font?: 's' | 'm'
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  options: () => [],
  isDisabled: false,
  isLoading: false,
  allowEmpty: false,
  variant: 'default',
  font: 'm'
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: SelectOptionValue): void
}>()

const onOptionClick = (option: ISelectOption) => {
  // !props.isDisabled && emit('update:modelValue', option.value)
  if (!props.isDisabled) {
    if (option.value !== props.modelValue) {
      emit('update:modelValue', option.value)
    } else {
      if (props.allowEmpty) emit('update:modelValue', null)
    }
  }
}

const componentClasses = computed(() => [`_${props.variant}`])
</script>

<style lang="sass" scoped>
.radioSelectComponent
  > .option:not(:last-child)
    margin-bottom: 4*$u

  &._inline
    display: flex
    align-items: center
    > .option:not(:last-child)
      margin-bottom: 0
      margin-right: 4*$u

  > .option
    img
      max-width: 100%
      margin-top: 2*$u
</style>
