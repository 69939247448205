<template lang="pug">
.footerStandartContainerComponent(:class="componentClasses")
  footer-standart-info.info
  footer-standart-questions(v-if="isAuthenticated").questions
  footer-standart-copyright.copyright
  footer-standart-logos.logos
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import store from '@/store'
import FooterStandartInfo from '@/components/_layout/Footer/FooterStandart/FooterStandartInfo.vue'
import FooterStandartQuestions from '@/components/_layout/Footer/FooterStandart/FooterStandartQuestions.vue'
import FooterStandartCopyright from '@/components/_layout/Footer/FooterStandart/FooterStandartCopyright.vue'
import FooterStandartLogos from '@/components/_layout/Footer/FooterStandart/FooterStandartLogos.vue'

const isAuthenticated = computed(() => store.user.isAuthenticated.value)

const componentClasses = computed(() => ({ _auth: isAuthenticated.value }))
</script>

<style lang="sass" scoped>
.footerStandartContainerComponent
  @include appContainer
  display: grid
  grid-template-areas: "info questions" "copyright logos"
  grid-template-columns: 1fr 1fr
  grid-gap: 5*$u 10*$u

  > .info
    grid-area: info

  > .questions
    grid-area: questions

  > .copyright
    grid-area: copyright

  > .logos
    grid-area: logos

  @media (max-width: $screenSizeL)
    grid-gap: 5*$u

  @media (max-width: $screenSizeM)
    grid-gap: 5*$u 10*$u

  @media (max-width: $screenSizeS)
    grid-template-areas: "info info" "copyright logos"
    grid-gap: 9*$u 5*$u

    &._auth
      grid-template-areas: "info info" "questions questions" "copyright logos"

  @media (max-width: $screenSizeXS)
    grid-template-areas: "info" "logos" "copyright"
    grid-template-columns: 1fr

    &._auth
      grid-template-areas: "info" "questions" "logos" "copyright"
</style>
