import type { DiagnosticsItemThemeStateRemoteData } from '@diagnostics/services/types'

const validate = (data: DiagnosticsItemThemeStateRemoteData) => {
  if (!data?.id) throw new Error('Invalid diagnositcs theme state data.')
}

export default class DiagnosticsThemeState {
  id: number
  name: string
  valueMax: number
  value: number

  constructor(data: DiagnosticsItemThemeStateRemoteData) {
    validate(data)
    this.id = data.id
    this.name = data.theme ?? ''
    this.valueMax = data.max_value ?? 0
    this.value = data.user_value ?? 0
  }
}
