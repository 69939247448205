<template lang="pug">
.alertComponent(:class="[`_${color}`]")
  .icon(:style="styleImage")

  .text
    .title(v-if="title") {{ title }}
    .message(v-if="message") {{ message }}
</template>

<script lang="ts" setup>
import imageAttention from '@/assets/images/alert-attention.svg'
import imageThumbUp from '@/assets/images/alert-thumbs-up.svg'
import imageSad from '@/assets/images/alert-sad.svg'
import { computed } from 'vue'

interface Props {
  title?: string
  message?: string
  color?: 'default' | 'green' | 'red'
  icon?: 'attention' | 'thumbs-up' | 'sad'
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  message: '',
  color: 'default',
  icon: 'attention'
})

const imageUrl = computed(() => {
  switch (props.icon) {
    case 'thumbs-up':
      return imageThumbUp
    case 'sad':
      return imageSad
    default:
    case 'attention':
      return imageAttention
  }
})

const styleImage = { backgroundImage: `url(${imageUrl.value})` }
</script>

<style lang="sass" scoped>
.alertComponent
  display: grid
  grid-template-columns: 13*$u 1fr
  grid-gap: 3*$u
  border-radius: $brS
  padding: 5*$u

  > .icon
    flex: 0 0 13*$u
    width: 13*$u
    height: 13*$u
    background: center no-repeat
    background-size: contain
    margin: auto

  > .text
    color: $colorText
    display: grid
    align-items: center
    grid-gap: 2*$u
    > .title
      @include font(t16-demibold)
      text-transform: uppercase
    > .message
      @include font(t16)

  &._default
    background-color: $colorBackground2
  &._red
    background-color: $colorCalendar1
  &._green
    background-color: $colorCalendar3

  @media screen and (max-width: $screenSizeXS)
    grid-template-columns: 1fr
    padding: 6*$u
</style>
