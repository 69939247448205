<template lang="pug">
.matchTrainerTaskAnswerComponent(
  :class="componentClasses"
)
  .section {{ letter }}
  .section
    input.input(
      ref="inputRef"
      type="text"
      :disabled="isDisabled"
      :value="modelValue"
      @input="onInput"
      placeholder="?"
    )
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ALPHABET } from '@abstract-trainer/config'
import { ValidationStatus } from '@/components/_ui/types'

interface Props {
  index: number
  maxIndex: number
  answer?: number | null
  modelValue: number | null
  validationStatus?: ValidationStatus
  isDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  answer: null,
  validationStatus: ValidationStatus.None
})

const letter = computed(() => ALPHABET[props.index])
const componentClasses = computed(() => ({
  _disabled: props.isDisabled,
  _valid: props.validationStatus === ValidationStatus.Valid,
  _invalid: props.validationStatus === ValidationStatus.Invalid
}))

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | null): void
}>()

const inputRef = ref<HTMLInputElement | null>(null)

const onInput = (e: Event) => {
  if (props.isDisabled) return
  const target = e.target as HTMLInputElement
  if (target.value === '') emit('update:modelValue', null)
  const regex = new RegExp(`^[0-9]+$`, 'g')
  if (target.value.match(regex) && +target.value > 0 && +target.value <= props.maxIndex) {
    emit('update:modelValue', Number(target.value))
  } else {
    if (inputRef.value) inputRef.value.value = target.value.slice(0, -1)
  }
}
</script>

<style lang="sass" scoped>
.matchTrainerTaskAnswerComponent
  border: .25*$u solid $colorGray
  border-radius: $brXS
  display: flex

  > .section
    padding: 1*$u 2*$u
    text-transform: uppercase
    color: $colorPrimary
    width: 12*$u
    flex: 0 0 12*$u
    text-align: center

    &:first-child
      border-right: .25*$u solid $colorGray

    > .input
      border: none
      width: 100%
      text-align: center

  &._valid
    background-color: $colorCalendar3
    border-color: $colorSecondary
    > .section
      border-color: $colorSecondary

  &._invalid
    background-color: $colorCalendar1
    border-color: $colorDanger
    > .section
      border-color: $colorDanger

  &._disabled
    .input
      cursor: not-allowed
</style>
