<template lang="pug">
.userNotificationsDropdownComponent
  .header
    .content
      .title Уведомления
      ui-button.button(
        v-if="counter > 0"
        size="s"
      ) +{{counter}}
    .see-button
      ui-button.button(
        v-if="counter > 0"
        size="s"
        @click="onSeeButtonClick"
        color="secondary"
        :outlined="true"
      ) Отметить всё, как прочитанное
  .items(v-if="notifications.length")
    template(v-for="item in notifications")
      .item(v-if="item.type !== NotificationType.Wrong")
        user-notifications-dropdown-item(
          :key="item.id"
          :item="item"
          @action="onItemAction"
          @read="onItemRead"
        )
  .empty(v-else-if="!isLoadingNotifications") Для вас пока нет уведомлений.
  ui-loader.loader(v-if="isLoadingNotifications")
</template>

<script lang="ts" setup>
import type NotificationsController from '@notifications/controllers/NotificationsController'
import UserNotificationsDropdownItem from '@user/components/UserNotifications/UserNotificationsDropdownItem.vue'
import { toRef } from 'vue'
import { NotificationType } from '@notifications/config'
import { readAllNotification } from '@notifications/services/notifications'

interface Props {
  controller: ReturnType<typeof NotificationsController>
}
const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'itemAction'): void
  (e: 'itemRead'): void
}>()

const { requestNotifications, isLoadingNotifications, notifications, counter } = toRef(
  props,
  'controller'
).value

requestNotifications()

const onSeeButtonClick = () => {
  const response = readAllNotification()
  console.log(response)
  counter.value = 0
  requestNotifications()
}

const onItemAction = () => {
  emit('itemAction')
}
const onItemRead = () => {
  emit('itemRead')
}
</script>

<style lang="sass" scoped>
.userNotificationsDropdownComponent
  > .header
    display: flex
    justify-content: space-between
    border-bottom: 1px solid $colorBackground
    padding-bottom: 4*$u
    flex-flow: row
    @media (max-width: $screenSizeL)
      flex-flow: column

    > .content
      display: flex
      align-items: center
      flex-flow: row

      > .title
        @include font('h3')
        color: $colorPrimary
        margin-right: 4*$u

      > .button
        &:hover
          background: $colorPrimary !important
          cursor: initial

    > .see-button
      margin-left: 10px
      @media (max-width: $screenSizeL)
        margin-left: 0
        margin-top: 10px

  > .items
    > .item
      padding: 4*$u 0
      border-bottom: 1px solid $colorBackground

      &:last-child
        border-bottom: 0

  > .empty
    margin-top: 4*$u

  > .loader
    margin-top: 4*$u

  @media (max-width: $screenSizeS)
    > .header
      > .title
        @include font('t18')
</style>
