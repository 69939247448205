import { CourseStageWebinarStatus } from '@courses/config'
import type { CourseStageWebinarRemoteData } from '@courses/services/types'
import dayjs from 'dayjs'

const validate = (data: CourseStageWebinarRemoteData) => {
  if (!data.id) throw new Error('Invalid webinar data.')
}

export default class CourseStageWebinar {
  id: number
  link: string
  status: CourseStageWebinarStatus
  title: string
  videoUrl: string
  date: dayjs.Dayjs | null
  hasRecord: boolean

  constructor(data: CourseStageWebinarRemoteData) {
    validate(data)

    this.id = data.id
    this.link = data.link
    this.status = data.status ?? CourseStageWebinarStatus.Error
    this.title = data.title ?? ''
    this.videoUrl = data.record_link ?? ''
    this.date = data.start ? dayjs(data.start) : null
    this.hasRecord = data.record ?? false
  }

  get isActive() {
    return this.status === CourseStageWebinarStatus.Active
  }

  get isPlanned() {
    return this.status === CourseStageWebinarStatus.Planned
  }

  get isCompleted() {
    return this.status === CourseStageWebinarStatus.Completed
  }

  get isCompletedWithVideo() {
    return this.status === CourseStageWebinarStatus.CompletedWithVideo
  }

  get hasError() {
    return this.status === CourseStageWebinarStatus.Error
  }
}
