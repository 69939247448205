import ScreenController from '@ui-screen/controllers/ScreenController'
import ModalQueueController from '@ui-modalQueue/controllers/ModalQueueController'
import NoticesController from '@ui-notices/controllers/NoticesController'
import AudioController from '@ui-audio/controllers/AudioController'
import PageScrollController from '@ui-pageScroll/controllers/PageScrollController'

const UIController = (
  screenController: ReturnType<typeof ScreenController> = ScreenController(),
  modalQueueController: ReturnType<typeof ModalQueueController> = ModalQueueController(),
  noticesController: ReturnType<typeof NoticesController> = NoticesController(),
  audioController: ReturnType<typeof AudioController> = AudioController(),
  pageScrollController: ReturnType<typeof PageScrollController> = PageScrollController()
) => {
  return {
    screen: screenController,
    modals: modalQueueController,
    notices: noticesController,
    audio: audioController,
    pageScroll: pageScrollController
  }
}

export default UIController
