<template lang="pug">
.paginationComponent(v-if="controller.totalPages.value > 1")
  .page(
    v-for="page in pages"
    :class="{ _current: page.value === currentPage, _separator: page.isSeparator }"
    @click="onPageClick(page)"
  ) {{ page.label }}
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import clamp from '@core/helpers/clamp'
import type PaginationController from '@shared/controllers/PaginationController'

interface Page {
  isSeparator?: boolean
  value?: number
  label: string | number
}

interface Props {
  controller: ReturnType<typeof PaginationController>
  size?: number
}

const props = withDefaults(defineProps<Props>(), { size: 3 })

const currentPage = computed(() => props.controller.page.value)

const pages = computed(() => {
  const pages: Page[] = []
  if (currentPage.value > props.size + 1) {
    pages.push({
      value: 1,
      label: 1
    })
    pages.push({
      isSeparator: true,
      label: '...'
    })
  }
  for (
    let i = clamp(currentPage.value - props.size, 1, props.controller.totalPages.value);
    i <= clamp(currentPage.value + props.size, 1, props.controller.totalPages.value);
    i++
  ) {
    pages.push({
      value: i,
      label: i
    })
  }
  if (currentPage.value + props.size < props.controller.totalPages.value) {
    pages.push({
      isSeparator: true,
      label: '...'
    })
    pages.push({
      value: props.controller.totalPages.value,
      label: props.controller.totalPages.value
    })
  }
  return pages
})

const onPageClick = (page: Page) => {
  if (!page.isSeparator && page.value && page.value !== currentPage.value)
    props.controller.setPage(page.value)
}
</script>

<style lang="sass" scoped>
.paginationComponent
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  margin: -2*$u

  > .page
    @include font(t16-demibold)
    width: 10*$u
    height: 10*$u
    display: flex
    align-items: center
    justify-content: center
    text-transform: uppercase
    border-radius: $brS
    background-color: $colorBackground2
    color: $colorPrimary
    // padding: 3*$u 5*$u
    margin: 2*$u
    cursor: default

    &:hover:not(._current):not(._separator)
      background-color: $colorPrimary
      color: $colorWhite
      cursor: pointer

    &._current
      background-color: $colorPrimary
      color: $colorWhite

    &._separator
      background-color: transparent
</style>
