import { ValidationStatus, type IOptionWithValidation } from '@/components/_ui/types'
import { fromJsonString } from '@shared/helpers/from-json'
import { TaskType } from '@abstract-trainer/config'
import type {
  SortTaskDataLocalData,
  SortTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import { markRaw } from 'vue'
import type { UnwrapRef, Component } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'
import SortTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/SortTrainerTask/SortTrainerTask.vue'

export default class TrainerTaskSort extends AbstractTrainerTask {
  type: TaskType.Sort
  data: SortTaskDataLocalData
  component: Component

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.Sort) throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    this.type = data.test_type

    this.data = formatData(data.data as SortTaskDataRemoteData)

    this.component = markRaw(SortTrainerTask)
  }

  get optionsIds() {
    return this.data.options.map(o => o.value)
  }

  getOptionsByIdOrNull(id: number) {
    return this.data.options.find(o => o.value === id) ?? null
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null): IOptionWithValidation<number>[] {
    if (!answer)
      return this.data.options.map(o => ({ ...o, validationStatus: ValidationStatus.None }))
    const results: IOptionWithValidation<number>[] = []
    const parsedAnswer = fromJsonString(answer.answer, []) as number[] // массив Id ответов
    const notAnsweredResults: IOptionWithValidation<number>[] = [] // массив опций не вошедших в ответ, если такие есть

    this.data.options.forEach(option => {
      const currentIndex = parsedAnswer.findIndex(id => id === option.value)
      if (currentIndex !== -1) {
        const status =
          answer.validatedInputs[currentIndex] === undefined
            ? ValidationStatus.None
            : answer.validatedInputs[currentIndex].isValid
            ? ValidationStatus.Valid
            : ValidationStatus.Invalid
        results[currentIndex] = { ...option, validationStatus: status }
      } else {
        notAnsweredResults.push({ ...option, validationStatus: ValidationStatus.None })
      }
    })
    return [...results, ...notAnsweredResults]
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    const parsedAnswer = fromJsonString(answer?.answer, null)
    return (parsedAnswer ?? this.data.options.map(o => o.value)) as UnwrapRef<
      TrainerTaskLocalAnswer<T>
    >
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    if (!answerValue) return false
    return JSON.stringify(answerValue) !== JSON.stringify(this.data.options.map(o => o.value))
  }
}

const formatData = (data: SortTaskDataRemoteData): SortTaskDataLocalData => {
  const result = {
    options: data.variant.map((o, idx) => {
      const item = {
        value: o.id,
        label: o.text,
        image: o.image ?? ''
      }
      return item
    })
  }
  return result
}
