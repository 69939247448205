import { ref, readonly, type UnwrapRef } from 'vue'
import type { CallApiReturn } from '../api/types'
import useEventEmitter from './useEventEmitter'

export default function useApiRequest<
  RemoteDataType,
  RemoteErrorType = string[],
  P extends unknown[] = unknown[]
>(requestFunction: (...args: P) => Promise<CallApiReturn<RemoteDataType, RemoteErrorType>>) {
  if (!requestFunction) throw new Error('Invalid api request data.')

  const { $emit, $on, $off } = useEventEmitter<{
    success: RemoteDataType
    error: RemoteErrorType
  }>()

  const data = ref<RemoteDataType | null>(null)
  const errors = ref<RemoteErrorType | null>(null)
  const status = ref<number>(0)

  const isLoading = ref<boolean>(false)
  const isPristine = ref<boolean>(true)
  const isFullfilled = ref<boolean>(false)
  const isRejected = ref<boolean>(false)

  async function _request(...args: P) {
    isLoading.value = true
    status.value = 0
    const response = await requestFunction(...args)
    status.value = response.status
    if (response.data) {
      data.value = response.data as UnwrapRef<RemoteDataType>
      isRejected.value = false
      isFullfilled.value = true
      $emit('success', response.data)
    } else {
      if (response.errors) {
        errors.value = response.errors as UnwrapRef<RemoteErrorType>
        isFullfilled.value = false
        isRejected.value = true
        $emit('error', errors.value as RemoteErrorType)
      }
    }
    isPristine.value = false
    isLoading.value = false
  }

  const output = {
    isLoading,
    isPristine,
    isFullfilled,
    isRejected,
    status,
    data,
    errors,
    request: _request,
    $on,
    $off
  }

  return readonly(output)
}
