<template lang="pug">
.backButtonComponent(@click="onButtonClick")
  .icon
  .label
    slot -
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import type { RouteParamsRaw } from 'vue-router'

interface Props {
  name?: string
  params?: RouteParamsRaw
}
const props = withDefaults(defineProps<Props>(), {
  name: 'Home',
  params: () => ({})
})

const router = useRouter()
const onButtonClick = () => {
  router.push({ name: props.name, params: props.params })
}
</script>

<style lang="sass" scoped>
.backButtonComponent
  display: inline-flex
  align-items: center
  cursor: pointer
  color: $colorGray
  transition: $appAnimationSpeed
  &:hover
    color: $colorGray2
  .icon
    width: 5*$u
  .label
    @include font('t14')
    margin-left: 2*$u
</style>
