<template lang="pug">
.singleSelectTrainerTaskComponent
  ui-radio-select(
    :options="options"
    v-model="localAnswer"
    :is-disabled="isDisabled"
    variant="default"
    :allow-empty="true"
  )
</template>

<script lang="ts" setup>
import type { TrainerTaskSingleSelectControllerType } from '@abstract-trainer/controllers/TrainerTaskController'
import { computed, ref, watch } from 'vue'

interface Props {
  task: ReturnType<TrainerTaskSingleSelectControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()

const localAnswer = ref<number | null>(props.task.answer.localValue.value)
watch(props.task.answer.localValue, val => (localAnswer.value = val))
watch(localAnswer, props.task.answer.setLocalValue)

const options = computed(() =>
  !props.highlightValidation
    ? props.task.data.data.options
    : props.task.optionsWithValidateStatus.value
)
</script>
