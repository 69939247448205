import type {
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import type { UnwrapRef } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'

export default class TrainerTaskDefault extends AbstractTrainerTask {
  type: null
  data: null
  component: null

  constructor(data: TrainerTaskRemoteData) {
    super(data)
    this.type = null
    this.data = null
    this.component = null
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null) {
    return []
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    return answer?.answer as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    return !!(<string>answerValue)
  }
}
