import FileUploadTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/FileUploadTrainerTask/FileUploadTrainerTask.vue'
import { TaskType } from '@abstract-trainer/config'
import type {
  DefaultTaskDataLocalData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import { markRaw, type Component, type UnwrapRef } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'
import type { TrainerTaskDataRemoteData } from '@abstract-trainer/services/types'

export default class TrainerTaskFileUpload extends AbstractTrainerTask {
  type: TaskType.FileUpload
  data: DefaultTaskDataLocalData
  component: Component
  isDataEmpty = false // Задание для ответа файлом поддерживает пустую структуру
  quizData?: TrainerTaskDataRemoteData

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.FileUpload) {
      throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    } else {
      this.type = data.test_type
    }

    this.data = {}
    this.quizData = data.data

    this.component = markRaw(FileUploadTrainerTask) // TEMP
  }

  getOptionsWithValidatedStatus() {
    return []
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    return answer?.uploadedFile as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    return !!answerValue
  }

  isAnswerModified<T extends TrainerTask>(
    localAnswer: UnwrapRef<TrainerTaskLocalAnswer<T>> | null,
    answer: TrainerTaskAnswer | null
  ): boolean {
    return JSON.stringify(localAnswer) !== JSON.stringify(answer?.uploadedFile)
  }

  hasRemoteAnswer(answer: TrainerTaskAnswer | null): boolean {
    return !!answer?.uploadedFile
  }
}
