import AbstractNotification from '@notifications/models/AbstractNotification'
import type { NotificationRemoteData } from '@notifications/services/types'
import { NotificationType } from '@notifications/config'

export default class NotificationUnknown extends AbstractNotification {
  constructor(data: NotificationRemoteData) {
    super(data)
    this.type = NotificationType.Wrong
  }

  get title() {
    return null
  }

  get message() {
    return 'Уведомление неизвестного типа.'
  }

  onActionClick() {
    return
  }
}
