// Sets the auth token if provided in route query.
import type { MiddlewareFunction } from '@/router/middlewarePipeline'

const authToken: MiddlewareFunction = ({ to, next, nextMiddleware, store }) => {
  if (to.query && to.query._token) {
    store.user.auth.setToken(to.query._token as string)
    delete to.query._token

    return next(to)
  }

  return nextMiddleware()
}

export default authToken
