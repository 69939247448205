<template lang="pug">
.badgeComponent( :class="badgeClasses" )
  .text(v-if="$slots.default")
    slot
</template>

<script lang="ts" setup>
import { computed } from 'vue'

export type UIBadgeColor =
  | 'primary'
  | 'secondary'
  | 'secondary2'
  | 'success'
  | 'danger'
  | 'background'
  | 'background2'
  | 'white'
  | 'course1'
  | 'course2'
  | 'course3'
  | 'course4'
  | 'course5'
  | 'colorGray'

interface Props {
  color?: UIBadgeColor
  size?: 's' | 'm' // 24 | 36
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary',
  size: 'm'
})

const badgeClasses = computed(() => {
  return [`_color_${props.color}`, `_size_${props.size}`]
})
</script>

<style lang="sass" scoped>
$colors: (primary: $colorPrimary, secondary: $colorSecondary, secondary2: $colorSecondary2, success: $colorSuccess, danger: $colorDanger, background: $colorBackground, background2: $colorBackground2, "white": $colorWhite, course1: $colorCourse1, course2: $colorCourse2, course3: $colorCourse3, course4: $colorCourse4, course5: $colorCourse5, colorGray: $colorGray2)

.badgeComponent
  width: fit-content
  display: flex
  align-items: center
  border: 2px solid transparent
  transition: $appAnimationSpeed linear
  color: $colorWhite
  user-select: none
  background: $colorPrimary
  position: relative
  border-radius: $brXS

  > .text
    @include font('t12-demibold')
    white-space: nowrap
    flex: 1
    text-transform: uppercase

  @each $name, $color in $colors
    &._color_#{$name}
      background: $color
  &._color_background,
  &._color_background2,
  &._color_white
    color: $colorPrimary

  &._size_m // default
    height: 9*$u
    padding: 0 4*$u
  &._size_s
    height: 6*$u
    padding: 0 2*$u
</style>
