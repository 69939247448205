<template lang="pug">
.textareaComponent(
  :class="componentClasses"
)
  textarea.textarea(
    ref="textareaRef"
    :placeholder="placeholderText"
    :value="modelValue ?? ''"
    @input="onInput"
    @keydown.enter="onEnterKeyPress"
    :disabled="isDisabled"
  )
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { ValidationStatus } from '../types'

interface Props {
  placeholderText?: string
  modelValue: string | null
  variant?: 'default' | 'outlined' | 'gray' | 'white'
  smart?: boolean
  useModKeyForNewLine?: boolean
  isDisabled?: boolean
  validationStatus?: ValidationStatus | null
  bigheight?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  placeholderText: '',
  modelValue: '',
  variant: 'default',
  validationStatus: ValidationStatus.None
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const componentClasses = computed(() => [
  `_${props.variant}`,
  {
    _disabled: props.isDisabled,
    _valid: props.validationStatus === ValidationStatus.Valid,
    _invalid: props.validationStatus === ValidationStatus.Invalid
  }
])

const textareaRef = ref<HTMLTextAreaElement | null>(null)

const onInput = (e: Event) => {
  if (props.isDisabled) return
  const target = e.target as HTMLInputElement
  emit('update:modelValue', target.value)
}

const onEnterKeyPress = (e: KeyboardEvent) => {
  if (props.isDisabled) return
  if (!props.smart && !props.bigheight) return
  e.preventDefault()
  const target = e.target as HTMLTextAreaElement
  if (props.useModKeyForNewLine && !e.ctrlKey && !e.shiftKey && !e.metaKey) return
  const position = target.selectionEnd
  emit(
    'update:modelValue',
    props.modelValue
      ? props.modelValue.substring(0, position) + '\n' + props.modelValue.substring(position)
      : '\n'
  )
  target.selectionEnd = position
}
const resize = () => {
  if (!textareaRef.value) return
  textareaRef.value.style.height = '0px'
  textareaRef.value.style.height = `${textareaRef.value.scrollHeight}px`
}

const bigHeight = () => {
  if (!textareaRef.value) return
  textareaRef.value.style.height = '400px'
  textareaRef.value.style.height = `${textareaRef.value.scrollHeight}px`
}

onMounted(() => {
  if (props.smart) {
    resize()
    watch(
      () => props.modelValue,
      () => {
        setTimeout(resize, 0)
      },
      { immediate: true }
    )
  }
  if (props.bigheight) {
    bigHeight()
    watch(
      () => props.modelValue,
      () => {
        setTimeout(bigHeight, 0)
      },
      { immediate: true }
    )
  }
})
</script>

<style lang="sass" scoped>
.textareaComponent
  > .textarea
    border: 0
    display: block
    width: 100%
    padding: 2.5*$u
    @include font(t14)
    border-radius: $brXS
    height: 30*$u
    resize: none

  &._default
    > .textarea
      background: $colorBackground2

  &._gray
    > .textarea
      background: $colorBackground

  &._white
    > .textarea
      background: $colorWhite

  &._outlined
    > .textarea
      border: 0.25*$u solid $colorGray
      background-color: transparent

  &._disabled
    > .textarea
      cursor: not-allowed

  &._valid
    > .textarea
      background: $colorCalendar3

  &._invalid
    > .textarea
      background: $colorCalendar1
</style>
