<template lang="pug">
.footerStandartCopyrightComponent © Все права защищены. <span style="cursor:pointer" @click="sentryTest()">{{ year }}</span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const year = computed(() => new Date().getFullYear())
function sentryTest() {
  throw new Error('Sentry_test_' + Math.round(Math.random() * 1000))
}
</script>

<style lang="sass" scoped>
.footerStandartCopyrightComponent
  @include font('t14')
  color: $colorWhite
  margin-top: 2*$u
  white-space: nowrap

  @media (max-width: $screenSizeS)
    margin-top: auto
</style>
