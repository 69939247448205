export enum CourseFormat {
  Online = 'online',
  Offline = 'offline'
}

export const COURSE_FORMAT_TITLES: Record<CourseFormat, string> = {
  [CourseFormat.Online]: 'Онлайн',
  [CourseFormat.Offline]: 'Оффлайн'
}

export enum CourseStructureItem {
  Module,
  Topic,
  Lesson,
  Stage
}

export enum CourseStageType {
  Lecture = 5,
  Webinar = 6,
  Offline = 7,
  Trainer = 0,
  Control = 1, // Текущий контроль
  Test = 2, // Промежуточная аттестация
  FinalTest = 4, // Итоговая аттестация
  Questionnaire = 3, // Анкетирование
  SelfAssessment = 8 // Взаимооценивание
}

export const TRAINERS_STAGE_TYPES = [
  CourseStageType.Trainer,
  CourseStageType.Control,
  CourseStageType.Test,
  CourseStageType.FinalTest,
  CourseStageType.Questionnaire,
  CourseStageType.SelfAssessment
]

export enum CourseStateStatus {
  InProgress = 0,
  Passed = 1,
  Failed = 2,
  Pending = 3 // Идет согласования заявки
}

export enum CourseAccessState {
  None = 0,
  Pending = 1,
  Approved = 2,
  NoPayment = 3,
  PaymentProcessing = 4,
  PaymentSuccess = 5,
  Open = 6
}

export enum CourseAccessType {
  Free = 'O',
  Paid = 'P',
  Register = 'F',
  Special = 'S'
}

export enum CourseProfileId {
  Profile1 = 1,
  Profile2 = 2,
  Profile3 = 3,
  Profile4 = 4,
  Profile5 = 5
}

export enum CourseAquirementCode {
  Certificate = 'certificate',
  Documents = 'documents'
}

export const COURSE_AQUIREMENTS_DATA = {
  [CourseAquirementCode.Certificate]: {
    code: CourseAquirementCode.Certificate,
    icon: 'aquirement-certificate',
    title: 'Удостоверение о повышении квалификации',
    text: 'ФГАОУ ДПО «Академия Минпросвещения России» предоставит Вам удостоверение о повышении квалификации установленного образца.'
  },
  [CourseAquirementCode.Documents]: {
    code: CourseAquirementCode.Documents,
    icon: 'aquirement-documents',
    title: 'Нормативные документы',
    text: 'При прохождении курса Вы получите пакет нормативных документов.'
  }
}

export const MAP_CATALOG_PARAMS_KEYS_REMOTE_LOCAL = {
  profile_type: 'courseProfileId',
  audiences: 'courseAudienceId',
  pay_type: 'courseAccessType',
  course_format: 'courseFormat',
  region: 'regionId',
  organization: 'organizationId',
  min_hours: 'durationMin',
  max_hours: 'durationMax',
  start_date: 'dateFrom',
  finish_date: 'dateTo',
  is_recommendation: 'recommended',
  search_query: 'search',
  ordering: 'order',
  page: 'page',
  page_size: 'pageSize'
} as const

export enum CourseStageWebinarStatus {
  Error = -1,
  Planned = 0,
  Active = 1,
  Completed = 2,
  CompletedWithVideo = 3
}

export enum CourseStageAccess {
  Locked = 'B',
  Unlocked = 'O'
}

export enum OrderValue {
  date = 'start_at',
  dateReverse = '-start_at'
}

export enum FlowStatus {
  NotStarted = 0,
  InProgress = 1,
  Finished = 2
}

export enum FlowCourseStatus {
  Awaiting = 0,
  InProgress = 1,
  Complited = 2
}
