import { computed, ref } from 'vue'
import { SCREEN_SIZES } from '@ui-screen/config'

const ScreenController = () => {
  const window = ref<Window | null>(null)

  const width = ref(0)

  const updateWidth = () => {
    width.value = window.value?.innerWidth ?? 0
  }

  const size = computed(() => {
    for (const [k, v] of Object.entries(SCREEN_SIZES) as [keyof typeof SCREEN_SIZES, number][]) {
      if (width.value < v) return k
    }
    return 'xl'
  })

  const onResize = () => {
    updateWidth()
  }

  const init = (win: Window) => {
    window.value = win
    updateWidth()
    window.value.addEventListener('resize', onResize)
  }

  return {
    init,
    width: computed(() => width.value),
    size
  }
}

export default ScreenController
