import dayjs from 'dayjs'
import type { AppealRequestRemoteData } from '@appeals/services/types'
import UserProfile from '@user-profile/models/UserProfile'
import { AppealsItemStatus, APPEAL_ITEM_STATUS_TITLES } from '@appeals/config'
import AppealConfig from './AppealConfig'
import AppealSlot from './AppealSlot'
import Group from '@groups/models/Group'

const validate = (data: AppealRequestRemoteData) => {
  if (!data.id) throw new Error('Invalid appeal request data.')
}

export default class AppealRequest {
  id: number
  config: AppealConfig | null
  student: UserProfile | null
  checkingTutor: UserProfile | null
  slot: AppealSlot | null
  status: AppealsItemStatus | null
  appealTutor: UserProfile | null
  conclusionUrl: string | null
  conclusionDate: dayjs.Dayjs | null
  chatRoomId: string | null
  timeLeft: number
  quizChainId: number
  quizChainStateId: number
  group: Group | null

  constructor(data: AppealRequestRemoteData) {
    validate(data)
    this.id = data.id
    this.config = data.config ? new AppealConfig(data.config) : null
    this.student = data.student ? new UserProfile(data.student) : null
    this.checkingTutor = data.checking_tutor ? new UserProfile(data.checking_tutor) : null
    this.slot = data.slot ? new AppealSlot(data.slot) : null
    this.status = data.status ?? null
    this.appealTutor = data.appeal_tutor ? new UserProfile(data.appeal_tutor) : null
    this.conclusionUrl = data.conclusion_file ?? null
    this.conclusionDate = data.conclusion_date ? dayjs(data.conclusion_date) : null
    this.chatRoomId = data.chat_channel ?? ''
    this.timeLeft = data.time_left ?? 0
    this.quizChainId = data.quiz_chain_id ?? 0
    this.quizChainStateId = data.quiz_chain_state_id ?? 0
    this.group = data.group ? new Group(data.group) : null
  }

  get title() {
    if (!this.config) return ''
    return this.config.diagnosticsItem?.title ?? this.config.course?.title ?? ''
  }

  get isPending() {
    return this.status === AppealsItemStatus.Pending
  }
  get isAppointed() {
    return this.status === AppealsItemStatus.Appointed
  }
  get isActive() {
    return this.status === AppealsItemStatus.Active
  }
  get isFinished() {
    return this.status === AppealsItemStatus.Finished
  }

  get isDiagnosticsAppeal() {
    return !!this.config?.diagnosticsItem
  }

  get statusText() {
    return this.status !== null ? APPEAL_ITEM_STATUS_TITLES[this.status] : ''
  }

  get dateFrom() {
    return this.slot?.dateFrom
  }

  get dateTo() {
    return this.slot?.dateTo
  }

  get tutor() {
    return this.isPending || this.isAppointed ? this.appealTutor : this.checkingTutor
  }
}
