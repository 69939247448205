<template lang="pug">
.loaderComponent(:class="loaderClasses")
  ui-svg-icon(name="loader")
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  size?: number
}

const props = withDefaults(defineProps<Props>(), {
  size: 7
})

const loaderClasses = computed(() => {
  return {
    _responsive: !props.size
  }
})
</script>

<style lang="sass" scoped>
.loaderComponent
  width: calc(v-bind(size) * #{$u})
  height: calc(v-bind(size) * #{$u})
  flex: 0 0 calc(v-bind(size) * #{$u})
  color: $colorPrimary
  margin: 0 auto

  &._responsive
    width: 100%
    height: 100%
</style>
