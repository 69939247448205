import { fromJsonString } from '@shared/helpers/from-json'
import { TaskType } from '@abstract-trainer/config'
import type {
  CellInput,
  CellNonInput,
  TableFillTaskDataLocalData,
  TableFillTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData,
  TableFillAnswerOption
} from '@abstract-trainer/services/types'
import { markRaw } from 'vue'
import type { UnwrapRef, Component } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'
import { ValidationStatus, type IOptionWithValidation } from '@/components/_ui/types'
import TableFillTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/TableFillTrainerTask/TableFillTrainerTask.vue'

export default class TrainerTaskTableFill extends AbstractTrainerTask {
  type: TaskType.TableFill
  data: TableFillTaskDataLocalData
  component: Component

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.TableFill)
      throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    this.type = data.test_type

    this.data = formatData(data.data as TableFillTaskDataRemoteData)

    this.component = markRaw(TableFillTrainerTask)
  }

  get inputCells() {
    const result: CellInput[] = []
    this.data.rows.forEach(row => {
      row.forEach(cell => {
        if (cell.type === 'input') {
          result.push(cell as CellInput)
        }
      })
    })
    return result
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null): IOptionWithValidation<number>[] {
    if (!answer)
      return this.inputCells.map(o => ({
        value: o.id,
        label: '',
        validationStatus: ValidationStatus.None
      }))

    const parsedAnswer = fromJsonString(answer.answer, []) as TableFillAnswerOption[]
    const result: IOptionWithValidation<number>[] = []
    parsedAnswer.forEach((o, idx) => {
      result.push({
        value: o.id,
        label: o.answer,
        validationStatus: answer.validatedInputs[idx].isValid
          ? ValidationStatus.Valid
          : ValidationStatus.Invalid
      })
    })
    return result
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    const parsedAnswer = fromJsonString(answer?.answer, null)
    if (parsedAnswer) return parsedAnswer as UnwrapRef<TrainerTaskLocalAnswer<T>>
    const result: TableFillAnswerOption[] = this.inputCells.map(cell => ({
      id: (<CellInput>cell).id,
      answer: ''
    }))
    return result as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    if (!answerValue) return false
    return !!(<TableFillAnswerOption[]>answerValue).filter(i => i.answer !== '').length
  }
}

const formatData = (data: TableFillTaskDataRemoteData): TableFillTaskDataLocalData => {
  let inputIndex = 0
  return {
    rows: data.rows.map(row => {
      return row.map(cell => {
        let mappedCell
        if (cell.type !== 'input') {
          const temp = cell as { type: Exclude<string, 'input'>; content: string }
          mappedCell = {
            type: temp.type,
            value: temp.content ?? ''
          } as CellNonInput
        } else {
          const temp = cell as { type: 'input'; id: number }
          mappedCell = {
            type: temp.type,
            id: temp.id,
            index: inputIndex
          } as CellInput
          inputIndex++
        }
        return mappedCell
      })
    })
  }
}
