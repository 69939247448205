<template lang="pug">
.iframeTrainerTaskComponent(
  :class="{ _fullscreen: isFullscreen }",
  ref="container"
)
  iframe.iframe(:src="task.data.data.url")
  .fullscreenToggle(
    title="Развернуть на весь экран"
    @click="onFullscreenToggleClick"
)
    .icon
      ui-svg-icon(
        name="fullscreen",
        :is-responsive="true"
      )
</template>

<script setup lang="ts">
import useFlag from '@core/hooks/useFlag'
import { ref, watch } from 'vue'
import type { TrainerTaskIframeControllerType } from '@abstract-trainer/controllers/TrainerTaskController'

interface Props {
  task: ReturnType<TrainerTaskIframeControllerType>
}

defineProps<Props>()

const container = ref<HTMLElement | null>(null)

const { flag: isFullscreen, toggle: toggleFullscreen } = useFlag('', false)

watch(isFullscreen, val => {
  if (val) return container.value?.requestFullscreen()
  return document.exitFullscreen()
})

const onFullscreenToggleClick = () => {
  toggleFullscreen()
}
</script>

<style lang="sass" scoped>
.iframeTrainerTaskComponent
  position: relative

  > .iframe
    width: 100%
    height: 130*$u
    background: $colorWhite
    display: block

  > .fullscreenToggle
    background: $colorBackground2
    border-radius: $brS
    position: absolute
    top: -10*$u
    right: 0
    cursor: pointer
    padding: 3*$u

  &._fullscreen
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: $zIndexModal

    > .fullscreenToggle
        top: 0*$u

    > .iframe
      width: 100%
      height: 100%
      border-radius: 0
</style>
