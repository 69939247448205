import type { CourseModuleRemoteData } from '@courses/services/types'
import CourseTopic from '@courses/models/CourseTopic'
import getStructureLabel from '@courses/helpers/course-structure-label'
import { CourseStructureItem } from '@courses/config'

interface CourseModuleConstructorData extends CourseModuleRemoteData {
  structureLabels?: string[]
}

const validate = (data: CourseModuleConstructorData) => {
  if (!data.id) throw new Error('Invalid Course Module data.')
}

export default class CourseModule {
  id: number
  title: string
  number: number
  structureLabel: string
  topics: CourseTopic[]
  isInvariant: boolean

  constructor(data: CourseModuleConstructorData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
    this.number = data.number ?? 0
    this.structureLabel = getStructureLabel(data.structureLabels ?? [], CourseStructureItem.Module)
    this.isInvariant = data.is_invariant ?? false
    this.topics =
      data.topics?.map(
        o =>
          new CourseTopic({
            ...o,
            structureLabels: data.structureLabels,
            is_invariant: data.is_invariant
          })
      ) ?? []
  }

  get titleFull() {
    return `${this.structureLabel} ${this.number}. ${this.title}`
  }

  get stagesCountLecture() {
    return this.topics
      .map(o => o.stagesCountLecture)
      .reduce((prev: number, current: number) => prev + current, 0)
  }

  get stagesCountTest() {
    return this.topics
      .map(o => o.stagesCountTest)
      .reduce((prev: number, current: number) => prev + current, 0)
  }

  get hasOnlyOfflineStages() {
    return this.topics.every(topic => {
      return topic.lessons.every(lesson => {
        return lesson.stages.every(stage => stage.offlineInfo)
      })
    })
  }

  get hasOnlyOnlineStages() {
    return this.topics.every(topic => {
      return topic.lessons.every(lesson => {
        return lesson.stages.every(stage => !stage.offlineInfo)
      })
    })
  }
}
