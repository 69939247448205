export enum TaskType {
  SingleSelect = 0,
  MultipleSelect = 1,
  TextInput = 5,
  TextInputShort = 10,
  FileUpload = 6,
  TextFill = 3,
  TextSelect = 4,
  Match = 13,
  Sort = 15,
  TableFill = 16,
  Iframe = 14,
  Image = 23
}

export enum TrainerStateStatus {
  InProgress = 0,
  Passed = 1,
  Failed = 2,
  OnCheck = 3,
  FailedByDeadline = 4,
  FailedByAntiplagiat = 5
}

export enum TrainerScoreType {
  Point = 0,
  Pass = 1,
  Percent = 2
}

export const TRAINER_TASK_TEXT_INPUT_PLACEHOLDER = '_'

export const ALPHABET = 'абвгдеёжзийклмнопрстуфхцчшщьыъэюя'

export type TrainerResultIcons = 'invalid' | 'valid' | 'deadline' | 'pending'

export const DEFAULT_TRAINER_RESULT_LABELS: Record<TrainerResultIcons, string> = {
  valid: 'Пройдено!',
  invalid: 'Не пройдено!',
  deadline: 'Срок прохождения истек',
  pending: 'На проверке'
}
