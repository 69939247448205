// https://rjzaworski.com/2019/10/event-emitters-in-typescript

import { reactive } from 'vue'

enum CallbackType {
  Standart,
  Once
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventMap = Record<string, any>

type EventKey<T extends EventMap> = string & keyof T

export type EventReceiver<T> = (params: T) => void

export interface Emitter<T extends EventMap> {
  $on<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>): void
  $once<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>): void
  $off<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>): void
  $emit<K extends EventKey<T>>(eventName: K, params?: T[K]): void
}

type EmitterWithDebug<T extends EventMap> = Emitter<T> & {
  listeners?: {
    [K in EventKey<T>]?: Map<EventReceiver<EventMap[K]>, CallbackType>
  }
}

function useEventEmitter<T extends EventMap>(): Emitter<T>
function useEventEmitter<T extends EventMap>(debugMode: false): Emitter<T>
function useEventEmitter<T extends EventMap>(debugMode: true): EmitterWithDebug<T>
function useEventEmitter<T extends EventMap>(debugMode?: boolean): EmitterWithDebug<T> {
  const listeners: EmitterWithDebug<T>['listeners'] = reactive({})

  const $on: Emitter<T>['$on'] = (key, fn) => {
    listeners[key] = (listeners[key] ?? new Map()).set(fn, CallbackType.Standart)

    if (debugMode) {
      console.warn('Handler attached:')
      console.log('Key:', key)
      console.log('Listener:', fn)
      console.log('Listeners list:', listeners[key])
    }
  }

  const $once: Emitter<T>['$once'] = (key, fn) => {
    listeners[key] = (listeners[key] ?? new Map()).set(fn, CallbackType.Once)

    if (debugMode) {
      console.warn('Handler attached:')
      console.log('Key:', key)
      console.log('Listener:', fn)
      console.log('Listeners list:', listeners[key])
    }
  }

  const $off: Emitter<T>['$off'] = (key, fn) => {
    listeners[key]?.delete(fn)

    if (debugMode) {
      console.warn('Handler detached:')
      console.log('Key:', key)
      console.log('Listener:', fn)
      console.log('Listeners list:', listeners[key])
    }
  }

  const $emit: Emitter<T>['$emit'] = (key, data) => {
    if (debugMode) {
      console.warn('Event emitted:')
      console.log('Key:', key)
      console.log('Data:', data)
    }

    listeners[key]?.forEach((type, fn) => {
      if (type === CallbackType.Once) $off(key, fn)
      fn(data)
    })
  }

  const output: EmitterWithDebug<T> = {
    $on,
    $once,
    $off,
    $emit
  }

  if (debugMode) {
    output.listeners = listeners
  }

  return output
}

export default useEventEmitter
