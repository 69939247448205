import type { RouteLocationRaw } from 'vue-router'
import store from '@/store'
import { computed, type ComputedRef } from 'vue'

// Emulate TS Enum with object values
export class NavigationItem {
  static readonly Courses = new NavigationItem('Курсы', { name: 'Courses' })
  static readonly CabinetDashboard = new NavigationItem('Дашборд', { name: 'CabinetDashboard' })
  static readonly CabinetCourses = new NavigationItem('Курсы', { name: 'CabinetCourses' })
  static readonly CabinetCalendar = new NavigationItem('Календарь', { name: 'CabinetCalendar' })
  static readonly CabinetGroups = new NavigationItem('Группы', { name: 'CabinetGroups' })
  static readonly Diagnostics = new NavigationItem('Диагностики', { name: 'Diagnostics' })
  static readonly CabinetStudents = new NavigationItem('Слушатели', { name: 'CabinetStudents' })
  static readonly CabinetTasks = new NavigationItem('Задания', { name: 'CabinetTasks' })

  // Всегда в "..."

  static readonly CabinetAppeals = new NavigationItem(
    'Апелляции',
    { name: 'CabinetAppeals' },
    computed(() => true)
  )

  static readonly Knowledges = new NavigationItem(
    'База знаний',
    { name: 'Knowledges' },
    computed(() => true)
  )
  static readonly Simulators = new NavigationItem(
    'Витрина симуляционных центров',
    { name: 'Simulators' },
    computed(() => true)
  )
  static readonly SelfAssessments = new NavigationItem(
    'Взаимооценивание',
    { name: 'SelfAssessmentList' },
    computed(() => true)
  )

  static readonly News = new NavigationItem('Новости', { name: 'News' }, store.user.isAuthenticated)
  static readonly FAQ = new NavigationItem('FAQ', { name: 'FAQ' }, store.user.isAuthenticated)

  private constructor(
    public readonly label: string,
    public readonly route: RouteLocationRaw,
    public readonly inDots: ComputedRef<boolean> = computed(() => false)
  ) {}

  // return route with query or params
  public routeWith(routeParams: RouteLocationRaw): RouteLocationRaw {
    return Object.assign(this.route, routeParams)
  }
}
