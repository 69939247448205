import type { OrganizationRemoteData } from '@dict/services/types'
import BaseDictionary from '@dict/models/BaseDictionary'
import type { DadataInfo } from './DadataInfoInterface'
import Region from './Region'
import Municipality from './Municipality'

export default class Organization extends BaseDictionary implements DadataInfo {
  fullTitle: string
  inn: string
  regionId: number | null
  municipalityId: number | null
  logoUrl: string
  code: string | null
  isShnor: boolean
  fromDadata: boolean
  region: Region | null
  municipality: Municipality | null

  constructor(data: OrganizationRemoteData) {
    super(data)
    this.title = data.short_name ?? ''
    this.fullTitle = data.full_name || data.organization_dadata_full_name || ''
    this.inn = data.inn || data.organization_dadata_inn || ''
    this.regionId = data.region ?? null
    this.region = data.region_embed ? new Region(data.region_embed) : null
    this.municipalityId = data.municipalitet ?? null
    this.municipality = data.municipalitet_embed ? new Municipality(data.municipalitet_embed) : null
    this.logoUrl = data.icon ?? ''

    this.code = data.code ?? null
    this.isShnor = data.is_shnor ?? false

    this.fromDadata = data.from_dadata ?? false
  }
}
