import useApiRequest from '@core/hooks/useApiRequest'
import useEventEmitter from '@core/hooks/useEventEmitter'
import { computed } from 'vue'
import { readNotification } from '@notifications/services/notifications'

interface Events {
  'read-notification-request-success': void
  'read-notification-request-error': string[]
}

const NotificationsItemController = (id: number) => {
  const { $on, $off, $once, $emit } = useEventEmitter<Events>()

  const readNotificationRequest = useApiRequest(readNotification)
  readNotificationRequest.$on('success', () => {
    $emit('read-notification-request-success')
  })
  readNotificationRequest.$on('error', errors => {
    $emit('read-notification-request-error', errors)
  })
  const requestReadNotification = () => {
    readNotificationRequest.request(id)
  }

  return {
    requestReadNotification,
    isLoadingReadNotification: computed(() => readNotificationRequest.isLoading),
    $on,
    $off,
    $once
  }
}

export default NotificationsItemController
