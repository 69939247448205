import isAuthenticated from '@router/middleware/isAuthenticated'
import type { RouteRecordRaw } from 'vue-router'

const diagnosticsRoutes: RouteRecordRaw[] = [
  {
    path: '/diagnostics',
    name: 'Diagnostics',
    component: () => import('./DiagnosticsHome.vue'),
    redirect: { name: 'DiagnosticsList' },
    children: [
      {
        path: '',
        name: 'DiagnosticsList',
        component: () => import('./DiagnosticsList.vue')
      },
      {
        path: ':id',
        name: 'DiagnosticsItem',
        component: () => import('./DiagnosticsItem.vue'),
        props: route => ({ id: Number(route.params.id) }),
        redirect: { name: 'DiagnosticsItemLanding' },
        children: [
          {
            path: '',
            name: 'DiagnosticsItemLanding',
            component: () => import('./DiagnosticsItemLanding.vue'),
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'test',
            name: 'DiagnosticsItemTest',
            component: () => import('./DiagnosticsItemTest.vue'),
            props: route => ({ id: Number(route.params.id) }),
            meta: {
              middleware: [isAuthenticated]
            }
          },
          {
            path: 'result',
            name: 'DiagnosticsItemResult',
            component: () => import('./DiagnosticsItemResult.vue'),
            props: route => ({ id: Number(route.params.id) }),
            meta: {
              middleware: [isAuthenticated]
            }
          }
        ]
      }
    ]
  }
]

export default diagnosticsRoutes
