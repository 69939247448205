<template lang="pug">
.headerNavigationItemComponent
  router-link.link(
    :class="linkClasses"
    :to="item.route"
    active-class="_active"
  ) {{ item.label }}
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { NavigationItem } from '@nav/config'

interface Props {
  item: NavigationItem
  isInvertedColors?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isInvertedColors: false
})

const linkClasses = computed(() => ({ _inverted: props.isInvertedColors }))
</script>

<style lang="sass" scoped>
.headerNavigationItemComponent
  position: relative
  cursor: pointer

  .link
    @include font('t14')
    display: block
    color: $colorPrimary
    padding: $u 0
    border-bottom: 2px solid transparent
    &._active
      border-color: $colorPrimary

    &._inverted
      color: $colorWhite
      &._active
        border-color: $colorWhite
</style>
