import type { CourseAquirementCode } from '@courses/config'
import type { DiagnosticsAquirementCode } from '@diagnostics/config'

interface CourseAquirementConstructorData {
  code: CourseAquirementCode | DiagnosticsAquirementCode
  icon: string
  title: string
  text: string
}

export default class CourseAquirement {
  code: CourseAquirementCode | DiagnosticsAquirementCode
  icon: string
  title: string
  text: string

  constructor(data: CourseAquirementConstructorData) {
    this.code = data.code
    this.icon = data.icon
    this.title = data.title
    this.text = data.text
  }
}
