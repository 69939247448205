import useApiRequest from '@core/hooks/useApiRequest'
import useEventEmitter from '@core/hooks/useEventEmitter'
import type Attachment from '@shared/models/Attachment'
import { computed } from 'vue'
import { checkAttachmentAccess } from '../services/attachment'

interface Events {
  'action-access': never
  'action-deny': never
}

const AttachmentController = (item?: Attachment) => {
  const { $on, $off, $emit } = useEventEmitter<Events>()

  const checkActionRequest = useApiRequest(checkAttachmentAccess)
  checkActionRequest.$on('success', () => $emit('action-access'))
  checkActionRequest.$on('error', () => $emit('action-deny'))

  const checkActionAccess = () => {
    if (!item || !item.accessUrl) {
      $emit('action-access')
    } else {
      checkActionRequest.request(item.accessUrl)
    }
  }

  return {
    checkActionAccess,
    isLoading: computed(() => checkActionRequest.isLoading),
    $on,
    $off
  }
}

export default AttachmentController
