<template lang="pug">
.matchTrainerTaskComponent
  .groups
    match-trainer-task-group.group(
      v-for="(group, index) in task.data.data.groups"
      :key="index"
      :group="group"
      :type="index === 0 ? 'alpha' : 'number'"
    )
  .answersWrapper
    .title Запишите в таблицу выбранные цифры под соответствующими буквами.
    .answers(v-if="localAnswer?.length")
      match-trainer-task-answer.answer(
        v-for="(_, index) in options"
        :key="index"
        :index="index"
        :max-index="maxIndex"
        v-model="localAnswer[index]"
        :is-disabled="isDisabled"
        :validation-status="getOptionValidationStatus(task.data.data.groups[0].options[index].value)"
      )
</template>

<script lang="ts" setup>
import { ValidationStatus } from '@/components/_ui/types'
import clamp from '@core/helpers/clamp'
import type { TrainerTaskMatchControllerType } from '@abstract-trainer/controllers/TrainerTaskController'
import { computed, ref, watch } from 'vue'
import MatchTrainerTaskAnswer from './MatchTrainerTaskAnswer.vue'
import MatchTrainerTaskGroup from './MatchTrainerTaskGroup.vue'

interface Props {
  task: ReturnType<TrainerTaskMatchControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()

const options = computed(() => props.task.data.data.groups[0].options)
const values = computed(() => props.task.data.data.groups[1].options)

const maxIndex = computed(() => clamp(values.value.length, 1, values.value.length))

const localAnswer = ref<number[]>(props.task.answer.localValue.value ?? [])
watch(props.task.answer.localValue, val => val && (localAnswer.value = val), { deep: true })
watch(localAnswer, props.task.answer.setLocalValue, { deep: true })

const statuses = computed(() => props.task.optionsWithValidateStatus.value)

const getOptionValidationStatus = (index: number) => {
  if (!props.highlightValidation) return ValidationStatus.None
  const statusObj = statuses.value.find(o => o.value === index)
  return !statusObj ? ValidationStatus.None : statusObj.validationStatus || ValidationStatus.None
}
</script>

<style lang="sass" scoped>
.matchTrainerTaskComponent
  > .groups
    display: flex
    margin-bottom: 10*$u
    > .group
      &:not(:last-child)
        margin-right: 10*$u

  > .answersWrapper

    > .title
      color: $colorPrimary
      margin-bottom: 7*$u

    > .answers
      display: flex
      align-items: center
      flex-wrap: wrap

      > .answer
        margin: 2*$u
</style>
