import type { RouteRecordRaw } from 'vue-router'

const simulatorsRoutes: RouteRecordRaw[] = [
  {
    path: '/simulators',
    name: 'Simulators',
    component: () => import('./SimulatorsHome.vue'),
    redirect: { name: 'SimulatorsList' },
    children: [
      {
        path: '',
        name: 'SimulatorsList',
        component: () => import('./SimulatorsList.vue')
      },
      {
        path: ':id',
        name: 'SimulatorsItem',
        props: route => ({ id: Number(route.params.id) }),
        component: () => import('./SimulatorsItem.vue'),
        redirect: { name: 'SimulatorsItemLanding' },
        children: [
          {
            path: '',
            name: 'SimulatorsItemLanding',
            component: () => import('./SimulatorsItemLanding.vue')
          },
          {
            path: 'result',
            name: 'SimulatorsItemResult',
            component: () => import('./SimulatorsItemResult.vue')
          }
        ]
      }
    ]
  }
]

export default simulatorsRoutes
