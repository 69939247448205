<template lang="pug">
transition(name="modal")
  .modalWrapperComponent(
    v-if="activeModal"
    @click="onOverlayClick"
    ref="wrapperRef"
  )
    .inner(
      :class="innerClasses"
      @click.stop
    )
      .closeButton(
        v-if="isClosable"
        @click="onCloseButtonClick"
      )
        ui-svg-icon(name="close")
      component(
        :is="activeModal.component"
        @close="onClose"
        @scroll-to-top="onScrollToTop"
        ref="componentRef"
        v-bind="activeModal.props"
        v-on="activeModal.on"
      )
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from 'vue'
import store from '@/store'

// Контроллер очереди модалок
const controller = store.ui.modals

// Активная модалка из очереди
const activeModal = controller.activeModal

// Референс враппера модалки
const wrapperRef = ref<HTMLDivElement | null>(null)

// Референс инстанса компонента активной модалки
const componentRef = ref<{
  onBeforeClose?: () => Promise<boolean>
  onAfterClose: () => void
} | null>(null)

// Флаг, отвечающий за отображение крестика и закрытие модалки по клику на фон или при нажатии ESC
const isClosable = computed(() => activeModal.value?.params.isClosable ?? true)

// Класс компонента модального окна
const innerClasses = computed(() => [
  { _white: activeModal.value?.params.backgroundColor === 'white' },
  { _gray: activeModal.value?.params.backgroundColor === 'gray' }
])

// Функция, закрывающщая активную модалку
const closeActiveModal = async () => {
  // Флаг, должна ли модалка быть закрыта
  if (!activeModal.value) return
  let closeSuccess = true

  //  Смотрим, есть ли в инстансе компонента модалки метод onBeforeClose
  if (typeof componentRef.value?.onBeforeClose === 'function') {
    // Вызываем метод onBeforeClose
    const result = await componentRef.value.onBeforeClose()
    // Если onBeforeClose не вернул true, модалка не должна быть закрыта
    if (!result) closeSuccess = false
  }
  // Если модалка должна быть закрыта
  if (closeSuccess) {
    // Удаляем модалка из очереди
    controller.remove(activeModal.value.key)
    // Если в инстансе компонента модалки есть метод onAfterClose, вызываем его
    if (typeof componentRef.value?.onAfterClose === 'function') componentRef.value.onAfterClose()
  }
}

// Хендлер, вызываемый по нажатию на задний фон
const onOverlayClick = () => {
  if (isClosable.value) closeActiveModal()
}

// Хендлер, вызываемый по нажатию ESC
const onEscapeKeyPress = (e: KeyboardEvent) => {
  if (e.keyCode === 27 && isClosable.value) closeActiveModal()
}

// Хендлер, вызываемый по клику на крестик
const onCloseButtonClick = () => {
  if (isClosable.value) closeActiveModal()
}

// Хендлер, вызываемый, когда в инстансе компонента модалки сработал ивент 'close'
const onClose = () => closeActiveModal()

// Хендлер скролла враппера в начало
const onScrollToTop = () => {
  if (wrapperRef.value) wrapperRef.value.scrollTop = 0
}

// подписываемся на нажатие клавиш
document.addEventListener('keyup', onEscapeKeyPress)

onUnmounted(() => {
  document.removeEventListener('keyup', onEscapeKeyPress)
})

const hasActiveModal = computed(() => !!activeModal.value)

watch(hasActiveModal, store.ui.pageScroll.toggleByFlag)
</script>

<style lang="sass" scoped>
.modal-enter-active, .modal-leave-active
  transition-property: opacity, top
  transition-duration: $appAnimationSpeed
  transition-timing-function: ease-out
.modal-enter-from, .modal-leave-to
  opacity: 0
  top: -25vh !important
.modal-enter-to, .modal-leave-from
  opacity: 1
  top: 0 !important

.modalWrapperComponent
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  background: rgba($colorBlack, .7)
  display: flex
  overflow: auto
  z-index: $zIndexModal
  padding: 7*$u

  > .inner
    position: relative
    z-index: $zIndexModal
    border-radius: $brL
    padding: 10*$u
    margin: auto
    max-width: 250*$u

    > .closeButton
      width: 6*$u
      height: 6*$u
      position: absolute
      top: 3*$u
      right: 3*$u
      background-color: transparent
      color: $colorGray
      padding: 0.5*$u
      box-shadow: none
      cursor: pointer

      &:hover,
      &:active
        color: $colorPrimary

    &._white
      background-color: $colorWhite

    &._gray
      background-color: $colorBackground

  @media screen and (max-width: $screenSizeS)
    padding: 0

    > .inner
      width: 100%
      border-radius: 0
      display: flex
      align-items: center
      justify-content: center
</style>
