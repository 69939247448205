import type { RegionRemoteData } from '@dict/services/types'
import BaseDictionary from '@dict/models/BaseDictionary'
import type { DadataInfo } from './DadataInfoInterface'

export default class Region extends BaseDictionary implements DadataInfo {
  federalDistrictId: number | null
  fromDadata: boolean
  nameDadata: string

  constructor(data: RegionRemoteData) {
    super(data)
    this.federalDistrictId = data.fo ?? null
    this.fromDadata = data.from_dadata ?? false
    this.nameDadata = data.name_dadata ?? ''
  }
}
