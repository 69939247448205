import type { MiddlewareFunction } from '../middlewarePipeline'
import FillEmailModal from '@user-profile/components/modals/FillEmailModal.vue'
// Redirects to login page if user is not authenticated.
const hasEmail: MiddlewareFunction = ({ to, next, store, nextMiddleware }) => {
  if (store.user.isAuthenticated.value) {
    if (!store.user.profile.data.value?.email) {
      // show fill email modal
      store.ui.modals.push({
        key: 'fill-email-modal',
        component: FillEmailModal,
        params: {
          isClosable: false
        }
      })
    }
  }

  return nextMiddleware()
}

export default hasEmail
