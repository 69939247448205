import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Course from '@courses/models/Course'
import router from '@/router'

export default class NotificationForumMessage extends AbstractNotification {
  course: Course
  forumId: number
  topicId: number

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.course) {
      throw new Error('Invalid NotificationForumMessage data. `course` is empty in reference_data')
    }
    if (!data.reference_data.forum_id) {
      throw new Error('Invalid NotificationForumMessage data. Has no `forum_id` in reference_data')
    }
    if (!data.reference_data.topic_id) {
      throw new Error('Invalid NotificationForumMessage data. Has no `topic_id` in reference_data')
    }
    this.course = new Course(data.reference_data.course)
    this.forumId = data.reference_data.forum_id
    this.topicId = data.reference_data.topic_id
  }

  get title() {
    return `${this.course.title}`
  }

  get message() {
    return `Новое сообщение на форуме`
  }

  onActionClick() {
    router.push({
      name: 'ForumTopicsItem',
      params: {
        id: this.forumId,
        topicId: this.topicId
      }
    })
  }
}
