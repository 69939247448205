import type { ISelectOption } from '@/components/_ui/types'
import type { DictionaryRemoteData } from '@dict/services/types'

const validate = (data: DictionaryRemoteData) => {
  if (data.id !== 0 && !data.id) throw new Error('Invalid Dictionary data.')
}

export default class BaseDictionary {
  id: number
  title: string

  constructor(data: DictionaryRemoteData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
  }

  get option(): ISelectOption {
    return {
      label: this.title,
      value: this.id
    }
  }
}
