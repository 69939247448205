export const getIconName = (type: string | null) => {
  let name: string | null = ''
  switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
      name = 'document-jpg'
      break
    case 'image/png':
      name = 'document-png'
      break
    case 'application/pdf':
      name = 'document-pdf'
      break
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      name = 'document-pptx'
      break
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      name = 'document-xls'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
    case 'application/vnd.oasis.opendocument.text':
      name = 'document-word'
      break
    case 'video/mp4':
    case 'video/avi':
      name = 'document-video'
      break
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/x-wav':
      name = 'document-audio'
      break
    default:
      name = null
  }
  return name
}
