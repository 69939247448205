<template lang="pug">
.attachmentAudioComponent
  .icon
    slot(name="icon")
  .content(
    :style="onTitleClick ? {cursor: 'pointer'} : ''"
    @click="onTitleClick"
  )
    .title {{ title }}
    .label {{ label }}
  .separator
  .action(@click.stop="onClick")
    .inner
      transition(name="fade" mode="out-in")
        ui-loader(v-if="isLoading")
        slot(v-else name="action-icon")
</template>

<script lang="ts" setup>
import type Attachment from '@shared/models/Attachment'
import { onUnmounted } from 'vue'
import AttachmentController from '../controllers/AttachmentController'

interface Props {
  title: string
  label: string
  item?: Attachment
  disableCheck?: boolean
  onTitleClick?: () => void
}
const props = defineProps<Props>()

const emits = defineEmits<{
  (e: 'action'): void
}>()

const { $on, $off, checkActionAccess, isLoading } = AttachmentController(props.item)

const onAction = () => emits('action')

const onClick = () => {
  if (props.disableCheck) {
    onAction()
  } else {
    checkActionAccess()
  }
}
$on('action-access', onAction)
onUnmounted(() => {
  $off('action-access', onAction)
})
</script>

<style lang="sass" scoped>
.attachmentAudioComponent
  width: fit-content
  display: grid
  grid-template-areas: "icon content separator action"
  grid-template-columns: 7.5*$u minmax(12*$u, 50*$u) 0.25*$u 6*$u
  grid-column-gap: 4*$u
  color: $colorGray
  border-radius: $brXS
  border: 0.25*$u solid $colorCourse3
  padding: 3.5*$u 5*$u
  transition: $appAnimationSpeed

  > .icon
    grid-area: icon
    width: 7.5*$u
    height: 10*$u
    color: $colorPrimary

  > .content
    grid-area: content

    > .title
      @include font(t14)
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    > .label
      @include font(t14-demibold)
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      color: $colorText

  > .separator
    grid-area: separator
    width: 0.25*$u
    height: 10*$u
    background-color: $colorCourse3

  > .action
    cursor: pointer
    grid-area: action
    color: $colorCourse3
    transition: $appAnimationSpeed
    display: grid

    > .inner
      margin: auto

    &:hover
      color: $colorPrimary
</style>
