import { PAGE_SCROLL_DISABLED_CLASS_NAME } from '@ui-pageScroll/config'

export default function PageScrollController() {
  const body = document.getElementById('body')

  const disable = () => {
    body?.classList.add(PAGE_SCROLL_DISABLED_CLASS_NAME)
  }

  const enable = () => {
    body?.classList.remove(PAGE_SCROLL_DISABLED_CLASS_NAME)
  }

  const toggleByFlag = (isDisabled: boolean) => {
    isDisabled ? disable() : enable()
  }

  return {
    disable,
    enable,
    toggleByFlag
  }
}
