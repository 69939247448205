<template lang="pug">
.userMenuComponent(
  v-if="profile"
  v-click-outside="onClickOutside"
  :class="componentClasses"
)
  .handle(@click="onClick")
    .avatar
      ui-avatar(
        :variant="isInvertedColors ? 'white' : 'default'"
        :url="profile.avatarUrl ?? ''"
        :size="10"
      )
    .info
      .name {{ profile.fullName }}
      .role {{ profile.roleTitle() }}
    .polygon
      ui-svg-icon(name="polygon")
  transition(name="fade")
    template(v-if="isOpened")
      .menu
        .item(@click="goToProfile") Мой профиль
        .item(@click="goToUpdateProfile") Редактирование профиля
        .item._select(
          v-if="profile.canChangeRole"
          @click.stop
        ) Сменить роль
          .input
            ui-select(
              v-model="role"
              :options="rolesOptions"
              placeholder-text="Выберите роль"
            )
        a.item(
          v-if="profile.hasAccessToAdminPanel"
          @click="close"
          :href="adminUrl"
          target="_blank"
        ) Панель администрирования
        .item(@click="user.auth.logout") Выйти
</template>

<script lang="ts" setup>
import store from '@/store'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { ADMIN_URL } from '@/config'
import useFlag from '@core/hooks/useFlag'
import type { Role } from '@user-profile/config'
import type UserController from '@user/controllers/UserController'

const router = useRouter()

interface Props {
  user: ReturnType<typeof UserController>
  isInvertedColors?: boolean
}

const props = defineProps<Props>()

const profile = computed(() => props.user.profile.data.value)

const { flag: isOpened, cssClass: openedCssClass, off: close, toggle } = useFlag('_opened', false)

const componentClasses = computed(() => [
  openedCssClass.value,
  { _inverted: props.isInvertedColors }
])

const adminUrl = ADMIN_URL

const rolesOptions = computed(
  () =>
    profile.value?.availableRoles.map(role => ({
      label: profile.value?.roleTitle(role) ?? '',
      value: role
    })) ?? []
)

const role = computed<Role>({
  get() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return profile.value!.role
  },
  set(value: Role) {
    store.user.profile.requestSetRole(value)
    profile.value?.changeRole(value)
    router.push({ name: 'Home' })
    close()
  }
})

const onClick = () => {
  toggle()
}
const onClickOutside = () => {
  close()
}
const goToProfile = () => {
  close()
  router.push({
    name: 'ProfileInfo',
    params: {
      id: props.user.id.value
    }
  })
}
const goToUpdateProfile = () => {
  close()
  router.push({
    name: 'ProfileUpdate'
  })
}
</script>

<style lang="sass" scoped>
.userMenuComponent
  display: flex
  align-items: center
  position: relative

  > .handle
    display: flex
    align-items: center
    cursor: pointer
    color: $colorPrimary

    > .avatar
      margin-right: 2*$u
      @media (max-width: $screenSizeL)
        margin-right: 0

    > .info
      @media (max-width: $screenSizeL)
        display: none
      > .name,
      > .role
        @include font(t14)
        white-space: nowrap
        max-width: 40*$u
        overflow: hidden
        text-overflow: ellipsis

    > .polygon
      flex: 0 0 2*$u
      width: 2*$u
      height: 2*$u
      margin-left: 1*$u
      margin-top: -4*$u
      transform-origin: center
      transform: rotate(0)
      transition: $appAnimationSpeed linear
      @media (max-width: $screenSizeL)
        display: none

  > .menu
    position: absolute
    top: calc(100% + #{5*$u})
    right: 0
    width: 55*$u
    background-color: $colorWhite
    box-shadow: -3px 0px 13px rgba($colorText, .2)
    border-radius: $brXS
    z-index: $zIndexNotifications
    color: $colorText
    padding: 3*$u

    &::before
      content: ''
      border-left: 2*$u $colorWhite solid
      border-top: 2*$u $colorWhite solid
      border-right: 2*$u transparent solid
      border-bottom: 2*$u transparent solid
      position: absolute
      top: -2*$u
      transform-origin: center
      transform: rotate(45deg)
      right: calc(50% - #{2*$u})
      box-shadow: -4*$u -4*$u 4*$u rgba($colorText, .1)
      @media (max-width: $screenSizeL)
        right: 3*$u

    > .item
      display: block
      @include font(t14)
      cursor: pointer
      &:not(:last-child)
        margin-bottom: 3*$u
      &:hover
        color: $colorPrimary
      &._select
        cursor: initial
        &:hover
          color: $colorText
        .input
          margin-top: $u

  &._inverted
    .handle
      color: $colorWhite

  &._opened
    .polygon
      transform: rotate(180deg)
</style>
