<template lang="pug">
.tableFillTrainerTaskComponent
  table
    tbody
      tr(v-for="row in task.data.data.rows")
        td(
          v-for="cell in row",
          :class="{ _header: cell.type === 'header' }"
        )
          template(v-if="checkNonInputCell(cell)")
            .text {{ cell.value }}
          template(v-else)
            .input
              ui-input(
                variant="outlined"
                v-if="localAnswer[cell.index]"
                v-model="localAnswer[cell.index].answer"
                :key="cell.id"
                :is-disabled="isDisabled"
                :validation-status="statuses[cell.index]?.validationStatus"
              )
</template>

<script lang="ts" setup>
import { ValidationStatus } from '@/components/_ui/types'
import type { TrainerTaskTableFillControllerType } from '@abstract-trainer/controllers/TrainerTaskController'
import type {
  CellInput,
  CellNonInput,
  TableFillAnswerOption
} from '@abstract-trainer/services/types'
import { computed, ref, watch } from 'vue'

interface Props {
  task: ReturnType<TrainerTaskTableFillControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()

const localAnswer = ref<TableFillAnswerOption[]>(props.task.answer.localValue.value ?? [])
watch(props.task.answer.localValue, val => val && (localAnswer.value = val), { deep: true })
watch(localAnswer, props.task.answer.setLocalValue, { deep: true })

const statuses = computed(() => {
  const options = [...props.task.optionsWithValidateStatus.value]
  if (!props.highlightValidation) options.forEach(o => (o.validationStatus = ValidationStatus.None))
  return options
})

const checkNonInputCell = (cell: CellNonInput | CellInput): cell is CellNonInput => {
  return cell.type !== 'input'
}
</script>

<style lang="sass" scoped>
.tableFillTrainerTaskComponent
  max-width: 100%
  overflow-x: auto

  > table
    @include table

    td._header
      background-color: $colorPrimary !important
      color: $colorWhite
      @include font(t16-demibold)
      vertical-align: middle
      text-align: left
      padding: 2*$u 7*$u
      &:after
        width: 0 !important
</style>
