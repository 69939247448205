import { callApi } from '@core/api/apiWrapper'
import type { Paginated } from '@shared/models/types'
import type { CounterRemoteData, NotificationRemoteData } from '@notifications/services/types'

export const fetchCounter = async () => {
  const response = await callApi<CounterRemoteData>({
    method: 'get',
    url: 'profile/counters/'
  })
  return response
}

export const fetchNotifications = async (page = 1) => {
  const response = await callApi<Paginated<NotificationRemoteData>>({
    method: 'get',
    url: 'notification/',
    params: {
      page
    }
  })
  return response
}

export const readNotification = async (id: number) => {
  const response = await callApi({
    method: 'post',
    url: `notification/${id}/read/`
  })
  return response
}

export const readAllNotification = async () => {
  const response = await callApi({
    method: 'post',
    url: `notification/read_all/`
  })
  return response
}
