<template lang="pug">
.profileUpdateRequireFullCompleteModalComponent
  .form
    .title Для продолжения заполните электронную почту

    ui-input(
      v-model="email"
      :is-disabled="isLoading"
      variant="outlined"
      placeholder-text="Электронная почта"
      @keypress.enter="submitForm"
    )
    ui-errors.errors(:errors="errors && errors.email")

    ui-button.button(
      :is-disabled="!email"
      :is-loading="isLoading"
      @click="submitForm"
    ) Продолжить

    ui-button.button(
      v-if="emailAlreadyExists"
      :outlined="true"
      @click="onResetPasswordClick"
    ) Восстановить пароль

</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import ProfileUpdateController from '@user-profile/controllers/ProfileUpdateController'
import store from '@/store'
import { useRouter } from 'vue-router'

const emit = defineEmits(['close'])

const email = ref('')

const {
  requestSubmitForm,
  isLoading,
  $on,
  $off,
  updateErrors: errors
} = ProfileUpdateController(store.user)

const submitForm = () => {
  const formData = new FormData()
  formData.append('email', email.value)
  requestSubmitForm(formData)
}

const closeModal = () => {
  emit('close')
}

const emailAlreadyExists = computed(
  () => !!errors.value.email?.find(message => message.match('уже существует'))
)
const router = useRouter()
const _toResetPasswortPage = () => {
  router.push({ name: 'PasswordRecovery', query: email.value ? { email: email.value } : {} })
  closeModal()
}

const onResetPasswordClick = () => {
  store.user.auth.$once('logout-success', _toResetPasswortPage)
  store.user.auth.logout()
}

onMounted(() => {
  $on('request-profile-update-success', closeModal)
})

onUnmounted(() => {
  $off('request-profile-update-success', closeModal)
})
</script>

<style lang="sass" scoped>
@import "../../../Auth/styles"
</style>
