<template lang="pug">
.examplesNavigationComponent
  router-link.item(
    v-for="(item, index) in items"
    :key="index"
    :to="item.route"
    active-class="_active"
  ) {{ item.label }}
</template>

<script lang="ts" setup>
const items = [
  {
    label: 'Typography',
    route: { name: 'ExamplesTypography' }
  },
  {
    label: 'Icons',
    route: { name: 'ExamplesIcons' }
  },
  {
    label: 'Modals',
    route: { name: 'ExamplesModals' }
  },
  {
    label: 'Trainer',
    route: { name: 'ExamplesTrainer' }
  },
  {
    label: 'UI-kit',
    route: { name: 'ExamplesUI' }
  },
  {
    label: 'Emitter',
    route: { name: 'ExamplesEmitter' }
  },
  {
    label: 'Dadata',
    route: { name: 'ExamplesDadata' }
  }
]
</script>

<style lang="sass" scoped>
.examplesNavigationComponent
  background-color: $colorSecondary
  padding: 5*$u
  > .item
    display: block
    @include font(t16-demibold)
    color: $colorWhite

    &:not(:last-child)
      margin-bottom: 4*$u

    &:hover
      color: $colorPrimary
      text-decoration: underline

    &._active
      color: $colorPrimary
      text-decoration: none
      cursor: default
</style>
