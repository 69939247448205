<template lang="pug">
.fileUploaderComponent(:class="uploaderClasses")
  input.input(
    type="file",
    @change="onFileUpload",
    :accept="inputAccept",
    :disabled="isDisabled"
    ref="inputRef"
  )
  .placeholder
    .attachment(v-if="variant !== 'icon'")
      ui-attachment(
        :url="modelValue ? null : uploadedFile ? uploadedFile.url : null",
        :label="modelValue ? modelValue.name : uploadedFile ? uploadedFile.label : placeholderText",
        :type="modelValue ? modelValue.type : uploadedFile ? uploadedFile.type : null"
      )
    .icon
      ui-svg-icon(name="attachment")
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import type { IFile } from '@/components/_ui/types'
import store from '@/store'

interface Props {
  placeholderText?: string
  modelValue: File | null
  variant?: 'default' | 'outlined' | 'gray' | 'white' | 'icon'
  type?: string
  accept?: string
  uploadedFile?: IFile | null
  isDisabled?: boolean
  extensions?: string[]
  maxSize?: number
}

const props = withDefaults(defineProps<Props>(), {
  placeholderText: 'Выбрать файл',
  modelValue: null,
  variant: 'default',
  type: 'text',
  accept: '',
  uploadedFile: null,
  isDisabled: false,
  extensions: () => [],
  maxSize: 0
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: File | null): void
}>()

const inputRef = ref<HTMLInputElement | null>(null)

const inputAccept = computed(() =>
  props.extensions.length ? `${props.accept},.${props.extensions.join(',.')}` : props.accept
)

const uploaderClasses = computed(() => [`_${props.variant}`, { _disabled: props.isDisabled }])

const checkExtensionAccept = (extension?: string) => {
  if (!props.extensions.length) return true
  if (extension && !props.extensions.includes(extension)) return false
  return true
}

const checkFileSize = (size: number) => {
  return !props.maxSize || size <= props.maxSize
}

const onFileUpload = (e: Event) => {
  const target = e.target as HTMLInputElement

  if (!target.value || !target.files?.length) {
    emit('update:modelValue', null)
    return
  }

  const fileExtension = target.value.toLowerCase().split('.').pop()

  if (!checkExtensionAccept(fileExtension)) {
    store.ui.notices.newWarning(
      'Ошибка',
      `Разрешенные типы файлов: ${props.extensions.join(', ')}`,
      { duration: -1 }
    )

    target.value = ''
    emit('update:modelValue', null)
    return
  }

  const file = target.files[0]

  if (!checkFileSize(file.size)) {
    store.ui.notices.newWarning(
      'Ошибка',
      `Максимальный размер файла: ${props.maxSize / 1024 / 1024}Mb`,
      {
        duration: -1
      }
    )

    target.value = ''
    emit('update:modelValue', null)
    return
  }

  emit('update:modelValue', file)
}

const click = () => {
  inputRef.value?.click()
}

defineExpose({ click })
</script>

<style lang="sass" scoped>
.fileUploaderComponent
  position: relative
  user-select: none
  cursor: pointer

  > .input
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
    cursor: pointer

  > .placeholder
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    border-radius: $brXS
    color: $colorText
    padding: 1.5*$u
    @include font(t14)

    > .icon
      width: 7*$u
      height: 7*$u
      flex: 0 0 7*$u
      border-radius: $brXS
      background-color: $colorWhite
      color: $colorPrimary
      margin-left: 3*$u
      padding: 1*$u

    > .attachment
      flex: 1
      max-width: calc(100% - #{10*$u})

  &._disabled
    > .input
      cursor: not-allowed

  &._default
    > .placeholder
      background-color: $colorBackground2

  &._gray
    > .placeholder
      background-color: $colorBackground

  &._white
    > .placeholder
      background-color: $colorWhite

  &._icon
    > .placeholder
      padding: 3*$u
      background-color: $colorWhite
      border-radius: 50%
      > .icon
        background-color: transparent
        margin-left: 0
        width: 4*$u
        height: 4*$u
        flex: 0 0 4*$u
        padding: 0
</style>
