import type { UserDocumentRemoteData } from '@user-profile/services/types'
import type { IFile } from '@/components/_ui/types'
import type { ProfileDocumentType } from '@user-profile/config'
import { getLabelFromUrl } from '@shared/helpers/getLabelFromUrl'

const validate = (data: UserDocumentRemoteData) => {
  if (!data.id) throw new Error('Invalid user document data.')
}

export default class UserDocument implements IFile {
  id: number
  type: string
  documentType: ProfileDocumentType | null
  url: string
  label: string

  constructor(data: UserDocumentRemoteData) {
    validate(data)

    this.id = data.id
    this.documentType = data.type ?? null
    this.url = data.file ?? ''
    this.type = data.media_type ?? ''
    this.label = getLabelFromUrl(this.url)
  }
}
