import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Course from '@courses/models/Course'
import CourseStage from '@courses/models/CourseStage'
import router from '@/router'

export default class NotificationSelfAssessmentsTutor extends AbstractNotification {
  course: Course
  stage: CourseStage

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.course || !data.reference_data.stage) {
      throw new Error('Invalid NotificationSelfAssessmentsTutor data.')
    }
    this.course = new Course(data.reference_data.course)
    this.stage = new CourseStage(data.reference_data.stage)
  }

  get title() {
    return null
  }

  get message() {
    return `На курсе "${this.course.title}" в этапе "${this.stage.title}" доступны работы для Взаимооценивания.`
  }

  onActionClick() {
    router.push({
      name: 'SelfAssessmentList',
      query: {
        course: this.course.id,
        search_query: this.stage.title
      }
    })
  }
}
