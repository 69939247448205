<template lang="pug">
.footerStandartLogosComponent(:style="logosStyles")
  img.logo(src="/assets/images/prosv-logo.png")
  img.logo(src="/assets/images/academy-logo.svg")
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import store from '@/store'

const logosStyles = computed(() => ({ marginLeft: store.user.isAuthenticated.value ? 0 : 'auto' }))
</script>

<style lang="sass" scoped>
.footerStandartLogosComponent
  grid-area: logos
  display: grid
  grid-gap: 14*$u
  grid-auto-flow: column
  width: min-content
  margin-top: -12*$u

  > .logo
    height: 19*$u

  @media (max-width: $screenSizeL)
    margin-top: -6*$u

    > .logo
      height: 13*$u

  @media (max-width: $screenSizeS)
    margin-top: 0
    grid-gap: 5*$u
    margin-left: auto !important

  @media (max-width: $screenSizeXS)
    margin-left: 0 !important
</style>
