<template lang="pug">
.feedbackModalComponent
  template(v-if="!isSentSendFeedback")
    .title Форма обратной связи
    ui-alert(
      color="green"
      message="Пожалуйста, каждый вопрос оформляйте отдельным обращением. Перед отправкой проверьте соответствие содержания вашего вопроса и выбранной категории"
    )
    .field
      .title Выберите тему*
      ui-select(
        v-model="formData.theme"
        :options="themesOptions"
        placeholder-text="Выберите тему"
        :is-loading="isLoadingFeedbackThemes"
      )
    .field
      .title Полное название курса
      ui-input(v-model="formData.courseTitle")
    .field
      .title Ваш вопрос*
      ui-textarea(
        v-model="formData.comment"
        placeholder-text="Вопрос"
      )
    .field.files
      .title Прикрепите файл ({{extensions.join(', ')}})
      template(v-if="formData.files")
        ui-file-uploader-multiple(
          v-model="formData.files"
          :accept="accept"
          :extensions="extensions"
          placeholder-text="Прикрепить файл"
        )
    .field
      .title Субъект*
      ui-select(
        v-model="formData.regionId"
        :options="regionOptions"
        :is-loading="isRegionsLoading"
      )
    .field
      .title Контакты для обратной связи*
      .email.group
        ui-checkbox.option(
          type="radio"
          :modelValue="formData.answerType === AnswerType.Email"
          @click="selectAnswerType(AnswerType.Email)"
        ) E-mail
        ui-input.input(
          v-model="formData.email"
        )
      .email.group
        ui-checkbox.option(
          type="radio"
          :modelValue="formData.answerType === AnswerType.Phone"
          @click="selectAnswerType(AnswerType.Phone)"
        ) Телефон
        ui-input.input(
          v-model="formData.phone"
          mask="+#############"
          placeholder-text="Введите номер",
        )
    ui-errors(:errors="errors")
    ui-button.button(
      :is-disabled="isSubmitDisabled",
      :is-loading="isLoadingSendFeedback"
      size="l"
      @click="requestSendFeedback"
    ) Отправить
  template(v-else)
    .title Ваш вопрос успешно отправлен!
    .image(:style="imageStyles")
</template>

<script lang="ts" setup>
import store from '@/store'
import FeedbackController from '@/modules/Feedback/controllers/FeedbackController'
import RegionsController from '@dict/controllers/RegionsController'
import { AnswerType } from '@feedback/services/types'
import { computed, onMounted } from 'vue'
import imageUrl from '@/assets/images/paper-plane.svg'
import { EXTENSIONS_DOC, EXTENSIONS_IMAGE } from '@shared/config'
import isEmail from '@core/helpers/isEmail'

interface Props {
  themeId?: number
  courseId?: number
  courseTitle?: string
  stageId?: number
}
const props = defineProps<Props>()

defineEmits(['close'])

const profile = computed(() => store.user.profile.data.value)

const { regionOptions, requestRegions, isRegionsLoading } = RegionsController()
requestRegions()

const {
  requestFeedbackThemes,
  themesOptions,
  formData,
  errors,
  requestSendFeedback,
  isLoadingFeedbackThemes,
  isLoadingSendFeedback,
  isSentSendFeedback
} = FeedbackController(profile.value?.phone, profile.value?.email)

requestFeedbackThemes()

onMounted(() => {
  if (props.themeId) formData.theme = props.themeId
  if (props.courseId) formData.courseId = props.courseId
  if (props.courseTitle) formData.courseTitle = props.courseTitle
  if (props.stageId) formData.stageId = props.stageId
  if (profile.value?.regionId) formData.regionId = profile.value?.regionId
})

const accept = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png'
].join(',')

const extensions = [...EXTENSIONS_DOC, ...EXTENSIONS_IMAGE]

const imageStyles = { backgroundImage: `url(${imageUrl})` }

const selectAnswerType = (value: AnswerType) => {
  formData.answerType = value
}

const isSubmitDisabled = computed(
  () =>
    !formData.comment ||
    !formData.theme ||
    !formData.answerType ||
    !formData.regionId ||
    (formData.answerType === AnswerType.Email && !isEmail(formData.email ?? '')) ||
    (formData.answerType === AnswerType.Phone && !formData.phone)
)
</script>

<style lang="sass" scoped>
.feedbackModalComponent
  width: 160*$u
  max-width: 100%
  padding: 0 27*$u
  display: grid
  grid-gap: 4*$u
  grid-template-columns: 100%

  > .title
    @include font(h3)
    text-align: center

  > .field
    display: grid
    grid-gap: 2*$u
    grid-auto-columns: 100%

    > .title
      color: $colorGray

    > .group
      > .option
        margin-bottom: 2*$u

  > .button
    margin: 5*$u auto 0

  > .image
    width: 25*$u
    height: 25*$u
    background: center no-repeat
    background-size: contain
    margin: 0 auto

  @media (max-width: $screenSizeS)
    padding: 0 10*$u

  @media (max-width: $screenSizeXS)
    padding: 0

    > .button
      width: 100%
</style>
