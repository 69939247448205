import { ref, computed } from 'vue'
import useEventEmitter from '@core/hooks/useEventEmitter'
import useApiRequest from '@core/hooks/useApiRequest'
import { fetchAvailableRoles, getProfile, changeRole } from '@user-profile/services/profile'
import UserProfile from '@user-profile/models/UserProfile'
import store from '@/store'
import type { Role } from '@user-profile/config'

interface ProfileControllerEvents {
  'request-profile-success': UserProfile
  'request-profile-error': string[]
  'request-available-roles-success': unknown
  'request-available-roles-error': string[]
  'request-set-role-success': unknown
  'request-set-role-error': string[]
}

const ProfileController = () => {
  // Event emitter
  const { $on, $once, $off, $emit } = useEventEmitter<ProfileControllerEvents>()

  // Profile
  const data = ref<UserProfile | null>(null)

  const reset = () => {
    data.value = null
  }

  const profileRequest = useApiRequest(getProfile)

  profileRequest.$on('success', responseData => {
    data.value = new UserProfile(responseData)
    $emit('request-profile-success', data.value)
  })
  profileRequest.$on('error', errors => {
    $emit('request-profile-error', errors)
  })

  const request = async (id: number | null = null) => {
    await profileRequest.request(id)
  }

  // Roles
  const availableRoles = useApiRequest(fetchAvailableRoles)
  availableRoles.$on('success', responseData => {
    if (store.user.profile.data.value) store.user.profile.data.value.availableRoles = responseData
    $emit('request-available-roles-success', responseData)
  })
  availableRoles.$on('error', errors => {
    $emit('request-available-roles-error', errors)
  })
  const requestAvailableRoles = () => {
    availableRoles.request()
  }
  $on('request-profile-success', () => {
    requestAvailableRoles()
  })

  const setRole = useApiRequest(changeRole)
  setRole.$on('success', responseData => {
    $emit('request-set-role-success', responseData)
  })
  setRole.$on('error', errors => {
    $emit('request-set-role-error', errors)
  })
  const requestSetRole = (data: Role) => {
    setRole.request(data)
  }

  // Output
  const output = {
    data: computed(() => data.value),
    request,
    isLoading: computed(() => profileRequest.isLoading),
    isFullfilled: computed(() => profileRequest.isFullfilled),
    reset,
    requestSetRole,
    $on,
    $once,
    $off
  }
  return output
}

export default ProfileController
