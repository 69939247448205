<template lang="pug">
.messageFormComponent(:class="componentClasses")
  .form
    ui-textarea.input(
      :modelValue="modelValue"
      @update:modelValue="emit('update:modelValue', $event)"
      :placeholder-text="placeholderText"
      :smart="true"
      variant="white"
    )

    .button._submit(@click="onSubmitClick")
      ui-svg-icon.icon(v-if="!isLoading" name="plane")
      ui-loader(v-else)

    .button._attachment(
      v-if="showAttachment"
      :class="attachmentClasses"
      @click="onAttachmentClick"
    )
      ui-svg-icon.icon(name="attachment")
      ui-file-uploader.input(
        :modelValue="file"
        @update:modelValue="onAttachmentChange"
        :max-size="3145728"
        ref="attachmentRef"
      )

  ui-errors(:errors="errors")
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

interface Props {
  modelValue: string
  placeholderText?: string
  isLoading?: boolean
  errors?: string[]
  showAttachment?: boolean
  attachment?: File | null
}

const props = withDefaults(defineProps<Props>(), {
  placeholderText: 'Оставьте свой комментарий',
  isLoading: false,
  errors: () => [],
  showAttachment: false,
  attachment: null
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'update:attachment', value: File | null): void
  (e: 'submit'): void
}>()

const isFormValid = computed(() => props.modelValue?.length || props.attachment)

const componentClasses = computed(() => ({ _valid: isFormValid.value }))

const attachmentRef = ref<HTMLInputElement | null>(null)
const file = ref<File | null>(null)
const attachmentClasses = computed(() => ({ _valid: !!props.attachment }))

const onAttachmentClick = () => {
  attachmentRef.value?.click()
}

const onAttachmentChange = (newVal: File | null) => {
  file.value = newVal
  emit('update:attachment', file.value)
}

const onSubmitClick = () => {
  if (!isFormValid.value || props.isLoading) return
  emit('submit')
}
</script>

<style lang="sass" scoped>
.messageFormComponent
  > .form
    background: shade($colorCourse3, -80%)
    display: flex
    align-items: flex-end
    padding: 2*$u
    border-radius: $brXS

    > .input
      flex: 1 0 1px

    > .button
      width: 10*$u
      height: 10*$u
      flex-basis: 10*$u
      border-radius: 50%
      background: $colorWhite
      margin: 0 2*$u
      display: flex
      align-items: center
      justify-content: center

      > .icon
        width: 6*$u
        height: 6*$u
        flex-basis: 6*$u
        color: $colorGray2
        transition: $appAnimationSpeed

      &._submit
        margin-left: 4*$u

        > .icon
          margin-left: -$u

      &._attachment
        cursor: pointer

        > .input
          display: none

        &._valid
          > .icon
            color: $colorPrimary

  &._valid
    > .form
      > .button
        &._submit
          cursor: pointer
          > .icon
            color: $colorPrimary
</style>
