import dayjs from 'dayjs'
import type { AppealConfigRemoteData } from '@appeals/services/types'
import DiagnosticsItem from '@diagnostics/models/DiagnosticsItem'
import getDatesFormatted from '@courses/helpers/course-dates-formatted'
import Course from '@courses/models/Course'
import CourseFlow from '@courses/models/CourseFlow'

const validate = (data: AppealConfigRemoteData) => {
  if (!data.id) throw new Error('Invalid appeal config data.')
}

export default class AppealConfig {
  id: number
  dateFrom: dayjs.Dayjs | null
  dateTo: dayjs.Dayjs | null
  requestDateFrom: dayjs.Dayjs | null
  requestDateTo: dayjs.Dayjs | null
  workingHoursFrom: string | null
  workingHoursTo: string | null
  slotDuration: number | null
  diagnosticsItem: DiagnosticsItem | null
  course: Course | null
  courseFlow: CourseFlow | null
  constructor(data: AppealConfigRemoteData) {
    validate(data)

    this.id = data.id
    this.requestDateFrom = data.request_date_from ? dayjs(data.request_date_from) : null
    this.requestDateTo = data.request_date_to ? dayjs(data.request_date_to) : null
    this.dateFrom = data.date_from ? dayjs(data.date_from) : null
    this.dateTo = data.date_to ? dayjs(data.date_to) : null
    this.workingHoursFrom = data.working_hours_from ?? null
    this.workingHoursTo = data.working_hours_to ?? null
    this.slotDuration = data.slot_duration ?? null
    this.diagnosticsItem = data.diagnostic ? new DiagnosticsItem(data.diagnostic) : null
    this.course = data.course ? new Course(data.course) : null
    this.courseFlow = data.course_flow ? new CourseFlow(data.course_flow) : null
  }

  get requestDatesFormatted() {
    return getDatesFormatted(this.requestDateFrom, this.requestDateTo) ?? ''
  }
  get datesFormatted() {
    return getDatesFormatted(this.dateFrom, this.dateTo) ?? ''
  }
}
