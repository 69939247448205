<template lang="pug">
.inputComponent(:class="componentClasses")
  template(v-if="!mask")
    input.input(
      ref="input"
      :value="modelValue"
      :placeholder="placeholderText"
      @input="onInput"
      :disabled="isDisabled"
      :autocomplete="autocomplete"
      :min="min"
      :max="max"
      @blur="emit('blur')"
      :type="showEye ? (isShownByEye ? 'text' : type) : type"
    )
  template(v-else)
    input.input(
      ref="input"
      :value="maskedValue"
      :placeholder="placeholderText"
      @maska="onInput"
      :disabled="isDisabled"
      :autocomplete="autocomplete"
      :min="min"
      :max="max"
      @blur="emit('blur')"
      v-maska="mask"
      :type="showEye ? (isShownByEye ? 'text' : type) : type"
    )

  template(v-if="showEye")
    .eye(
      :style="{backgroundImage: `url(${isShownByEye ? eyeHiddenIcon : eyeIcon})`}"
      @click="toggleShowByEye"
    )

</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ValidationStatus } from '../types'
import { mask as toMask, tokens } from 'maska'
import useFlag from '@core/hooks/useFlag'
import eyeIcon from '@/assets/images/eye.svg'
import eyeHiddenIcon from '@/assets/images/eye-hidden.svg'

interface Props {
  placeholderText?: string
  modelValue: string | number | null
  variant?: 'default' | 'outlined' | 'gray' | 'white'
  type?: string
  showEye?: boolean
  isDisabled?: boolean
  validationStatus?: ValidationStatus | null
  autocomplete?: string
  min?: number | string
  max?: number | string
  mask?: string
}

const props = withDefaults(defineProps<Props>(), {
  placeholderText: '',
  modelValue: '',
  variant: 'default',
  type: 'text',
  showEye: undefined,
  validationStatus: ValidationStatus.None,
  autocomplete: undefined,
  min: undefined,
  max: undefined,
  mask: undefined
})

const componentClasses = computed(() => [
  `_${props.variant}`,
  {
    _disabled: props.isDisabled,
    _valid: props.validationStatus === ValidationStatus.Valid,
    _invalid: props.validationStatus === ValidationStatus.Invalid
  }
])

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'blur'): void
}>()

const maskedValue = computed(() =>
  !!props.mask
    ? toMask(props.modelValue?.toString() ?? '', props.mask, tokens, true)
    : props.modelValue
)

const onInput = (e: Event) => {
  if (props.isDisabled) return
  const target = e.target as HTMLInputElement
  if (props.type === 'number') {
    if (+target.value <= props.min) target.value = props.min.toString()
    if (+target.value >= props.max) target.value = props.max.toString()
  }
  if (!!props.mask) {
    emit('update:modelValue', target.dataset.maskRawValue || target.value)
  } else {
    emit('update:modelValue', target.value)
  }
}

const { flag: isShownByEye, toggle: toggleShowByEye } = useFlag('_is-shown', false)

const input = ref<HTMLInputElement | null>(null)
defineExpose({ input })
</script>

<style lang="sass" scoped>
.inputComponent
  position: relative

  > .eye
    flex: 0 0 24px 24px
    width: 24px
    height: 24px
    background: center no-repeat
    background-size: contain
    margin: auto
    cursor: pointer
    position: absolute
    top: 8px
    right: 15px

  > .input
    border: 1px solid transparent
    display: block
    width: 100%
    padding: 2.25*$u
    @include font(t14)
    border-radius: $brXS

  > input[type=date]::-webkit-calendar-picker-indicator
    visibility: hidden

  &._default
    > .input
      background: $colorBackground2

  &._gray
    > .input
      background: $colorBackground

  &._white
    > .input
      background: $colorWhite

  &._outlined
    > .input
      border: 0.25*$u solid $colorGray
      background: $colorWhite

  &._disabled
    > .input
      cursor: not-allowed

  &._valid
    > .input
      background: $colorCalendar3

  &._invalid
    > .input
      background: $colorCalendar1
</style>
