import type { RouteRecordRaw } from 'vue-router'
import isAuthenticated from '@router/middleware/isAuthenticated'
import { accessRoles } from '@router/middleware/rolePermissions'
import { Role } from '@user-profile/config'

const selfAssessmentsRoutes: RouteRecordRaw[] = [
  {
    path: '/self-assessment',
    name: 'SelfAssessment',
    component: () => import('./SelfAssessmentHome.vue'),
    redirect: { name: 'SelfAssessmentList' },
    meta: {
      middleware: [isAuthenticated]
    },
    children: [
      {
        path: '',
        name: 'SelfAssessmentList',
        component: () => import('./SelfAssessmentList.vue'),
        meta: {
          middleware: [accessRoles([Role.Tutor])]
        }
      },
      {
        path: ':id',
        name: 'SelfAssessmentItemEdit',
        props: route => ({
          id: Number(route.params.id),
          resultsOnly: false
        }),
        component: () => import('./SelfAssessmentItem.vue')
      },
      {
        path: ':id/results',
        name: 'SelfAssessmentItemResults',
        props: route => ({
          id: Number(route.params.id),
          resultsOnly: true
        }),
        component: () => import('./SelfAssessmentItem.vue')
      }
    ]
  }
]

export default selfAssessmentsRoutes
