import AbstractTrainer from '@abstract-trainer/models/AbstractTrainer'
import { createTrainerTask } from '@abstract-trainer/models/tasks/create-trainer-task'
import type { TrainerTask } from '@abstract-trainer/services/types'
import type { TrainerRemoteData } from '@courses-trainer/services/types'
import { TrainerScoreType, type TrainerResultIcons } from '@abstract-trainer/config'

const validate = (data: TrainerRemoteData) => {
  if (!data.id) throw new Error('Invalid trainer data.')
}

export default class Trainer extends AbstractTrainer {
  id: number
  tasks: TrainerTask[]
  maxAttempts: number
  duration: number
  scoreType: TrainerScoreType
  labelsResultText: Partial<Record<TrainerResultIcons, string>> = {}

  constructor(data: TrainerRemoteData) {
    super()
    validate(data)

    this.id = data.id
    this.tasks = data.quiz_list ? data.quiz_list.map(o => createTrainerTask(o)) : []
    this.maxAttempts = data.max_attempts ?? 0
    this.duration = data.max_duration ?? 0
    this.scoreType = data.score_type ?? TrainerScoreType.Point
    this.skipTimerNotice = data.skip_timer_notice ?? false

    if (data.result_labels?.passed) this.labelsResultText.valid = data.result_labels?.passed
    if (data.result_labels?.failed) this.labelsResultText.invalid = data.result_labels?.failed
  }
}
