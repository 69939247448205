import { CourseStructureItem } from '@courses/config'

const getStructureLabel = (labels: string[], item: CourseStructureItem) => {
  let label
  switch (item) {
    case CourseStructureItem.Module:
      label = labels[labels.length - 4]
      break
    case CourseStructureItem.Topic:
      label = labels[labels.length - 3]
      break
    case CourseStructureItem.Lesson:
      label = labels[labels.length - 2]
      break
    case CourseStructureItem.Stage:
      label = labels[labels.length - 1]
  }
  return label ?? ''
}

export default getStructureLabel
