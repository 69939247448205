import type { CourseStageAccess } from '@courses/config'
import type { CourseStateStagesAccessRemoteData } from '@courses/services/types'

const generateStagesAccessMap = (data: CourseStateStagesAccessRemoteData[], isCompact: boolean) => {
  const access: Map<number, CourseStageAccess> = new Map()
  for (const group of data) {
    if (isCompact && group.stages) {
      group.stages.forEach(stage => {
        access.set(stage.id, stage.access)
      })
    } else if (group.topics) {
      group.topics.forEach(topic => {
        topic.lessons.forEach(lesson => {
          lesson.stages.forEach(stage => {
            access.set(stage.id, stage.access)
          })
        })
      })
    }
  }
  return access
}

export default generateStagesAccessMap
