import type { CourseAuthorRemoteData } from '@courses/services/types'

const validate = (data: CourseAuthorRemoteData) => {
  if (!data.id) throw new Error('Invalid Course Author data.')
}

export default class CourseAuthor {
  id: number
  name: string
  position: string
  avatarUrl: string

  constructor(data: CourseAuthorRemoteData) {
    validate(data)

    this.id = data.id
    this.name = data.name ?? ''
    this.position = data.position ?? ''
    this.avatarUrl = data.avatar ?? ''
  }
}
