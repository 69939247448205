import { ValidationStatus, type IOptionWithValidation } from '@/components/_ui/types'
import { fromJsonString } from '@shared/helpers/from-json'
import MultipleSelectTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/MultipleSelectTrainerTask/MultipleSelectTrainerTask.vue'
import { TaskType } from '@abstract-trainer/config'
import type {
  MultipleSelectTaskDataLocalData,
  MultipleSelectTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import { markRaw } from 'vue'
import type { UnwrapRef, Component } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'

export default class TrainerTaskMultipleSelect extends AbstractTrainerTask {
  type: TaskType.MultipleSelect
  data: MultipleSelectTaskDataLocalData
  component: Component

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.MultipleSelect)
      throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    this.type = data.test_type

    this.data = formatData(data.data as MultipleSelectTaskDataRemoteData)

    this.component = markRaw(MultipleSelectTrainerTask)
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null) {
    if (!answer)
      return this.data.options.map(o => ({ ...o, validationStatus: ValidationStatus.None }))
    const results: IOptionWithValidation<string | number>[] = []
    let index = 0
    const parsedAnswer = fromJsonString(answer.answer, []) as number[]
    this.data.options.forEach(o => {
      if (parsedAnswer.includes(o.value)) {
        results.push({
          ...o,
          validationStatus: answer.validatedInputs[index].isValid
            ? ValidationStatus.Valid
            : ValidationStatus.Invalid
        })
        index++
      } else {
        results.push({ ...o, validationStatus: ValidationStatus.None })
      }
    })
    return results
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    const parsedAnswer = fromJsonString(answer?.answer, [])
    return parsedAnswer as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    if (!answerValue) return false
    return !!(<number[]>answerValue).filter(i => !!i).length
  }
}

const formatData = (data: MultipleSelectTaskDataRemoteData): MultipleSelectTaskDataLocalData => {
  const result = {
    options: data.variant.map(o => {
      const item = {
        value: o.id,
        label: o.text,
        image: o.image ?? ''
      }
      return item
    })
  }
  return result
}
