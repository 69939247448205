import { callApi } from '@core/api/apiWrapper'

export const checkAttachmentAccess = async (url: string) => {
  const response = await callApi({
    method: 'GET',
    url,
    remote: true,
    authRequired: true
  })

  return response
}
