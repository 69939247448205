import { callApi } from '@core/api/apiWrapper'
import type {
  AcademicDegreeRemoteData,
  AcademicTitleRemoteData,
  ActionTypeRemoteData,
  CitizenshipRemoteData,
  LevelRemoteData,
  MunicipalityRemoteData,
  OrganizationRemoteData,
  PositionRemoteData,
  RegionRemoteData,
  SubjectRemoteData,
  CivilServantRemoteData,
  ProfileTypeRemoteData
} from './types'
// import type { CallApiReturn } from '@core/api/types'

export const fetchRegions = async (filters: Partial<{ hasCourses: boolean }> = {}) => {
  const params: Partial<{ course: boolean }> = {}
  if (filters.hasCourses) params.course = filters.hasCourses
  const response = await callApi<RegionRemoteData[]>({
    method: 'get',
    url: 'dict/region/',
    params,
    authRequired: false
  })
  return response
}

export const fetchRegion = async (id: number) => {
  const response = await callApi<RegionRemoteData>({
    method: 'get',
    url: `dict/region/${id}/`,
    authRequired: false
  })
  return response
}

export const fetchMunicipalities = async (filters: Partial<{ regionId: number | null }> = {}) => {
  const params: Partial<{ region: number | null }> = {}
  if (filters.regionId) params.region = filters.regionId
  const response = await callApi<MunicipalityRemoteData[]>({
    method: 'get',
    url: 'dict/municipalitet/',
    params,
    authRequired: false
  })
  return response
}

export const fetchMunicipality = async (id: number) => {
  const response = await callApi<MunicipalityRemoteData>({
    method: 'get',
    url: `dict/municipalitet/${id}/`,
    authRequired: false
  })
  return response
}

export const fetchCitizenships = async () => {
  const response = await callApi<CitizenshipRemoteData[]>({
    method: 'get',
    url: 'dict/citizenship/',
    authRequired: false
  })
  return response
}

export const fetchLevels = async () => {
  const response = await callApi<LevelRemoteData[]>({
    method: 'get',
    url: 'dict/level/',
    authRequired: false
  })
  return response
}

export interface OrganizationsFilters {
  municipalityId?: number
  regionId?: number
  hasCourses?: boolean
  search?: string
}
interface OrganizationsParams {
  municipalitet?: number
  region?: number
  course?: boolean
  search?: string
}

export const fetchOrganizations = async (filters: Partial<OrganizationsFilters> = {}) => {
  const params: Partial<OrganizationsParams> = {}
  if (filters.municipalityId) params.municipalitet = filters.municipalityId
  if (filters.regionId) params.region = filters.regionId
  if (filters.hasCourses) params.course = filters.hasCourses
  if (filters.search) params.search = filters.search
  const response = await callApi<OrganizationRemoteData[]>({
    method: 'get',
    url: 'dict/organization/',
    params,
    authRequired: false
  })
  return response
}

export const fetchOrganization = async (id: number) => {
  const response = await callApi<OrganizationRemoteData>({
    method: 'get',
    url: `dict/organization/${id}/`,
    authRequired: false
  })
  return response
}

export const fetchSubjects = async () => {
  const response = await callApi<SubjectRemoteData[]>({
    method: 'get',
    url: 'subject/'
  })
  return response
}

export const fetchAcademicDegrees = async () => {
  const response = await callApi<AcademicDegreeRemoteData[]>({
    method: 'get',
    url: 'dict/academicdegree/',
    authRequired: false
  })
  return response
}

export const fetchAcademicTitles = async () => {
  const response = await callApi<AcademicTitleRemoteData[]>({
    method: 'get',
    url: 'dict/academictitle/',
    authRequired: false
  })
  return response
}

interface PositionsFilters {
  organizationType?: number | null
}

export const fetchPositions = async (filters?: PositionsFilters) => {
  const params: Record<string, string | number> = {}
  if (filters?.organizationType) params.organization_type = filters.organizationType
  const response = await callApi<PositionRemoteData[]>({
    method: 'get',
    url: 'dict/positions/',
    params
  })
  return response
}

interface OrganizationTypesFilters {
  position?: number | null
}

export const fetchOrganizationTypes = async (filters?: OrganizationTypesFilters) => {
  const params: Record<string, string | number> = {}
  if (filters?.position) params.position = filters.position
  const response = await callApi<PositionRemoteData[]>({
    method: 'get',
    url: 'dict/organization_type/',
    params
  })
  return response
}

export const fetchActionTypes = async () => {
  const response = await callApi<ActionTypeRemoteData[]>({
    method: 'get',
    url: 'dict/action_type/',
    authRequired: false
  })
  return response
}

export const fetchCivilServants = async () => {
  const response = await callApi<CivilServantRemoteData[]>({
    method: 'get',
    url: 'dict/civil_servant/',
    authRequired: false
  })
  return response
}

export const fetchProfileTypes = async () => {
  const response = await callApi<ProfileTypeRemoteData[]>({
    method: 'get',
    url: 'dict/profile_type/',
    authRequired: false
  })
  return response
}

export const fetchUserProfileTypes = async () => {
  const response = await callApi<ProfileTypeRemoteData[]>({
    method: 'get',
    url: 'dict/user_profile_type/',
    authRequired: false
  })
  return response
}
