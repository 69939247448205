<template lang="pug">
.footerStandartPersonalDataComponent
  a(
    :href="href"
    download
  ) Согласие на обработку персональных данных
</template>

<script lang="ts" setup>
import { PUBLIC_PATH } from '@/config'

// const href = PUBLIC_PATH + 'assets/docs/Согласие_на_обработку_персональных_данных.pdf'
const href = PUBLIC_PATH + 'assets/docs/Согласие_на_обработку_персональных_данных_ЦЭДПО.pdf'
</script>

<style lang="sass" scoped>
.footerStandartPersonalDataComponent
  > a
    @include font('t14')
    color: $colorWhite
    white-space: nowrap

    &:hover
      text-decoration: underline
</style>
