import type { App } from 'vue'

import ExamplesNavigation from './ExamplesNavigation/ExamplesNavigation.vue'
import ExamplesPageTitle from './ExamplesPageTitle/ExamplesPageTitle.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ExamplesNavigation: typeof ExamplesNavigation
    ExamplesPageTitle: typeof ExamplesPageTitle
  }
}

export default function initializeExamplesUiKit(app: App): void {
  app.component('ExamplesNavigation', ExamplesNavigation)
  app.component('ExamplesPageTitle', ExamplesPageTitle)
}
