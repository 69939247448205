// TODO: ts uuid
/* eslint-disable */ // @ts-ignore
import { v4 as uuidv4 } from 'uuid'

export enum NoticeType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}

export interface NoticeConstructorData {
  type?: NoticeType
  title?: string
  content?: string
  duration?: number
}

export default class Notice {
  id: string
  type: NoticeType
  title: string
  content: string
  duration: number

  constructor(data: NoticeConstructorData) {
    this.id = uuidv4()
    this.type = data.type || NoticeType.Info
    this.title = data.title ?? ''
    this.content = data.content ?? ''
    this.duration = data.duration ?? 5000
  }
}
