<template lang="pug">
.tabSwitchItemComponent(
  :class="itemClasses",
  @click="onItemClick"
)
  .icon(v-if="item.icon")
    ui-svg-icon(
      :name="item.icon",
      :is-responsive="true"
    )
  .text {{ item.label }}
  .counter(v-if="item.counter") +{{item.counter}}
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { UiTabSwitchOption } from './types'

interface Props {
  item: UiTabSwitchOption
  isSelected: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false
})

const emit = defineEmits<{
  (e: 'select', value: number): void
}>()

const onItemClick = () => {
  if (!props.isSelected) emit('select', props.item.value)
}

const itemClasses = computed(() => ({ _selected: props.isSelected }))
</script>

<style lang="sass" scoped>
.tabSwitchItemComponent
  height: 8*$u
  display: flex
  align-items: center
  justify-content: center
  border-radius: 99em
  color: $colorPrimary
  padding: 0 3*$u
  cursor: pointer
  background: transparent
  transition: $appAnimationSpeed

  > .icon
    width: 4*$u
    height: 4*$u
    flex: 0 0 4*$u
    margin-right: 1*$u

  > .text
    @include font(t12-demibold)
    text-transform: uppercase
    white-space: nowrap

  > .counter
    @include font(t12-demibold)
    padding: 0.5*$u 1.5*$u
    border-radius: 99em
    background-color: $colorPrimary
    color: $colorWhite
    margin-left: 1.5*$u

  &._selected
    color: $colorWhite
    background-color: $colorPrimary
    cursor: default

    > .counter
      background-color: $colorSecondary
</style>
