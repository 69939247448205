import { ValidationStatus, type IOptionWithValidation } from '@/components/_ui/types'
import { fromJsonString } from '@shared/helpers/from-json'
import MatchTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/MatchTrainerTask/MatchTrainerTask.vue'
import { TaskType } from '@abstract-trainer/config'
import type {
  MatchTaskDataLocalData,
  MatchTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import { markRaw } from 'vue'
import type { UnwrapRef, Component } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'

export default class TrainerTaskMatch extends AbstractTrainerTask {
  type: TaskType.Match
  data: MatchTaskDataLocalData
  component: Component

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.Match) throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    this.type = data.test_type

    this.data = formatData(data.data as MatchTaskDataRemoteData)

    this.component = markRaw(MatchTrainerTask)
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null): IOptionWithValidation[] {
    if (!answer) return []
    return this.data.groups[0].options.map((o, idx) => ({
      ...o,
      validationStatus: !answer.validatedInputs[idx]
        ? ValidationStatus.None
        : answer.validatedInputs[idx].isValid
        ? ValidationStatus.Valid
        : ValidationStatus.Invalid
    }))
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    const parsedAnswer = fromJsonString(answer?.answer, [])
    const result = parsedAnswer.length
      ? [...parsedAnswer]
      : new Array(this.data.groups[0].options.length).fill(null)
    return result as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    return !answerValue ? false : !!(<number[]>answerValue).filter(i => !!i).length
  }
}

const formatData = (data: MatchTaskDataRemoteData): MatchTaskDataLocalData => {
  const formattedData: MatchTaskDataLocalData = {
    layout: data.layout,
    groups: [
      {
        title: data.column1_name || '',
        options: []
      },
      {
        title: data.column2_name || '',
        options: []
      }
    ]
  }
  formattedData.groups[0].options = data.column1.map(o => ({
    value: o.id,
    label: o.text,
    image: o.image
  }))
  formattedData.groups[1].options = data.column2.map(o => ({
    value: o.id,
    label: o.text,
    image: o.image
  }))
  return formattedData
}
