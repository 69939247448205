import type { RouteRecordRaw } from 'vue-router'
import isAuthenticated from '@router/middleware/isAuthenticated'

const forumRoutes: RouteRecordRaw[] = [
  {
    path: '/forum/:id/',
    name: 'ForumHome',
    component: () => import('./ForumHome.vue'),
    props: route => ({ id: Number(route.params.id) }),
    redirect: { name: 'ForumTopics' },
    meta: {
      middleware: [isAuthenticated]
    },
    children: [
      {
        path: '',
        name: 'ForumTopics',
        component: () => import('./ForumTopics.vue')
      },
      {
        path: 'topic/:topicId/',
        name: 'ForumTopicsItem',
        props: route => ({ topicId: Number(route.params.topicId) }),
        component: () => import('./ForumTopicsItem.vue')
      }
    ]
  }
]
export default forumRoutes
