import type { RouteRecordRaw } from 'vue-router'

const webPagesRoutes: RouteRecordRaw[] = [
  {
    path: '/web-pages',
    name: 'WebPages',
    component: () => import('./WebPagesHome.vue'),
    redirect: { name: 'NotFound', params: { notFound: '404' } },
    children: [
      {
        path: ':id',
        name: 'WebPagesItem',
        props: route => ({ id: Number(route.params.id) }),
        component: () => import('./WebPagesItem.vue')
      }
    ]
  }
]
export default webPagesRoutes
