import useApiRequest from '@/_core/hooks/useApiRequest'
import useEventEmitter from '@core/hooks/useEventEmitter'
import BaseDictionary from '@dict/models/BaseDictionary'
import type { DictionaryRemoteData, DictionaryService } from '@dict/services/types'
import { computed, ref, type UnwrapRef } from 'vue'

interface Events {
  'request-dictionaries-success': never
  'request-dictionaries-error': string[]
}

type DictionaryCreator<D, C> = (data: D) => C
const defaultCreator = (data: DictionaryRemoteData) => new BaseDictionary(data)

const DictionaryBaseController = <
  RemoteDataType extends DictionaryRemoteData,
  DictionaryClass extends BaseDictionary,
  ServiceParams extends unknown[]
>(
  service: DictionaryService<RemoteDataType, ServiceParams>,
  creator?: DictionaryCreator<RemoteDataType, DictionaryClass>
) => {
  // Base Dictionary
  const { $on, $off, $emit, $once } = useEventEmitter<Events>()

  const items = ref<DictionaryClass[]>([])
  const itemsRequest = useApiRequest(service)
  itemsRequest.$on('success', responseData => {
    $emit('request-dictionaries-success')
    items.value = responseData.map(creator ?? defaultCreator) as UnwrapRef<DictionaryClass[]>
  })
  const request = itemsRequest.request

  const options = computed<DictionaryClass['option'][]>(() => items.value.map(item => item.option))

  itemsRequest.$on('error', errors => {
    $emit('request-dictionaries-error', errors)
  })

  return {
    // dictionary items
    items,
    request,
    isLoading: computed(() => itemsRequest.isLoading),
    options,
    $on,
    $off,
    $once
  }
}

export default DictionaryBaseController
