<template lang="pug">
div
  .htmlTextComponent(
    ref="elementRef"
    v-html="value"
  )
  div(style="clear:both;")
</template>

<script lang="ts" setup>
import { onMounted, ref, watch, h, createApp } from 'vue'
import CollapseButton from './CollapseButton.vue'
import useFlag from '@core/hooks/useFlag'
import UiSvgIconVue from '../UiSvgIcon/UiSvgIcon.vue'

const CUT_ELEMENT_TAG = 'blockquote'

interface Props {
  value: string
}

withDefaults(defineProps<Props>(), {
  value: ''
})
const elementRef = ref<HTMLElement | null>(null)

const handleCuts = () => {
  // create collapse button & hide inner content
  const cuts = elementRef.value?.querySelectorAll(CUT_ELEMENT_TAG)
  cuts?.forEach(($cut, index) => {
    const id = `cut-${index}`
    const buttonContainer = document.createElement('div')
    buttonContainer.id = id
    $cut.after(buttonContainer)

    const { flag: isCollapsed, toggle: onClick } = useFlag('_collapsed', false)

    watch(
      isCollapsed,
      bool => {
        $cut.style.display = bool ? 'block' : 'none'
      },
      { immediate: true }
    )

    const button = h(CollapseButton, { isCollapsed, onClick })

    createApp(() => button)
      .component('UiSvgIcon', UiSvgIconVue)
      .mount(`#${id}`)
  })
}

onMounted(handleCuts)
</script>

<style lang="sass" scoped>
.htmlTextComponent
  color: $colorText
  white-space: pre-wrap
  @include font(t16)

  &:deep(h1)
    @include font(h1)
    margin-bottom: 3*$u
  &:deep(h2)
    @include font(h2)
    margin-bottom: 3*$u
  &:deep(h3)
    @include font(h3)
    margin-bottom: 3*$u
  &:deep(h4)
    @include font(h4)
    margin-bottom: 3*$u
  &:deep(h5)
    @include font(t16-demibold)
    margin-bottom: 3*$u

  &:deep(p)
    font-family: 'GolosTextRegular' !important
    margin-bottom: 3*$u

  &:deep(span)
    font-family: 'GolosTextRegular' !important

  &:deep(b)
    @include font(t16-bold)

  &:deep(ul)
    display: block
    list-style-type: disc
    margin-top: 1em
    margin-bottom: 1em
    margin-left: 0
    margin-right: 0
    padding-left: 10*$u
    > li
      display: list-item
      margin-bottom: 2*$u

  &:deep(ol)
    display: block
    list-style-type: decimal
    margin-top: 1em
    margin-bottom: 1em
    margin-left: 0
    margin-right: 0
    padding-left: 10*$u
    > li
      display: list-item
      margin-bottom: 2*$u

  &:deep(table)
    max-width: 100% !important
    margin: 0 auto
    @include font(t16)
    tbody
      tr
        td
          color: $colorText
          border: 0.25*$u solid $colorGray
          padding: 3.5*$u 5*$u

  &:deep(sub)
    vertical-align: sub
    font-size: 2*$u

  &:deep(sup)
    vertical-align: super
    font-size: 2*$u

  &:deep(u)
    text-decoration: underline

  &:deep(i)
    font-style: italic

  &:deep(pre)
    display: block
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace
    font-size: 14px
    word-break: break-all
    word-wrap: break-word
    white-space: pre-wrap
    border: .25*$u solid #ccc
    border-radius: 1*$u
    background-color: #f5f5f5
    padding: 2.5*$u

  &:deep(a)
    color: $colorPrimary

  &:deep(img)
    max-width: 100%
    &.note-float-left
      margin: 0 3*$u 3*$u 0
    &.note-float-right
      margin: 0 0 3*$u 3*$u

  &:deep(>*:last-child)
    margin-bottom: 0
</style>
