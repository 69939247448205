import Region from '@dict/models/Region'
import useApiRequest from '@/_core/hooks/useApiRequest'
import { fetchRegion, fetchRegions } from '@dict/services/dictionaries'
import { computed, ref } from 'vue'
import DictionaryBaseController from '@dict/controllers/DictionaryBaseController'

const RegionsController = () => {
  // Regions
  const {
    items: regions,
    request: requestRegionsItems,
    isLoading: isRegionsLoading,
    options: regionOptions
  } = DictionaryBaseController(fetchRegions, data => new Region(data))

  const requestRegions = async (hasCourses?: boolean) => {
    await requestRegionsItems({ hasCourses })
  }

  // Single region
  const region = ref<Region | null>(null)
  const regionRequest = useApiRequest(fetchRegion)
  regionRequest.$on('success', responseData => {
    region.value = new Region(responseData)
  })
  const requestRegion = async (id: number) => {
    await regionRequest.request(id)
  }

  return {
    // regions
    regions,
    requestRegions,
    isRegionsLoading,
    regionOptions,

    // single region
    region,
    requestRegion,
    isRegionLoading: computed(() => regionRequest.isLoading)
  }
}

export default RegionsController
