<template lang="pug">
.headerBurgerMenuComponent(:class="burgerMenuClasses")
  .container
    .navigation
      .item(v-for="(item, index) in navigation" :key="index")
        header-burger-menu-link(
          @close-burger="emit('closeBurger')"
          :item="item"
        )
    ui-button.loginButton(
      v-if="!profile"
      @click="goToLogin"
      :is-loading="isLoadingLoginButton"
      outlined
      icon="user"
    ) Вход в личный кабинет
    template(v-else)
      .separator
      .menu
        .item(@click="goToProfile") Мой профиль
        .item(@click="goToUpdateProfile") Редактирование профиля
        .item._select(
          v-if="profile.canChangeRole"
          @click.stop
        ) Сменить роль
          .input
            ui-select(
              v-model="role",
              :options="rolesOptions",
              placeholder-text="Выберите роль"
            )
        a.item(
          v-if="profile.hasAccessToAdminPanel"
          :href="adminUrl",
          target="_blank"
        ) Панель администрирования
        .item(@click="logout") Выйти
</template>

<script lang="ts" setup>
import store from '@/store'
import { ADMIN_URL } from '@/config'
import { useRouter, useRoute } from 'vue-router'
import { computed } from 'vue'
import useNavigation from '@nav/hooks/useNavigaion'
import type UserController from '@user/controllers/UserController'
import HeaderBurgerMenuLink from '@/components/_layout/Header/HeaderBurger/HeaderBurgerMenuLink.vue'
import type { Role } from '@user-profile/config'

interface Props {
  user: ReturnType<typeof UserController>
}
const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'closeBurger'): void
}>()
const emitCloseBurger = () => emit('closeBurger')

const { items: navigation } = useNavigation(store.user)
const adminUrl = ADMIN_URL
const isLoadingLoginButton = computed(
  () => store.user.auth.isLoading.value || store.user.profile.isLoading.value
)
const burgerMenuClasses = computed(() => ({ _homepage: isHomepage.value }))

// router
const router = useRouter()
const route = useRoute()
const isHomepage = computed(() => route.name === 'Home')
const goToLogin = () => {
  router.push({ name: 'Login' })
  emitCloseBurger()
}
const goToProfile = () => {
  router.push({
    name: 'ProfileInfo',
    params: {
      id: props.user.id.value
    }
  })
  emitCloseBurger()
}
const goToUpdateProfile = () => {
  router.push({
    name: 'ProfileUpdate'
  })
  emitCloseBurger()
}
const logout = () => {
  props.user.auth.logout()
  router.push({ name: 'Home' })
  emitCloseBurger()
}

// roles
const profile = computed(() => props.user.profile.data.value)
const role = computed<Role>({
  get() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return profile.value!.role
  },
  set(value: Role) {
    store.user.profile.requestSetRole(value)
    profile.value?.changeRole(value)
    router.push({ name: 'Home' })
    emitCloseBurger()
  }
})
const rolesOptions = computed(
  () =>
    profile.value?.availableRoles.map(role => ({
      label: profile.value?.roleTitle(role) ?? '',
      value: role
    })) ?? []
)
</script>

<style lang="sass" scoped>
.headerBurgerMenuComponent
  position: absolute
  background: $colorWhite
  top: calc(100% + 4*#{$u})
  width: 100%
  left: 0
  right: 0
  height: 100vh
  padding-top: 8*$u
  padding-bottom: calc(#{$headerHeight} + 8*#{$u})
  z-index: $zIndexBurgerMenu
  transition: $appAnimationSpeed
  &._homepage
    top: calc(100% + 4*#{$u})
    width: calc(100% - #{$appSidePaddingDesktop})
    left: calc(#{$appSidePaddingDesktop} / 2)
    right: calc(#{$appSidePaddingDesktop} / 2)
    @media (max-width: $screenSizeXS)
      width: calc(100% - #{$appSidePaddingMobile})
      left: calc(#{$appSidePaddingMobile} / 2)
      right: calc(#{$appSidePaddingMobile} / 2)
    &:before
      display: none

  &:before
    position: absolute
    content: ''
    width: 200%
    height: 100%
    display: block
    left: -50%
    top: 0
    background: $colorWhite
    z-index: -1
    @media (max-width: $screenSizeM)
      display: none

  &:after
    content: ""
    display: block
    position: absolute
    z-index: -1
    bottom: 0
    right: -25*$u
    width: 80*$u
    height: 70vh
    background: url("/assets/images/burger-menu-bg.svg") no-repeat 0 0 transparent
    background-size: contain
    transform: rotate(30deg)

  .container
    height: 100%
    @include appContainer
    overflow: hidden auto

  .navigation
    display: grid
    grid-gap: 4*$u
    justify-content: start

  .loginButton
    margin-top: 8*$u

  .separator
    border-top: 2px solid $colorBackground2
    margin: 8*$u 0

  .menu
    display: grid
    grid-gap: 4*$u
    justify-content: start
    .item
      @include font('t18')
      color: $colorPrimary
      cursor: pointer
</style>
