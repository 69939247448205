<template lang="pug">
.uiSelectOptionComponent(:class="optionClasses")
  template(v-if="isMultiple")
    ui-checkbox(
      :is-decorative="true"
      :model-value="isSelected"
    ) {{ option.label }}
  template(v-else) {{ option.label }}
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { ISelectOption } from '@/components/_ui/types'

interface Props {
  option: ISelectOption
  isSelected?: boolean
  isDisabled?: boolean
  isMultiple?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  isDisabled: false,
  isMultiple: false
})

const optionClasses = computed(() => {
  return {
    _selected: props.isSelected,
    _disabled: props.isDisabled
  }
})
</script>

<style lang="sass" scoped>
.uiSelectOptionComponent
  padding: 2*$u 4*$u
  cursor: pointer
  word-break: break-word

  &:hover
    background-color: $colorBackground2
  &._selected
    color: $colorPrimary
  &._disabled
    color: $colorGray
</style>
