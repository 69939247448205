import { ref, computed, watch } from 'vue'

export default function useFlag(
  className: string,
  initialValue = false,
  delayOff: number | null = null
) {
  const flag = ref<boolean>(initialValue)

  const cssClass = computed(() => ({ [className]: flag.value }))

  let _shouldClose = false

  const on = () => {
    flag.value = true
    if (delayOff !== null) {
      _shouldClose = false
    }
  }

  const off = () => {
    if (delayOff !== null) {
      _shouldClose = true
      setTimeout(() => {
        if (_shouldClose) flag.value = false
      }, delayOff)
    } else {
      flag.value = false
    }
  }

  const toggle = () => {
    flag.value ? off() : on()
  }

  const watchFlagChange = (callback: (value: boolean) => void, immediate = true) => {
    watch(flag, callback, { immediate })
  }

  return {
    flag,
    cssClass,
    on,
    off,
    toggle,
    watchFlagChange
  }
}
