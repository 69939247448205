import type { NotificationRemoteData } from '@notifications/services/types'
import { NotificationType } from '@notifications/config'
import NotificationUnknown from '@notifications/models/NotificationUnknown'
import NotificationWrong from '@notifications/models/NotificationWrong'
import NotificationCourseAccessStudent from '@notifications/models/NotificationCourseAccessStudent'
import NotificationCourseAccessTutor from '@notifications/models/NotificationCourseAccessTutor'
import NotificationCourseFinished from '@notifications/models/NotificationCourseFinished'
import NotificationDiagnosticsPending from '@notifications/models/NotificationDiagnosticsPending'
import NotificationDiagnosticsChecked from '@notifications/models/NotificationDiagnosticsChecked'
import NotificationGroupAttachment from '@notifications/models/NotificationGroupAttachment'
import NotificationSelfAssessmentsTutor from '@notifications/models/NotificationSelfAssessmentsTutor'
import NotificationSelfAssessmentsStudent from '@notifications/models/NotificationSelfAssessmentsStudent'
import NotificationTestPending from '@notifications/models/NotificationTestPending'
import NotificationTestChecked from '@notifications/models/NotificationTestChecked'
import NotificationWebinarVideo from '@notifications/models/NotificationWebinarVideo'
import NotificationWebinarStarted from '@notifications/models/NotificationWebinarStarted'
import NotificationWebinarFinished from '@notifications/models/NotificationWebinarFinished'
import NotificationCourseCommentAdded from './NotificationCourseCommentAdded'
import NotificationForumMessage from './NotificationForumMessage'
import NotificationFlowAnnouncement from '@notifications/models/NotificationFlowAnnouncement'

export const notificationsFactory = (data: NotificationRemoteData) => {
  try {
    switch (data.event_type) {
      case NotificationType.CourseAccessStudent:
        return new NotificationCourseAccessStudent(data)

      case NotificationType.CourseAccessTutor:
        return new NotificationCourseAccessTutor(data)

      case NotificationType.CourseFinished:
        return new NotificationCourseFinished(data)

      case NotificationType.DiagnosticsPending:
        return new NotificationDiagnosticsPending(data)

      case NotificationType.DiagnosticsChecked:
        return new NotificationDiagnosticsChecked(data)

      case NotificationType.GroupAttachment:
        return new NotificationGroupAttachment(data)

      case NotificationType.SelfAssessmentsTutor:
        return new NotificationSelfAssessmentsTutor(data)

      case NotificationType.SelfAssessmentsStudent:
        return new NotificationSelfAssessmentsStudent(data)

      case NotificationType.TestPending:
        return new NotificationTestPending(data)

      case NotificationType.TestChecked:
        return new NotificationTestChecked(data)

      case NotificationType.WebinarVideo:
        return new NotificationWebinarVideo(data)

      case NotificationType.WebinarStarted:
        return new NotificationWebinarStarted(data)

      case NotificationType.WebinarFinished:
        return new NotificationWebinarFinished(data)

      case NotificationType.CourseCommenAdded:
        return new NotificationCourseCommentAdded(data)

      case NotificationType.ForumMessage:
        return new NotificationForumMessage(data)

      case NotificationType.NewFlowAnnouncement:
        return new NotificationFlowAnnouncement(data)

      default:
        console.log('test Unknown', data.event_type)
        return new NotificationUnknown(data)
    }
  } catch (error) {
    console.warn(error)
    console.log('test error', data.event_type, data)
    return new NotificationWrong({
      ...data,
      title: 'Ошибка уведомления',
      message: 'Пожалуйста, обратитесь к администратору'
    })
  }
}
