import isNotAuthenticated from '@router/middleware/isNotAuthenticated'
import keepLastRoute from '@user-auth/middlewares/keepLastRoute'
import type { RouteRecordRaw } from 'vue-router'

const authRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    props: route => ({ email: route.query.email }),
    component: () => import('@user-auth/pages/AuthLogin.vue'),
    meta: {
      middleware: [isNotAuthenticated, keepLastRoute],
      redirectToRoute: { name: 'Home' }
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    props: route => ({ email: route.query.email }),
    component: () => import('@user-auth/pages/AuthRegistration.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      redirectToRoute: { name: 'Home' }
    }
  },
  {
    path: '/complete-registration',
    name: 'CompleteRegistration',
    props: route => ({
      token: route.query.token,
      login: route.query.login
    }),
    component: () => import('@user-auth/pages/AuthRegistrationComplete.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      redirectToRoute: { name: 'Home' }
    }
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    props: route => ({ email: route.query.email }),
    component: () => import('@user-auth/pages/AuthPasswordRecovery.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      redirectToRoute: { name: 'Home' }
    }
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    props: route => ({ token: route.query.token }),
    component: () => import('@user-auth/pages/AuthPasswordNew.vue'),
    meta: {
      middleware: [isNotAuthenticated],
      redirectToRoute: { name: 'Home' }
    }
  }
]

export default authRoutes
