<template lang="pug">
.headerNavigationComponent
  .items
    header-navigation-item(
      v-for="(item, index) in mainItems"
      :key="index"
      :item="item"
      :is-inverted-colors="isInvertedColors"
    )
    .dots(
      v-if="dotsItems.length"
      @click="onDotsClick"
      v-click-outside="onClickOutside"
      :class="dotsClasses"
    ) ...
      transition(name="fade")
        .dropdown(v-if="isDotsOpened")
          header-navigation-item(
            v-for="(item, index) in dotsItems"
            :key="index"
            :item="item"
          )
</template>

<script lang="ts" setup>
import HeaderNavigationItem from '@/components/_layout/Header/HeaderNavigation/HeaderNavigationItem.vue'
import useNavigation from '@nav/hooks/useNavigaion'
import store from '@/store'
import { computed } from 'vue'
import useFlag from '@core/hooks/useFlag'

interface Props {
  isInvertedColors: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isInvertedColors: false
})

// items
const { mainItems, dotsItems } = useNavigation(store.user)

// dots dropdown
const { flag: isDotsOpened, off: closeDots, toggle: toggleDots } = useFlag('_opened', false)

const dotsClasses = computed(() => ({
  _inverted: props.isInvertedColors
}))

const onDotsClick = () => {
  toggleDots()
}
const onClickOutside = () => {
  closeDots()
}
</script>

<style lang="sass" scoped>
.headerNavigationComponent
  > .items
    display: grid
    grid-auto-flow: column
    gap: 6*$u
    @media (max-width: $screenSizeM)
      gap: 3*$u

  .dots
    @include font('t18-demibold')
    position: relative
    cursor: pointer
    color: $colorPrimary
    user-select: none

    &._inverted
      color: $colorWhite

    .dropdown
      position: absolute
      top: calc(100% + #{5*$u})
      left: 50%
      transform: translateX(-50%)
      width: fit-content
      background-color: $colorWhite
      box-shadow: -3px 0px 13px rgba($colorText, .2)
      border-radius: $brXS
      z-index: $zIndexNotifications
      color: $colorText
      padding: 3*$u
      cursor: initial
      user-select: initial
      &:before
        content: ''
        border-left: 2*$u $colorWhite solid
        border-top: 2*$u $colorWhite solid
        border-right: 2*$u transparent solid
        border-bottom: 2*$u transparent solid
        position: absolute
        top: -2*$u
        transform-origin: center
        transform: rotate(45deg)
        right: calc(50% - #{2*$u})
        box-shadow: -4*$u -4*$u 4*$u rgba($colorText, .1)
      &:deep()
        .headerNavigationItemComponent
          white-space: nowrap
          .link
            border-color: transparent
</style>
