import type { CourseLessonRemoteData } from '@courses/services/types'
import CourseStage from '@courses/models/CourseStage'
import getStructureLabel from '@courses/helpers/course-structure-label'
import { CourseStructureItem } from '@courses/config'

interface CourseLessonConstructorData extends CourseLessonRemoteData {
  structureLabels?: string[]
}

const validate = (data: CourseLessonConstructorData) => {
  if (!data.id) throw new Error('Invalid Course Lesson data.')
}

export default class CourseLesson {
  id: number
  title: string
  description: string
  number: number
  structureLabel: string
  stages: CourseStage[]
  isInvariant: boolean

  constructor(data: CourseLessonConstructorData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
    this.description = data.description ?? ''
    this.number = data.number ?? 0
    this.structureLabel = getStructureLabel(data.structureLabels ?? [], CourseStructureItem.Lesson)
    this.isInvariant = data.is_invariant ?? false
    this.stages =
      data.stages?.map(
        o =>
          new CourseStage({
            ...o,
            structureLabels: data.structureLabels,
            is_invariant: data.is_invariant
          })
      ) ?? []
  }

  get titleFull() {
    return `${this.structureLabel} ${this.number}. ${this.title}`
  }

  get stagesCountLecture() {
    return this.stages.filter(o => o.isLecture).length
  }

  get stagesCountTest() {
    return this.stages.filter(o => o.isTest).length
  }

  get hasOnlyOfflineStages() {
    return this.stages.every(stage => stage.offlineInfo)
  }

  get hasOnlyOnlineStages() {
    return this.stages.every(stage => !stage.offlineInfo)
  }
}
