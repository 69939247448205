<template lang="pug">
.collapseButtonComponent(
  :class="{_collapsed: _isCollapsed}"
)
  .text {{_isCollapsed ? 'Свернуть' : 'Развернуть'}}
  .chevron
    ui-svg-icon(name="chevron")
</template>

<script lang="ts" setup>
import { computed, unref } from 'vue'

interface Props {
  isCollapsed: object | boolean
}

const props = withDefaults(defineProps<Props>(), {
  isCollapsed: true
})

const _isCollapsed = computed(() => unref(props.isCollapsed))
</script>

<style lang="sass" scoped>
.collapseButtonComponent
  width: 30*$u
  height: 5.5*$u
  display: flex
  align-items: center
  justify-content: space-between
  position: relative
  color: $colorPrimary
  padding: 0 1*$u
  margin-bottom: 2*$u
  cursor: pointer

  > .text
    @include font(t14-demibold)
    text-transform: uppercase

  > .chevron
    flex: 0 0 2.25*$u
    width: 2.25*$u
    height: 2.25*$u
    transform-origin: center

  &._collapsed
    > .chevron
      transform: rotate(180deg)

  &:before
    content: ''
    position: absolute
    top: 60%
    bottom: 0
    right: -0.5*$u
    left: -0.5*$u
    background-color: $colorPrimary
    opacity: .2
</style>
