import { markRaw, type DefineComponent } from 'vue'

type BackgroundColorType = 'white' | 'gray'

interface ModalParams {
  isClosable?: boolean
  backgroundColor?: BackgroundColorType
}

export interface ModalConstructorData {
  key: string
  component: unknown
  params?: ModalParams
  props?: { [key: string]: unknown }
  on?: { [key: string]: (...args: unknown[]) => void }
}

export default class ModalData {
  key: string
  component: unknown
  params: ModalParams
  props: { [key: string]: unknown }
  on: { [key: string]: (...args: unknown[]) => void }

  constructor(data: ModalConstructorData) {
    if (!data) throw new Error('Modal options is empty!')
    if (!data.key || typeof data.key !== 'string')
      throw new Error('Modal options has no valid key property!')

    this.key = data.key

    const component = data.component as DefineComponent
    component.emits = ['close', 'scroll-to-top', ...(component.emits as string[])]
    this.component = markRaw(component)

    this.props = data.props ?? {} // inner conmponent's props
    this.on = data.on ?? {} // inner conmponent's event handlers

    const params: Partial<ModalParams> = {}
    params.isClosable = data.params?.isClosable ?? true
    params.backgroundColor = data.params?.backgroundColor ?? 'white'

    this.params = params as ModalParams
  }
}
