import useEventEmitter from '@core/hooks/useEventEmitter'
import useApiRequest from '@core/hooks/useApiRequest'
import { TokenName } from '@user-auth/config'
import type {
  LoginFormData,
  PasswordNewFormData,
  PasswordRecoveryFormData,
  RegisterCompleteFormData,
  RegisterCompleteErrorRemoteData,
  RegisterFormData,
  AuthLoginErrorRemoteData,
  RegisterErrorRemoteData
} from '@user-auth/services/types'
import {
  changePassword,
  completeRegistration,
  loginUser,
  logoutUser,
  recoverPassword,
  register
} from '@user-auth/services/auth'
import { computed } from '@vue/reactivity'
// import store from '@/store'

interface AuthControllerEvents {
  'request-code': string
  'login-success': string
  'login-error': AuthLoginErrorRemoteData
  'logout-success': unknown
  'request-register-success': unknown
  'request-register-error': RegisterErrorRemoteData | string[]
  'request-register-complete-success': unknown
  'request-register-complete-error': RegisterCompleteErrorRemoteData | string[]
  'request-password-recovery-success': unknown
  'request-password-recovery-error': string[]
  'request-password-new-success': unknown
  'request-password-new-error': string[]
}

const AuthController = () => {
  // Event emitter
  const { $on, $off, $emit, $once } = useEventEmitter<AuthControllerEvents>()

  // Token.
  const getToken = () => {
    return localStorage.getItem(TokenName.Auth)
  }

  const setToken = (token: string) => {
    localStorage.setItem(TokenName.Auth, token)
  }

  const removeToken = () => {
    localStorage.setItem(TokenName.Auth, '')
  }

  // Login
  const loginRequest = useApiRequest(loginUser)

  const login = (formData: LoginFormData) => {
    loginRequest.request(formData)
  }

  loginRequest.$on('success', responseData => {
    setToken(responseData.token)
    $emit('login-success', responseData.token)
  })
  loginRequest.$on('error', errors => {
    if (Array.isArray(errors)) {
      errors.forEach(error => {
        // store.ui.notices.newError(error)
      })
    } else {
      if (errors.need_code) {
        $emit('request-code')
      } else $emit('login-error', errors)
    }
  })

  // Logout
  const logoutRequest = useApiRequest(logoutUser)

  const logout = async () => {
    removeToken()
    await logoutRequest.request()
    $emit('logout-success')
  }

  // Register
  const registerRequest = useApiRequest(register)

  registerRequest.$on('success', () => {
    $emit('request-register-success')
  })
  registerRequest.$on('error', errors => {
    $emit('request-register-error', errors)
  })

  const requestRegister = async (formData: RegisterFormData) => {
    await registerRequest.request(formData)
  }

  // Register comlete
  const registerCompleteRequest = useApiRequest(completeRegistration)

  registerCompleteRequest.$on('success', responseData => {
    setToken(responseData.token)
    $emit('request-register-complete-success')
  })
  registerCompleteRequest.$on('error', errors => {
    $emit('request-register-complete-error', errors)
  })

  const requestRegisterComplete = async (formData: RegisterCompleteFormData) => {
    await registerCompleteRequest.request(formData)
  }

  // Password recovery
  const passwordRecoveryRequest = useApiRequest(recoverPassword)

  passwordRecoveryRequest.$on('success', () => {
    $emit('request-password-recovery-success')
  })
  passwordRecoveryRequest.$on('error', errors => {
    $emit('request-password-recovery-error', errors)
  })

  const requestPasswordRecovery = async (formData: PasswordRecoveryFormData) => {
    await passwordRecoveryRequest.request(formData)
  }

  // Password new
  const passwordNewRequest = useApiRequest(changePassword)

  passwordNewRequest.$on('success', responseData => {
    setToken(responseData.token)
    $emit('request-password-new-success')
  })
  passwordNewRequest.$on('error', errors => {
    $emit('request-password-new-error', errors)
  })

  const requestPasswordNew = async (formData: PasswordNewFormData) => {
    await passwordNewRequest.request(formData)
  }

  // Output
  const output = {
    login,
    logout,
    getToken,
    setToken,
    isLoading: computed(
      () =>
        loginRequest.isLoading ||
        logoutRequest.isLoading ||
        registerRequest.isLoading ||
        registerCompleteRequest.isLoading ||
        passwordRecoveryRequest.isLoading ||
        passwordNewRequest.isLoading
    ),
    requestRegister,
    requestRegisterComplete,
    requestPasswordRecovery,
    requestPasswordNew,
    $on,
    $off,
    $once
  }
  return output
}

export default AuthController
