<template lang="pug">
.radialProgressComponent
  svg.svg
    circle.circle.background(
      :cx="pixelSize / 2",
      :cy="pixelSize / 2",
      :r="normalizedRadius"
    )
    circle.circle.second(
      :cx="pixelSize / 2",
      :cy="pixelSize / 2",
      :r="normalizedRadius"
    )
    circle.circle.first(
      :cx="pixelSize / 2",
      :cy="pixelSize / 2",
      :r="normalizedRadius"
    )
  .value
    .label(v-if="labelText") {{labelText}}
    .counter(
      v-else
    ) {{ percentageValue ? `${percent}%` : completedSteps }}
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  size?: number
  totalSteps?: number
  firstSteps?: number
  secondSteps?: number
  percentageValue?: boolean
  labelText?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: 25,
  totalSteps: 100,
  firstSteps: 0,
  secondSteps: 0,
  labelText: '',
  percentageValue: false
})

const pixelSize = computed(() => props.size * 4)

const strokeWidth = computed(() => {
  return pixelSize.value / 9
})
const normalizedRadius = computed(() => {
  return (pixelSize.value - strokeWidth.value) / 2
})
const circumference = computed(() => {
  return normalizedRadius.value * 2 * Math.PI
})
const completedSteps = computed(() => {
  return props.firstSteps + props.secondSteps
})
const percent = computed(() => {
  return props.totalSteps ? Math.round((completedSteps.value / props.totalSteps) * 100) : 0
})
const firstPercent = computed(() => {
  return props.totalSteps ? Math.round((props.firstSteps / props.totalSteps) * 100) : 0
})
const secondPercent = computed(() => {
  return props.totalSteps ? Math.round((props.secondSteps / props.totalSteps) * 100) : 0
})
const firstOffset = computed(
  () => circumference.value - (firstPercent.value / 100) * circumference.value
)
const secondOffset = computed(
  () =>
    circumference.value - ((secondPercent.value + firstPercent.value) / 100) * circumference.value
)
</script>

<style lang="sass" scoped>
.radialProgressComponent
  position: relative
  width: fit-content

  > .svg
    width: calc(v-bind(size) * #{$u})
    height: calc(v-bind(size) * #{$u})
    position: relative
    display: block
    transform: rotate(-90deg)

    > .circle
      width: 80%
      height: 80%
      fill: none
      stroke: $colorCourse3
      stroke-linecap: round
      stroke-dashoffset: 0
      transition: stroke-dashoffset 1s linear
      stroke-width: v-bind(strokeWidth)

      &.first
        stroke-dasharray: v-bind(circumference)
        stroke-dashoffset: v-bind(firstOffset)
        stroke: $colorSecondary

      &.second
        stroke-dasharray: v-bind(circumference)
        stroke-dashoffset: v-bind(secondOffset)
        stroke: $colorCourse1

  > .value
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    @include font(t16-demibold)
    font-size: calc(v-bind(size) / 4 * #{$u})
    line-height: calc(v-bind(size) / 4 * #{$u})
    white-space: nowrap
    color: $colorPrimary

    > .label
      @include font(t14-demibold)
      font-size: calc(v-bind(size) / 4.5 * #{$u})
      line-height: calc(v-bind(size) / 4.5 * #{$u})
      color: $colorText
      text-align: center
      white-space: pre-wrap
</style>
