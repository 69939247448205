import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import initializeUiKit from '@/components/_ui'
import clickOutside from '@core/directives/click-outside'
import initializeExamplesUiKit from '@examples/components/_ui'

import '@/assets/styles/general.sass'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import duration from 'dayjs/plugin/duration'

import Maska from 'maska'

dayjs.extend(duration)
dayjs.locale('ru')

const app = createApp(App)

initializeUiKit(app)

const isDevMode = import.meta.env.VITE_APP_MODE === 'development' ? true : false

if (isDevMode) {
  initializeExamplesUiKit(app)
}

if (!isDevMode) {
  const dsn = <string>import.meta.env.VITE_APP_SENTRY
  Sentry.init({
    // beforeSend(event) {
    //   if (1) return event
    //   return null
    // },
    app,
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    // TODO change it for dev & prod
    tracePropagationTargets: ['https://dev-education.apkpro.ru', /^\/api\/v2\//],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app.use(router)
app.use(Maska)
app.directive('click-outside', clickOutside)

app.mount('#app')
