import dayjs from 'dayjs'
import type { CourseFlowRemoteData } from '@courses/services/types'
import { FlowCourseStatus, FlowStatus } from '@courses/config'
import getDatesFormatted from '@courses/helpers/course-dates-formatted'
import AppealConfig from '@appeals/models/AppealConfig'

const validate = (data: CourseFlowRemoteData) => {
  // if (!data.id) throw new Error('Invalid course flow data.')
}

export default class CourseFlow {
  id: number
  dateFrom: dayjs.Dayjs | null
  dateTo: dayjs.Dayjs | null
  status: FlowStatus
  courseStatus: FlowCourseStatus
  name: string
  announcingCount: number
  appeal: AppealConfig | null

  constructor(data: CourseFlowRemoteData) {
    validate(data)
    this.id = data.id
    this.name = data.name ?? ''
    this.dateFrom = data.start ? dayjs(data.start) : null
    this.dateTo = data.finish ? dayjs(data.finish) : null

    this.status = data.status ?? FlowStatus.InProgress // Определяется на бэке по дате
    this.courseStatus = data.course_state ?? FlowCourseStatus.InProgress // Определяется на бэке по заначению селекта
    this.announcingCount = data.announcing_count ?? 0
    this.appeal = data.appeal_config ? new AppealConfig(data.appeal_config) : null
  }

  get isNotStarted() {
    return this.status === FlowStatus.NotStarted || this.courseStatus === FlowCourseStatus.Awaiting
  }
  get isInProgress() {
    return (
      this.status === FlowStatus.InProgress && this.courseStatus === FlowCourseStatus.InProgress
    )
  }
  get isFinished() {
    return this.status === FlowStatus.Finished || this.courseStatus === FlowCourseStatus.Complited
  }

  get datesFormatted(): string | null {
    return getDatesFormatted(this.dateFrom, this.dateTo)
  }

  get hasAnnouncing() {
    return !!this.announcingCount
  }
}
