import type { FederalDistrictRemoteData } from '@dict/services/types'
import BaseDictionary from '@dict/models/BaseDictionary'
import type { DadataInfo } from './DadataInfoInterface'

export default class FederalDistrict extends BaseDictionary implements DadataInfo {
  fromDadata: boolean

  constructor(data: FederalDistrictRemoteData) {
    super(data)
    this.fromDadata = data.from_dadata ?? false
  }
}
