import IframeTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/IframeTrainerTask/IframeTrainerTask.vue'
import { TaskType } from '@abstract-trainer/config'
import type {
  IframeTaskDataLocalData,
  IframeTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import { markRaw, type Component, type UnwrapRef } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'

export default class TrainerTaskIframe extends AbstractTrainerTask {
  type: TaskType.Iframe
  data: IframeTaskDataLocalData
  component: Component
  isDataEmpty = false // Задание для ответа файлом поддерживает пустую структуру

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.Iframe) {
      throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    } else {
      this.type = data.test_type
    }

    this.data = formatData(data.data as IframeTaskDataRemoteData)

    if (!this.data.url) {
      throw new Error('Отсутствует поле url в задании типа Iframe')
    }

    this.component = markRaw(IframeTrainerTask)
  }

  getOptionsWithValidatedStatus() {
    return []
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    return '' as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }
}

const formatData = (data: IframeTaskDataRemoteData): IframeTaskDataLocalData => ({ url: data.url })
