<template lang="pug">
.noticesListComponent
  transition-group(name="notices")
    notices-list-item(
      v-for="item in items"
      :key="item.id"
      :item="item"
    )
</template>

<script lang="ts" setup>
import store from '@/store'
import { computed, onMounted } from 'vue'
import NoticesListItem from '@ui-notices/components/NoticesList/NoticesListItem.vue'

interface Props {
  ignoreDuplicates?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  ignoreDuplicates: true
})

onMounted(() => {
  if (!props.ignoreDuplicates) {
    store.ui.notices.setIgnoreDuplicates(false)
  }
})

const items = computed(() => {
  return store.ui.notices?.items.value
})
</script>

<style lang="sass" scoped>
.noticesListComponent
  width: 105*$u
  position: fixed
  top: 26*$u
  right: $appSidePaddingDesktop
  pointer-events: none
  z-index: $zIndexNotices
  display: grid
  gap: 3*$u
  justify-items: end
  @media (max-width: $screenSizeXS)
    justify-items: initial
    width: calc(100vw - 2*#{$appSidePaddingMobile})
    right: $appSidePaddingMobile

.notices-enter-active, .notices-leave-active
  transition: all 0.5s linear
.notices-enter-from, .notices-leave-to
  opacity: 0
  transform: translateY(-100px)
.notices-enter-to, .notices-leave-from
  opacity: 1
  transform: translateY(0)
.notices-leave-active
  position: absolute
.notices-move
  transition: all 0.5s ease
</style>
