import {
  CourseAccessType,
  CourseAquirementCode,
  CourseFormat,
  COURSE_AQUIREMENTS_DATA,
  COURSE_FORMAT_TITLES
} from '@courses/config'
import type { CourseRemoteData } from '@courses/services/types'
import CourseAuthor from '@courses/models/CourseAuthor'
import CourseReview from '@courses/models/CourseReview'
import CourseAquirement from '@courses/models/CourseAquirement'
import CourseModule from '@courses/models/CourseModule'
import CourseLesson from '@courses/models/CourseLesson'
import CourseProfile from './CourseProfile'
// import getDatesFormatted from '@courses/helpers/course-dates-formatted'
import Organization from '@dict/models/Organization'
import CourseFlow from './CourseFlow'
import type CourseStage from './CourseStage'
import getLast from '@shared/helpers/getLast'
import type { RouteLocationRaw } from 'vue-router'
import router from '@/router'

const validate = (data: CourseRemoteData) => {
  if (!data.id) throw new Error('Course has no ID')
  // if (!data.flows || !data.flows.length) throw new Error('Course has no flows')
}

export default class Course {
  id: number
  title: string
  flows: CourseFlow[]
  descriptionFull: string
  descriptionShort: string
  duration: number
  price: number
  accessType: CourseAccessType
  coverUrl: string
  coverUrlSmall: string
  format: CourseFormat
  personalDataRequired: boolean
  structureLabels: string[]
  authors: CourseAuthor[]
  requirements: string[]
  reviews: CourseReview[]
  hasCertificate: boolean
  modules: CourseModule[]
  lessons: CourseLesson[]
  isCompact: boolean
  profile: CourseProfile | null
  organization: Organization | null
  isShowComments: boolean
  forumEnabled: boolean
  forumId: number | null

  _stagesCache: Map<number, CourseStage>

  constructor(data: CourseRemoteData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
    this.flows = data.flows ? data.flows.map(o => new CourseFlow(o)) : []
    this.descriptionFull = data.description ?? ''
    this.descriptionShort = data.short_description ?? ''
    this.duration = data.duration ?? 0
    this.price = data.cost ?? 0
    this.accessType = data.pay_type ?? CourseAccessType.Free
    this.coverUrl = data.cover ?? ''
    this.coverUrlSmall = data.icon ?? ''
    this.format = data.course_format ?? CourseFormat.Online
    this.personalDataRequired = data.personal_data_required ?? false
    this.structureLabels = data.structure_labels ?? []
    this.authors = data.authors?.map(o => new CourseAuthor(o)) ?? []
    this.requirements = data.requirements?.map(o => o.requirement) ?? []
    this.reviews = data.reviews?.map(o => new CourseReview(o)) ?? []
    this.hasCertificate = data.has_certificate ?? false
    this.modules =
      data.modules?.map(o => new CourseModule({ ...o, structureLabels: this.structureLabels })) ??
      []
    this.lessons =
      data.lessons?.map(o => new CourseLesson({ ...o, structureLabels: this.structureLabels })) ??
      []
    this.isCompact = data.is_compact ?? false
    this.profile = data.profile_type ? new CourseProfile(data.profile_type) : null

    this.organization = data.organization ? new Organization(data.organization) : null
    this.isShowComments = data.show_comments ?? false
    this.forumEnabled = data.forum_enabled ?? false
    this.forumId = data.forum ?? null

    this._stagesCache = new Map<number, CourseStage>()
  }

  get formatTitle() {
    return COURSE_FORMAT_TITLES[this.format]
  }

  get aquirements() {
    const aquirements = []
    if (this.hasCertificate)
      aquirements.push(
        new CourseAquirement(COURSE_AQUIREMENTS_DATA[CourseAquirementCode.Certificate])
      )
    aquirements.push(new CourseAquirement(COURSE_AQUIREMENTS_DATA[CourseAquirementCode.Documents]))
    return aquirements
  }

  get stageIds() {
    const ids: number[] = []
    if (this.isCompact) {
      this.lessons.forEach(lesson => {
        lesson.stages.forEach(stage => ids.push(stage.id))
      })
    } else {
      this.modules.forEach(module => {
        module.topics.forEach(topic => {
          topic.lessons.forEach(lesson => {
            lesson.stages.forEach(stage => ids.push(stage.id))
          })
        })
      })
    }
    return ids
  }

  _cacheStage(id: number, stage: CourseStage) {
    this._stagesCache.set(id, stage)
    return stage
  }

  getStageById(id: number) {
    const cache = this._stagesCache.get(id)
    if (cache) return cache

    if (this.isCompact)
      for (const lesson of this.lessons)
        for (const stage of lesson.stages) {
          if (stage.id === id) return this._cacheStage(id, stage)
        }
    else
      for (const module of this.modules)
        for (const topic of module.topics)
          for (const lesson of topic.lessons)
            for (const stage of lesson.stages) {
              if (stage.id === id) return this._cacheStage(id, stage)
            }

    return null
  }

  // Flows
  get hasManyFlows() {
    return this.flows.length > 1
  }

  get hasOneFlow() {
    return this.flows.length === 1
  }

  get _invariantIndex() {
    return this.isCompact
      ? this.lessons.findIndex(l => l.isInvariant)
      : this.modules.findIndex(m => m.isInvariant)
  }

  get hasInvariant() {
    return this._invariantIndex !== -1
  }

  get lastInvariantStageId(): number | null {
    return this.hasInvariant
      ? this.isCompact
        ? getLast(this.lessons[this._invariantIndex]?.stages)?.id ?? null
        : getLast(getLast(getLast(this.modules[this._invariantIndex]?.topics)?.lessons)?.stages)
            ?.id ?? null
      : null
  }

  // forum
  get hasForum() {
    return !!this.forumId
  }

  get showForum() {
    return this.hasForum && this.forumEnabled
  }

  get forumRoute(): RouteLocationRaw | null {
    return this.forumId ? { name: 'ForumHome', params: { id: this.forumId } } : null
  }

  goToForum() {
    if (this.forumRoute) router.push(this.forumRoute)
  }
}
