import type { IOptionWithValidation } from '@/components/_ui/types'
import { fromJsonString } from '@shared/helpers/from-json'
import type { TaskType } from '@abstract-trainer/config'
import type {
  TrainerTask,
  TrainerTaskDataLocalData,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import type { UnwrapRef } from 'vue'
import type { Component } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import { isEmptyObject } from '@core/helpers/isEmptyObject'
const validate = (data: TrainerTaskRemoteData) => {
  if (!data.id) throw new Error('Invalid trainer task data.')
}

export default abstract class AbstractTrainerTask {
  readonly id: number
  readonly description: string
  readonly question: string
  readonly tutorMaxGrade: number
  readonly isAutochecking: boolean
  readonly isDataEmpty: boolean = false

  abstract readonly data: TrainerTaskDataLocalData | null
  abstract readonly type: TaskType | null
  abstract readonly component: Component | null

  protected constructor(data: TrainerTaskRemoteData) {
    validate(data)

    this.id = data.id
    this.description = data.question || ''
    this.question = data.question ?? ''
    this.tutorMaxGrade = data.max_grade ?? 0
    this.isAutochecking = data.is_auto ?? false

    if (isTaskDataEmpty(data.data)) this.isDataEmpty = true
  }

  abstract getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null): IOptionWithValidation[]

  abstract getLocalAnswer<T extends TrainerTask>(
    answer: TrainerTaskAnswer | null
  ): UnwrapRef<TrainerTaskLocalAnswer<T>>

  // this method can be overwritten if needed
  hasLocalAnswer<T extends TrainerTask>(
    localAnswer: UnwrapRef<TrainerTaskLocalAnswer<T>> | null
  ): boolean {
    return !!localAnswer
  }

  // this method can be overwritten if needed
  isAnswerModified<T extends TrainerTask>(
    localAnswer: UnwrapRef<TrainerTaskLocalAnswer<T>> | null,
    answer: TrainerTaskAnswer | null
  ): boolean {
    return JSON.stringify(localAnswer) !== JSON.stringify(fromJsonString(answer?.answer))
  }

  // this method can be overwritten if needed
  hasRemoteAnswer(answer: TrainerTaskAnswer | null): boolean {
    return !!answer?.answer
  }
}

export function isTaskDataEmpty(data?: unknown) {
  return !data || isEmptyObject(data as object)
}
