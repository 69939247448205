<template lang="pug">
.appFooterComponent
  component(:is="isFooterStandart ? FooterStandart : FooterSmall")
</template>

<script lang="ts" setup>
import FooterStandart from '@/components/_layout/Footer/FooterStandart/FooterStandart.vue'
import FooterSmall from '@/components/_layout/Footer/FooterSmall/FooterSmall.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const isFooterStandart = computed(() => {
  const routes = [
    'Home',
    'FAQ',
    'NewsList',
    'NewsItem',
    'CoursesList',
    'CoursesItemLanding',
    'DiagnosticsList',
    'DiagnosticsItemLanding',
    'Login',
    'Registration',
    'CompleteRegistration',
    'PasswordRecovery',
    'NewPassword',
    'NotFound'
  ]
  return routes.some(o => o === route.name)
})
</script>
