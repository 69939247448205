<template lang="pug">
.footerStandartQuestionsComponent
  .title Остались вопросы?
  ui-button(
    color="secondary"
    @click="showFeedbackModal"
  ) Заполнить форму обратной связи
</template>

<script lang="ts" setup>
import store from '@/store'
import FeedbackModal from '@feedback/components/Modals/FeedbackModal.vue'

const showFeedbackModal = () => {
  store.ui.modals.push({
    key: 'feedback-modal',
    component: FeedbackModal
  })
}
</script>

<style lang="sass" scoped>
.footerStandartQuestionsComponent
  > .title
    color: $colorWhite
    @include font('h2')
    margin-bottom: 6*$u

  @media (max-width: $screenSizeL)
    > .title
      font-size: 6*$u
</style>
