import {
  MAP_PROFILE_KEYS_REMOTE_LOCAL,
  MAP_CHANGE_PASSWORD_KEYS_REMOTE_LOCAL
} from '@user-profile/config'
import useObjectKeyConverter from '@core/hooks/useObjectKeyConverter'
import type { UserProfileRemoteKeys } from '@user-profile/services/types'

const {
  convertForward: convertUserProfileDataRemoteToLocal,
  convertReverse: convertUserProfileDataLocalToRemote,
  convertKeyForward: convertUserProfileKeyRemoteToLocal,
  convertKeyReverse: convertUserProfileKeyLocalToRemote,
  isKeyForward: isProfileFieldRemoteKey,
  isKeyReverse: isProfileFieldLocalKey
} = useObjectKeyConverter(MAP_PROFILE_KEYS_REMOTE_LOCAL)

const getProfileFieldRemoteKey = (localKey: string): UserProfileRemoteKeys => {
  return isProfileFieldLocalKey(localKey)
    ? convertUserProfileKeyLocalToRemote(localKey)
    : (localKey as UserProfileRemoteKeys)
}

const {
  convertForward: convertChangePasswordParamsRemoteToLocal,
  convertReverse: convertChangePasswordParamsLocalToRemote
} = useObjectKeyConverter(MAP_CHANGE_PASSWORD_KEYS_REMOTE_LOCAL)

export {
  convertUserProfileDataRemoteToLocal,
  convertUserProfileDataLocalToRemote,
  convertUserProfileKeyRemoteToLocal,
  convertUserProfileKeyLocalToRemote,
  isProfileFieldRemoteKey,
  isProfileFieldLocalKey,
  getProfileFieldRemoteKey,
  convertChangePasswordParamsRemoteToLocal,
  convertChangePasswordParamsLocalToRemote
}
