<template lang="pug">
.noticesListItemComponent(
  :class="itemClasses"
  @click="close"
)
  .title {{ item.title }}
  .content(
    v-if="item.content"
    v-html="item.content"
  )
</template>

<script lang="ts" setup>
import store from '@/store'
import { computed, onMounted } from 'vue'
import type Notice from '@ui-notices/models/Notice'

interface Props {
  item: Notice
}
const props = defineProps<Props>()

onMounted(() => {
  if (props.item.duration >= 0) {
    setTimeout(close, props.item.duration)
  }
})

const itemClasses = computed(() => `_${props.item.type}`)

const close = () => {
  store.ui.notices.remove(props.item.id)
}
</script>

<style lang="sass" scoped>
.noticesListItemComponent
  display: grid
  gap: $u
  border-radius: $brM
  padding: 4*$u 7*$u
  cursor: pointer
  z-index: $zIndexNotices
  pointer-events: auto
  box-shadow: 0 0 15px rgb(0 0 0 / 30%)
  @media (max-width: $screenSizeXS)
    width: 100%
    padding: 2*$u 4*$u
    border-radius: $brS

  .title
    @include font('t16-demibold')
    color: $colorWhite

  .content
    color: $colorWhite

  &._success
    background: $colorSuccess

  &._info
    background: $colorSecondary

  &._warning
    background: $colorSecondary2

  &._error
    background: $colorDanger
</style>
