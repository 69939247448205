import type { RouteRecordRaw } from 'vue-router'

const newsRoutes: RouteRecordRaw[] = [
  {
    path: '/news',
    name: 'News',
    component: () => import('./NewsHome.vue'),
    redirect: { name: 'NewsList' },
    children: [
      {
        path: '',
        name: 'NewsList',
        component: () => import('./NewsList.vue')
      },
      {
        path: ':id',
        name: 'NewsItem',
        props: route => ({ id: Number(route.params.id) }),
        component: () => import('./NewsItem.vue')
      }
    ]
  }
]

export default newsRoutes
