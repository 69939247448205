<template lang="pug">
.sortTrainerTaskItemComponent(:class="componentClasses")
  .letter {{ letter }}
  .optionWrapper
    .text {{ item.label }}
    img.image(
      v-if="item.image"
      :src="item.image"
    )
</template>

<script lang="ts" setup>
import { ValidationStatus } from '@/components/_ui/types'
import type { IOptionWithValidation } from '@/components/_ui/types'
import { computed } from 'vue'
import { ALPHABET } from '@abstract-trainer/config'
interface Props {
  item: IOptionWithValidation<number>
  isDisabled?: boolean
}

const props = defineProps<Props>()

const letter = computed(() => ALPHABET[(props.item?.value ?? 1) - 1])

const componentClasses = computed(() => ({
  _valid: props.item?.validationStatus === ValidationStatus.Valid,
  _invalid: props.item?.validationStatus === ValidationStatus.Invalid,
  _disabled: props.isDisabled
}))
</script>

<style lang="sass" scoped>
.sortTrainerTaskItemComponent
  display: flex
  align-items: center
  cursor: move
  padding: 1*$u 2*$u
  background: $colorWhite
  border-radius: $brXS
  user-select: none

  > .letter
    border: .25*$u solid $colorGray
    border-radius: 50%
    width: 7*$u
    height: 7*$u
    flex: 0 0 7*$u
    margin-right: 3*$u
    display: flex
    align-items: center
    justify-content: center

  > .optionWrapper
    max-width: 100%

    > .image
      max-width: 100%
      margin-top: 2*$u
      max-height: 100*$u

  &:hover:not(._disabled)
    background-color: $colorBackground2
    color: $colorPrimary
    > .letter
      border-color: $colorPrimary

  &._valid
    background-color: $colorCalendar3
    color: $colorSuccess
    > .letter
      border-color: $colorSuccess

  &._invalid
    background-color: $colorCalendar1
    color: $colorDanger
    > .letter
      border-color: $colorDanger

  &._disabled
    cursor: not-allowed
</style>
