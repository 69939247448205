import type { RouteRecordRaw } from 'vue-router'
import isAuthenticated from '@router/middleware/isAuthenticated'

const announcingRoutes: RouteRecordRaw[] = [
  {
    path: 'announcing',
    name: 'CoursesItemAnnouncing',
    component: () => import('./AnnouncingHome.vue'),
    redirect: { name: 'CoursesItemAnnouncingList' },
    meta: {
      middleware: [isAuthenticated]
    },
    children: [
      {
        path: '',
        name: 'CoursesItemAnnouncingList',
        component: () => import('./AnnouncingList.vue')
      },
      {
        path: ':announcingId',
        name: 'CoursesItemAnnouncingItem',
        component: () => import('./AnnouncingItem.vue'),
        props: route => ({
          announcingId: Number(route.params.announcingId)
        })
      }
    ]
  }
]
export default announcingRoutes
