<template lang="pug">
.textInputTrainerTaskComponent
  ui-textarea(
    v-model="localAnswer",
    :validation-status="highlightValidation ? taskValidationStatus : null",
    :is-disabled="isDisabled",
    :placeholder-text="task.data.data.placeholderText"
    bigheight
  )
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type {
  TrainerTaskTextInputFullControllerType,
  TrainerTaskTextInputShortControllerType
} from '@abstract-trainer/controllers/TrainerTaskController'

interface Props {
  task:
    | ReturnType<TrainerTaskTextInputFullControllerType>
    | ReturnType<TrainerTaskTextInputShortControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()

const localAnswer = ref<string | null>(props.task.answer.localValue.value)
watch(props.task.answer.localValue, val => val && (localAnswer.value = val), { deep: true })
watch(localAnswer, props.task.answer.setLocalValue)

const taskValidationStatus = computed(() => props.task.taskValidationStatus.value)
</script>
