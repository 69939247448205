<template lang="pug">
.matchTrainerTaskGroupOptionComponent
  .number.ui-f-p {{ optionNumber }}
  .content
    .label.ui-f-p {{ option.label }}
    img.image(:src="option.image")
</template>

<script lang="ts" setup>
import type { TaskDataSingleObectLocalData } from '@abstract-trainer/services/types'
import { computed } from 'vue'
import { ALPHABET } from '@abstract-trainer/config'
interface Props {
  index: number
  option: TaskDataSingleObectLocalData
  type: 'alpha' | 'number'
}

const props = defineProps<Props>()

const optionNumber = computed(() =>
  props.type === 'number' ? props.index + 1 : ALPHABET[props.index]
)
</script>

<style lang="sass" scoped>
.matchTrainerTaskGroupOptionComponent
  display: flex
  align-items: flex-start
  > .number
    border: .25*$u solid $colorGray
    border-radius: 50%
    width: 7*$u
    height: 7*$u
    flex: 0 0 7*$u
    margin-right: 3*$u
    display: flex
    align-items: center
    justify-content: center
    color: $colorPrimary
    text-transform: uppercase
  > .content
    > .image
      max-width: 100%
      border-radius: 1%
</style>
