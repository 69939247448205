import store from '@/store'
import { callApi } from '@core/api/apiWrapper'
import type { ProfileDocumentType } from '@user-profile/config'
import { convertChangePasswordParamsLocalToRemote } from '@user-profile/helpers/converters'
import type { Role } from '@user-profile/config'
import type {
  ProfileUpdateRemoteError,
  UserProfileRemoteData,
  UserProfileUpdateRemoteData,
  ChangePasswordRemoteData,
  ProfileFieldConfigRemoteData
} from './types'
// import { userProfileMock } from '@user-profile/services/mocks/userProfileMock'
// import type { CallApiReturn } from '@core/api/types'
// import { userProfileAdditionalFieldsMock } from '@user-profile/services/mocks/userProfileAdditionalFieldsMock'

export const getProfile = async (id: number | null = null) => {
  const response = await callApi<UserProfileRemoteData>({
    method: 'get',
    url: id ? `profile/${id}/` : 'profile/'
  })
  if (response.data instanceof Array) response.data = response.data[0]
  // const response = new Promise<CallApiReturn<UserProfileRemoteData, string[]>>(resolve => {
  //   setTimeout(() => {
  //     resolve({
  //       data: userProfileMock,
  //       errors: null,
  //       status: 200
  //     })
  //   }, 400)
  // })

  // Dadata mocks
  // if (response.data) {
  //   response.data.region_embded = { id: 1, name: 'г. Москва', from_dadata: false }
  //   response.data.municipalitet_embded = { id: 7, name: 'Южный', from_dadata: false }
  //   response.data.organization_embded = {
  //     id: 4,
  //     short_name: 'ГБОУ ДОД "ДМШ им. А.Г. Новикова"',
  //     from_dadata: false
  //   }
  // }

  return response
}

export const updateProfile = async (options: { userId: number; data: FormData }) => {
  store.ui.notices.clean()

  const response = await callApi<UserProfileUpdateRemoteData, Partial<ProfileUpdateRemoteError>>({
    method: 'patch',
    data: options.data,
    formData: true,
    url: `profile/${options.userId}/`
  })

  return response
}

export const uploadDocument = async (options: {
  file: File
  type: ProfileDocumentType | null
  documentId: number | null
}) => {
  const formData = new FormData()
  formData.append('file', options.file, options.file.name)
  formData.append('type', options.type?.toString() ?? '')
  const response = await callApi<UserProfileUpdateRemoteData, Partial<ProfileUpdateRemoteError>>({
    method: options.documentId ? 'patch' : 'post',
    data: formData,
    formData: true,
    url: options.documentId ? `profile/upload/${options.documentId}/` : 'profile/upload/'
  })
  return response
}

export const fetchAvailableRoles = async () => {
  const response = await callApi<Role[]>({
    method: 'get',
    url: 'profile/list_roles/'
  })
  return response
}

export const changeRole = async (data: Role) => {
  const response = await callApi({
    method: 'post',
    url: 'profile/set_role/',
    data: { role: data }
  })
  return response
}

export interface ChangePasswordFormData {
  oldPassword: string
  newPassword: string
  confirm: string
}

export const changePasswordProfile = async (id: number, formData: ChangePasswordFormData) => {
  const remoteData = convertChangePasswordParamsLocalToRemote(formData)
  const response = await callApi<ChangePasswordRemoteData>({
    method: 'patch',
    url: `change-password/${id}/`,
    data: remoteData
  })
  // const response = new Promise<CallApiReturn<ChangePasswordRemoteData, ChangePasswordRemoteData>>(
  //   resolve => {
  //     setTimeout(() => {
  //       resolve({ data: { detail: 'Пароль успешно изменен' }, errors: null, status: 200 })
  //       // resolve({ data: null, errors: { old_password: 'Неверный предыдущий пароль' }, status: 400 })
  //       // resolve({
  //       //   data: null,
  //       //   errors: { new_password: '<Причина неправильного пароля>' },
  //       //   status: 400
  //       // })
  //       // resolve({
  //       //   data: null,
  //       //   errors: { confirm_password: 'Подтверждение пароля не совпадает' },
  //       //   status: 400
  //       // })
  //       // resolve({
  //       //   data: null,
  //       //   errors: { detail: 'Доступ запрещен для неавторизованных пользователей' },
  //       //   status: 400
  //       // })
  //       // resolve({
  //       //   data: null,
  //       //   errors: {
  //       //     new_password: '<Причина неправильного пароля>',
  //       //     confirm_password: 'Подтверждение пароля не совпадает',
  //       //     detail: 'Доступ запрещен для неавторизованных пользователей'
  //       //   },
  //       //   status: 400
  //       // })
  //     }, 400)
  //   }
  // )
  return response
}

export const fetchAdditionalFields = async () => {
  const response = await callApi<ProfileFieldConfigRemoteData[]>({
    method: 'get',
    url: 'profile/additional-fields/'
  })
  // const response = new Promise<CallApiReturn<ProfileFieldConfigRemoteData[], string[]>>(resolve => {
  //   setTimeout(() => {
  //     resolve({
  //       data: userProfileAdditionalFieldsMock,
  //       errors: null,
  //       status: 200
  //     })
  //   }, 400)
  // })
  return response
}
