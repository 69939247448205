<template lang="pug">
.avatarComponent(:class="componentClasses")
  .inner
    .image(
      v-if="url"
      :style="imageStyles"
    )
    .placeholder(v-else)
      ui-svg-icon(
        name="user"
      )
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  url?: string | null
  size?: number
  variant?: 'default' | 'white' | 'secondary'
}

const props = withDefaults(defineProps<Props>(), {
  url: '',
  size: 0,
  variant: 'default'
})

const componentClasses = computed(() => [`_${props.variant}`, { _responsive: !props.size }])

const imageStyles = computed(() => ({
  backgroundImage: `url('${props.url}')`
}))
</script>

<style lang="sass" scoped>
.avatarComponent
  width: calc(v-bind(size) * #{$u})
  height: calc(v-bind(size) * #{$u})
  display: grid
  border-radius: 50%
  border: .5*$u solid $colorPrimary
  flex-shrink: 0

  &._default
    border-color: $colorPrimary
    > .inner
      > .placeholder
        color: $colorPrimary

  &._secondary
    border-color: $colorSecondary
    > .inner
      > .placeholder
        color: $colorSecondary
  &._white
    border-color: $colorWhite
    > .inner
      background: transparent
      > .placeholder
        color: $colorWhite

  > .inner
    width: 100%
    height: 100%
    margin: auto
    border-radius: 50%
    overflow: hidden
    display: grid
    background: $colorWhite

    > .image
      background: no-repeat center
      background-size: cover

    > .placeholder
      width: 100%
      height: 100%
      margin: auto auto 0
      transform-origin: top
      transform: scale(110%) translateY(10%)

  &._responsive
    width: 100% !important
    height: 100% !important
</style>
