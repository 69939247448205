import type { TrainerTask } from '@abstract-trainer/services/types'
import type { TrainerScoreType } from '@abstract-trainer/config'

export default abstract class AbstractTrainer {
  public abstract id: number
  public abstract tasks: TrainerTask[]
  public abstract maxAttempts: number
  public abstract duration: number
  public abstract scoreType: TrainerScoreType
  public skipTimerNotice: boolean

  protected constructor() {
    this.skipTimerNotice = false
  }
}
