<template lang="pug">
swiper(v-bind="swiperConfig")
  slot
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { SliderConfig, SliderOptions } from '@/components/_ui/UiSlider/types'
import { Navigation, FreeMode } from 'swiper'
import type { SwiperModule } from 'swiper/types/shared'
import { Swiper } from 'swiper/vue'
import type { NavigationOptions } from 'swiper/types'

export interface Props {
  name: string
  config: SliderConfig
  options?: SliderOptions
}

const initialOptions: Required<SliderOptions> = {
  navigationOn: true,
  freeModeOn: false,
  buttonsDisabledStyle: 'outline'
}

const props = defineProps<Props>()

const options: Required<SliderOptions> = {
  ...initialOptions,
  ...props.options
}

const modules: SwiperModule[] = []
if (options.navigationOn) modules.push(Navigation)
if (options.freeModeOn) modules.push(FreeMode)

const buttonsDisabledStyle =
  options.buttonsDisabledStyle === 'fill' ? '_disabled_fill' : '_disabled_outline'

const navigationConfig: NavigationOptions = {
  disabledClass: buttonsDisabledStyle,
  prevEl: `.slider_${props.name}_prev`,
  nextEl: `.slider_${props.name}_next`
}

const freeModeConfig = true

const swiperConfig = computed<SliderConfig>(() => ({
  ...props.config,
  modules,
  navigation: options.navigationOn ? navigationConfig : false,
  freeMode: options.freeModeOn ? freeModeConfig : false
}))
</script>
