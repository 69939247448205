<template lang="pug">
button.buttonComponent(
  :class="buttonClasses"
  :disabled="isDisabled || isLoading"
)
  .loader
    ui-svg-icon(name="loader")
  .icon(v-if="icon")
    ui-svg-icon(:name="icon")
  .text(v-if="$slots.default")
    slot
</template>

<script lang="ts" setup>
import { computed } from 'vue'
interface Props {
  isDisabled?: boolean
  isLoading?: boolean
  isResponsive?: boolean
  icon?: string | null
  color?:
    | 'primary'
    | 'secondary'
    | 'secondary2'
    | 'success'
    | 'danger'
    | 'background'
    | 'background2'
    | 'white'
    | 'course1'
    | 'course2'
    | 'course3'
    | 'course4'
    | 'course5'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' // 24 | 36 | 44 | 52 | 68
  outlined?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
  isLoading: false,
  isResponsive: false,
  icon: null,
  color: 'primary',
  size: 'm',
  outlined: false
})

const buttonClasses = computed(() => {
  return [
    `_color_${props.color}`,
    `_size_${props.size}`,
    {
      _loading: props.isLoading,
      _disabled: props.isDisabled,
      _responsive: props.isResponsive,
      _withIcon: props.icon,
      _outlined: props.outlined
    }
  ]
})
</script>

<style lang="sass" scoped>
$colors: (primary: $colorPrimary, secondary: $colorSecondary, secondary2: $colorSecondary2, success: $colorSuccess, danger: $colorDanger, background: $colorBackground, background2: $colorBackground2, "white": $colorWhite, course1: $colorCourse1, course2: $colorCourse2, course3: $colorCourse3, course4: $colorCourse4, course5: $colorCourse5)

$buttonIconSize: 6*$u

.buttonComponent
  width: fit-content
  height: 13*$u
  display: flex
  align-items: center
  padding: 0 5*$u
  border: 2px solid transparent
  border-radius: $brL
  transition: $appAnimationSpeed linear
  color: $colorWhite
  user-select: none
  background: $colorPrimary
  position: relative

  > .text
    @include font('t16')
    white-space: nowrap
    flex: 1

  > .loader
    position: absolute
    left: 50%
    width: 7*$u
    height: 7*$u
    transform: translateX(-50%) !important
    display: none

  > .icon
    width: $buttonIconSize
    height: $buttonIconSize
    flex: 0 0 $buttonIconSize

  &._responsive
    width: 100%

  &._withIcon
    > .text
      margin-left: 3*$u

  &._outlined
    background: $colorWhite
    color: $colorPrimary
    &:hover
      color: shade($colorPrimary, -30)
    &:active
      color: $colorPrimary

    @each $name, $color in $colors
      &._color_#{$name}
        border-color: $color
        &:hover
          border-color: shade($color, -30)
        &:active
          border-color: $color
      &._color_white
        background: $colorPrimary
        color: $colorWhite

  &:not(._outlined)
    @each $name, $color in $colors
      &._color_#{$name}
        background: $color
        &:hover
          background: shade($color, -30)
        &:active
          background: $color
    &._color_background,
    &._color_background2,
    &._color_white
      color: $colorPrimary
      &:hover
        color: shade($colorPrimary, -30)
      &:active
        color: $colorPrimary

  &._size_xl
    height: 17*$u
    padding: 0 16*$u
    > .text
      @include font('t18-demibold')
    > .loader
      width: 10*$u
      height: 10*$u
  &._size_l
    height: 13*$u
    padding: 0 8*$u
    > .text
      @include font('t16')
    > .loader
      width: 8*$u
      height: 8*$u
  &._size_m
    height: 11*$u
    > .text
      @include font('t14')
    > .loader
      width: 7*$u
      height: 7*$u
  &._size_s
    height: 9*$u
    padding: 0 4*$u
    > .text
      @include font('t14')
    > .loader
      width: 6*$u
      height: 6*$u
  &._size_xs
    height: 6*$u
    padding: 0 2*$u
    > .text
      @include font('t12-demibold')
    > .loader
      width: 4*$u
      height: 4*$u
  &._loading
    > .text,
    > .icon
      visibility: hidden
    > .loader
      display: block

  &._disabled
    background-color: $colorGray !important
    border-color: $colorGray !important
    color: $colorWhite !important
    pointer-events: none
</style>
