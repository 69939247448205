<template lang="pug">
.svgIconComponent(
  v-if="iconsFolderPath && name",
  :class="iconClasses"
)
  .svg(
    v-html="html",
    :class="svgClasses"
  )
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import { PUBLIC_PATH } from '@/config'
import iconCache from './iconCache'

interface Props {
  iconsFolderPath?: string
  name: string
  size?: number
  originalColors?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  iconsFolderPath: 'assets/images/icons/',
  name: '',
  size: 0,
  originalColors: false
})

const imageUrl = computed(() => `${PUBLIC_PATH}${props.iconsFolderPath}${props.name}.svg`)

const iconClasses = computed(() => {
  return {
    _responsive: !props.size
  }
})

const svgClasses = computed(() => ({ _inheritColors: !props.originalColors }))

const html = computed(() => iconCache[props.name])

watch(
  () => props.name,
  () => {
    const name = props.name
    if (name && iconCache[name] === undefined) {
      iconCache[name] = null
      fetch(imageUrl.value)
        .then(response => response.text())
        .then(svg => {
          iconCache[name] = svg
        })
    }
  },
  { immediate: true }
)
</script>

<style lang="sass" scoped>
.svgIconComponent
  display: flex
  align-items: center
  justify-content: center
  width: calc(v-bind(size) * #{$u})
  height: calc(v-bind(size) * #{$u})
  flex: 0 0 calc(v-bind(size) * #{$u})

  .svg
    line-height: 0
    width: 100%
    height: 100%
    &:deep(svg)
      width: 100%
      height: 100%
      margin: 0 auto
    &._inheritColors
      &:deep(svg)
        fill: currentColor
        path,
        rect
          fill: inherit

  &._responsive
    width: 100%
    height: 100%
</style>
