import useApiRequest from '@core/hooks/useApiRequest'
import useEventEmitter from '@core/hooks/useEventEmitter'
import { computed, ref } from 'vue'
import { fetchCounter, fetchNotifications } from '@notifications/services/notifications'
import type AbstractNotification from '@notifications/models/AbstractNotification'
import { notificationsFactory } from '@notifications/models/notificationsFactory'

interface Events {
  'counters-success': void
  'counters-error': string[]
  'notifications-success': void
  'notifications-error': string[]
}

const NotificationsController = () => {
  const { $on, $off, $emit } = useEventEmitter<Events>()

  // counter
  const counter = ref(0)
  const countersRequest = useApiRequest(fetchCounter)
  countersRequest.$on('success', responseData => {
    counter.value = responseData.notifications
    $emit('counters-success')
  })
  countersRequest.$on('error', errors => {
    $emit('counters-error', errors)
  })
  const requestCounter = () => {
    countersRequest.request()
  }

  // notifications
  const notifications = ref<AbstractNotification[]>([])
  const notificationsRequest = useApiRequest(fetchNotifications)
  notificationsRequest.$on('success', responseData => {
    notifications.value = responseData.results.map(notificationsFactory)
    $emit('notifications-success')
  })
  notificationsRequest.$on('error', errors => {
    $emit('notifications-error', errors)
  })
  const requestNotifications = () => {
    notificationsRequest.request()
  }

  return {
    counter,
    requestCounter,
    isLoadingCounters: computed(() => countersRequest.isLoading),
    notifications,
    requestNotifications,
    isLoadingNotifications: computed(() => notificationsRequest.isLoading),
    $on,
    $off
  }
}

export default NotificationsController
