import type { RouteRecordRaw } from 'vue-router'

const knowledgesRoutes: RouteRecordRaw[] = [
  {
    path: '/knowledges',
    name: 'Knowledges',
    component: () => import('./KnowledgesHome.vue'),
    redirect: { name: 'KnowledgesList' },
    children: [
      {
        path: '',
        name: 'KnowledgesList',
        component: () => import('./KnowledgesList.vue')
      },
      {
        path: ':id',
        name: 'KnowledgesItem',
        props: route => ({ id: Number(route.params.id) }),
        component: () => import('./KnowledgesItem.vue')
      }
    ]
  }
]

export default knowledgesRoutes
