<template lang="pug">
.chartRadarComponent(v-if="chartConfig")
  canvas.chart(ref="chartRef")
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import Chart from 'chart.js/auto'
import type { ChartConfiguration } from 'chart.js'

interface Props {
  chartConfig: ChartConfiguration
}

const props = defineProps<Props>()

const chartRef = ref<HTMLCanvasElement | null>(null)

onMounted(() => {
  if (!chartRef.value) return
  new Chart(chartRef.value, props.chartConfig)
})
</script>

<style lang="sass" scoped>
.chartRadarComponent
  > .chart
    width: 100%
    height: 100%
    margin: -15% 0
    transform-origin: center
</style>
