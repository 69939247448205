import type { CourseStateStagesAccessRemoteData } from '@courses/services/types'

export interface CourseVisibleStructure {
  modules: number[]
  topics: number[]
  lessons: number[]
  stages: number[]
}

const generateStructureVisibleMap = (
  data: CourseStateStagesAccessRemoteData[],
  isCompact: boolean
): CourseVisibleStructure => {
  const structure: CourseVisibleStructure = {
    modules: [],
    topics: [],
    lessons: [],
    stages: []
  }

  for (const group of data) {
    if (isCompact && group.stages) {
      structure.lessons.push(group.id)
      group.stages.forEach(stage => {
        structure.stages.push(stage.id)
      })
    } else if (group.topics) {
      structure.modules.push(group.id)
      group.topics.forEach(topic => {
        structure.topics.push(topic.id)
        topic.lessons.forEach(lesson => {
          structure.lessons.push(lesson.id)
          lesson.stages.forEach(stage => {
            structure.stages.push(stage.id)
          })
        })
      })
    }
  }

  return structure
}

export default generateStructureVisibleMap
