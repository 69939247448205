<template lang="pug">
.rangeSelectComponent
  .line(ref="line")
    .range(:style="rangeStyles")
      .handle._left(
        ref="handleMin"
        @mousedown="onMousedown('min')"
        @touchstart="onMousedown('min', $event)"
        :class="handleMinClasses"
      )
        .value {{ minDisplayed }}
      .handle._right(
        ref="handleMax"
        @mousedown="onMousedown('max')"
        @touchstart="onMousedown('max', $event)"
        :class="handleMaxClasses"
      )
        .value {{ maxDisplayed }}
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch, onBeforeUnmount } from 'vue'

interface Props {
  min: number
  max: number
  minValue?: number
  maxValue?: number
  step?: number
}
const props = withDefaults(defineProps<Props>(), {
  min: 0,
  max: 50,
  minValue: 0,
  maxValue: 250,
  step: 1
})

const emit = defineEmits<{
  (e: 'update:min', value: number): void
  (e: 'update:max', value: number): void
}>()

const line = ref<HTMLDivElement>()
const handleMin = ref<HTMLDivElement>()
const handleMax = ref<HTMLDivElement>()

const lineWidth = ref(0)
const isMoving = reactive({
  min: false,
  max: false
})
const mouseOldX = ref(0)
const movedDistance = ref(0)
const handleOffset = reactive({
  min: 0,
  max: 0
})
const totalSteps = computed(() => (props.maxValue - props.minValue) / props.step)
const stepWidth = computed(() => lineWidth.value / totalSteps.value)
const movedSteps = computed(() => Math.round(movedDistance.value / stepWidth.value))
const maxDisplayed = computed(() => {
  let value = props.max
  if (isMoving.max) value += movedSteps.value
  if (value > props.maxValue) value = props.maxValue
  if (value < props.min) value = props.min
  return value
})
const minDisplayed = computed(() => {
  let value = props.min
  if (isMoving.min) value += movedSteps.value
  if (value > props.max) value = props.max
  if (value < props.minValue) value = props.minValue
  return value
})
const rangeStyles = computed(() => ({
  left: `${handleOffset.min}px`,
  right: `${handleOffset.max}px`
}))

// Active handle logic.
const activeHanlde = ref('min')

const handleMinClasses = computed(() => {
  return {
    _active: activeHanlde.value === 'min'
  }
})

const handleMaxClasses = computed(() => {
  return {
    _active: activeHanlde.value === 'max'
  }
})

watch(
  () => props.min,
  () => setLeftOffset()
)
watch(
  () => props.max,
  () => setRightOffset()
)

const getAvailableLineWidth = () => {
  const handleWidth = handleMin.value?.getBoundingClientRect().width ?? 0
  return line.value ? line.value.offsetWidth - handleWidth : lineWidth.value - handleWidth
}

const init = () => {
  lineWidth.value = getAvailableLineWidth()
  setLeftOffset()
  setRightOffset()
}

onMounted(() => {
  init()
  window.addEventListener('resize', init)
  document.addEventListener('mouseup', onMouseup)
  document.addEventListener('touchend', onMouseup)
  document.addEventListener('mousemove', onMousemove)
  document.addEventListener('touchmove', onMousemove)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', init)
  document.removeEventListener('mouseup', onMouseup)
  document.removeEventListener('touchend', onMouseup)
  document.removeEventListener('mousemove', onMousemove)
  document.removeEventListener('touchmove', onMousemove)
})
const setLeftOffset = () => {
  let offset = props.min * stepWidth.value
  if (isMoving.min) offset += movedDistance.value
  if (offset < 0) offset = 0
  if (offset > lineWidth.value - handleOffset.max) offset = lineWidth.value - handleOffset.max
  handleOffset.min = offset
}
const setRightOffset = () => {
  let offset = lineWidth.value - props.max * stepWidth.value
  if (isMoving.max) offset -= movedDistance.value
  if (offset < 0) offset = 0
  if (offset > lineWidth.value - handleOffset.min) offset = lineWidth.value - handleOffset.min
  handleOffset.max = offset
}
const onMousedown = (handle: 'min' | 'max', e?: TouchEvent) => {
  isMoving[handle] = true
  movedDistance.value = 0
  activeHanlde.value = handle
  if (e) mouseOldX.value = e.touches[0].pageX
}
const onMouseup = () => {
  if (isMoving.min) {
    emit('update:min', minDisplayed.value)
    isMoving.min = false
    setLeftOffset()
  }
  if (isMoving.max) {
    emit('update:max', maxDisplayed.value)
    isMoving.max = false
    setRightOffset()
  }
  movedDistance.value = 0
}
const onMousemove = (e: MouseEvent | TouchEvent) => {
  if (e instanceof MouseEvent) {
    movedDistance.value += e.pageX - mouseOldX.value
    mouseOldX.value = e.pageX
  } else {
    movedDistance.value += e.touches[0].pageX - mouseOldX.value
    mouseOldX.value = e.touches[0].pageX
  }
  if (isMoving.min) setLeftOffset()
  else if (isMoving.max) setRightOffset()
}
</script>

<style lang="sass" scoped>
.rangeSelectComponent
  user-select: none
  margin: 0 5*$u
  height: 6*$u
  position: relative
  top: 3*$u
  display: flex

  .line
    width: 100%
    height: 3px
    background-color: $colorGray2
    position: relative
    &:after
      display: block
      position: absolute
      content: ''
      width: calc(100% + 10*#{$u})
      height: 100%
      top: 0
      left: -5*$u
      background: $colorGray2
      z-index: -1

  .range
    position: absolute
    height: 3px
    background-color: $colorPrimary

  .handle
    width: 10*$u
    height: 6*$u
    background-color: $colorPrimary
    border-radius: $brM
    display: flex
    position: absolute
    top: -3*$u
    color: $colorPrimary
    cursor: grab
    z-index: 1
    &._left
      left: -5*$u
    &._right
      right: -5*$u

    &._active
      z-index: 2

  .value
    margin: auto
    color: $colorWhite
    @include font('t14')
</style>
