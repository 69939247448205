import type { RouteRecordRaw } from 'vue-router'

const newsRoutes: RouteRecordRaw[] = [
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('./FAQ.vue')
  }
]

export default newsRoutes
