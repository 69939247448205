<template lang="pug">
.checkboxComponent(
  :class="checkboxClasses",
  @click="onInput"
)
  .box
    .checkmark(v-if="modelValue")
      ui-svg-icon( :name="type === 'check' ? 'checkmark' : 'circle'" )
  .label(v-if="$slots.default")
    slot
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { ValidationStatus } from '@/components/_ui/types'

interface Props {
  type?: 'check' | 'radio'
  modelValue?: boolean
  isDisabled?: boolean
  validationStatus?: ValidationStatus | string
  isDecorative?: boolean
  font?: 's' | 'm'
}
const props = withDefaults(defineProps<Props>(), {
  type: 'check',
  modelValue: false,
  isDisabled: false,
  validationStatus: ValidationStatus.None,
  isDecorative: false,
  font: 'm'
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const checkboxClasses = computed(() => [
  {
    _checked: props.modelValue,
    _valid: props.validationStatus === ValidationStatus.Valid,
    _invalid: props.validationStatus === ValidationStatus.Invalid,
    _disabled: props.isDisabled,
    _radio: props.type === 'radio',
    _decorative: props.isDecorative
  },
  `_font_${props.font}`
])

const onInput = () => {
  if (props.isDisabled || props.isDecorative) return
  emit('update:modelValue', !props.modelValue)
}
</script>

<style lang="sass" scoped>
.checkboxComponent
  display: flex
  align-items: center
  cursor: pointer
  user-select: none
  &:hover:not(._disabled, ._decorative)
    .box
      border-color: $colorPrimary

  .box
    display: flex
    align-items: center
    justify-content: center
    width: 5*$u
    height: 5*$u
    flex: 0 0 5*$u
    border: 0.5*$u solid $colorGray
    border-radius: $brXS
    background: $colorWhite
    padding: 0.5*$u
    transition: $appAnimationSpeed
    .checkmark
      color: $colorPrimary
      pointer-events: none
      transition: $appAnimationSpeed

  .label
    margin-left: 2*$u

  &._checked
    .box
      border-color: $colorPrimary
      background: $colorPrimary
      .checkmark
        color: $colorWhite

  &._valid
    &._checked
      .box
        border-color: $colorSuccess
        background: $colorSuccess
      .checkmark
        color: $colorWhite

  &._invalid
    &._checked
      .box
        border-color: $colorDanger
        background: $colorDanger
        .checkmark
          color: $colorWhite

  &._radio
    .box
      //border-color: $colorPrimary
      background: transparent
      border-radius: 50%
      .checkmark
        color: $colorPrimary

    &._valid
      &._checked
        .box
          border-color: $colorSuccess
        .checkmark
          color: $colorSuccess

    &._invalid
      &._checked
        .box
          border-color: $colorDanger
          .checkmark
            color: $colorDanger

    &._checked
      .box
        background: transparent

  &._disabled
    .box
      cursor: not-allowed
    .label
      cursor: default

  &._font_m
    .label
      @include font(t16)

  &._font_s
    .label
      @include font(t14)
</style>
