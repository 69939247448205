import store from '@/store'
import { computed, ref } from 'vue'
import useEventEmitter from '@core/hooks/useEventEmitter'
import useApiRequest from '@core/hooks/useApiRequest'
import type UserController from '@user/controllers/UserController'
import { updateProfile } from '@user-profile/services/profile'
import type { ProfileUpdateRemoteError } from '@user-profile/services/types'
import { getProfileFieldRemoteKey } from '@user-profile/helpers/converters'
import type AbstractUserProfileField from '@user-profile/models/UserProfileFields/AbstractUserProfileField'

type ErrorRemoteData = Record<string, string[]>
interface ProfileUpdateControllerEvents {
  'request-profile-update-success': unknown
  'request-profile-update-error': ErrorRemoteData
  'local-profile-update-success': unknown
}

const ProfileUpdateController = (user: ReturnType<typeof UserController>) => {
  // Event emitter
  const { $on, $off, $emit } = useEventEmitter<ProfileUpdateControllerEvents>()

  // Submit Update Form
  const updateErrors = ref<Partial<ProfileUpdateRemoteError>>({})

  const setUpdateErrors = (errors: Partial<ProfileUpdateRemoteError>) => {
    updateErrors.value = errors
  }

  const clearUpdateErrors = () => {
    updateErrors.value = {}
  }

  // Assign Error proxy to
  const _getErrorByKey = (localKey: string) => {
    return updateErrors.value[getProfileFieldRemoteKey(localKey)]
  }

  const _assignErrorPropertyHandler: ProxyHandler<AbstractUserProfileField> = {
    get(target, key: keyof AbstractUserProfileField) {
      if (key === 'formError') {
        return _getErrorByKey(target.key)
      }
      return target[key]
    }
  }

  const assignErrorProperty = (field: AbstractUserProfileField): AbstractUserProfileField => {
    return new Proxy(field, _assignErrorPropertyHandler)
  }

  // Request
  const profileUpdateRequest = useApiRequest(updateProfile)
  profileUpdateRequest.$on('success', () => {
    $emit('request-profile-update-success')
  })
  profileUpdateRequest.$on('error', errors => {
    clearUpdateErrors()
    setUpdateErrors(errors)
    $emit('request-profile-update-error', errors)
  })

  const requestSubmitForm = async (formData: FormData, userId: number = user.id.value ?? 0) => {
    await profileUpdateRequest.request({
      data: formData,
      userId
    })
  }

  // Errors & Notices
  const showErrorNotices = (errors: ErrorRemoteData) => {
    Object.values(errors).forEach(messages => {
      messages.forEach(message => {
        store.ui.notices.newWarning('Неверное заполнение профиля', message, { duration: -1 })
      })
    })
  }

  const _updateLocalProfile = () => {
    store.ui.notices.newSuccess('Изменения сохранены')
    $emit('local-profile-update-success')
  }

  $on('request-profile-update-success', () => {
    clearUpdateErrors()
    user.profile.$once('request-profile-success', _updateLocalProfile)
    user.profile.request()
  })

  $on('request-profile-update-error', errors => {
    showErrorNotices(errors)
  })

  // Output
  return {
    updateErrors,
    isLoading: computed(() => profileUpdateRequest.isLoading),
    isFullfilled: computed(() => profileUpdateRequest.isFullfilled),
    requestSubmitForm,
    setUpdateErrors,
    clearUpdateErrors,
    assignErrorProperty,
    user,

    $on,
    $off
  }
}

export default ProfileUpdateController
