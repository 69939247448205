import type { CourseReviewRemoteData } from '@courses/services/types'

const validate = (data: CourseReviewRemoteData) => {
  if (!data.id) throw new Error('Invalid Course Review data.')
}

export default class CourseReview {
  id: number
  date: Date | null
  name: string
  message: string
  rate: number

  constructor(data: CourseReviewRemoteData) {
    validate(data)

    this.id = data.id
    this.date = data.date ?? null
    this.name = data.name ?? ''
    this.message = data.message ?? ''
    this.rate = data.rate ?? 0
  }
}
