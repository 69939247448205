<template lang="pug">
abstract-attachment-block.attachmentBlockComponent(
  title="Прикреплённый фaйл"
  :label="label || item.label"
  :item="item"
  @action="onAction"
)

  template(v-slot:icon)
    ui-svg-icon(:name="iconName")
    
  template(v-slot:action-icon)
    ui-svg-icon(name="download")

</template>

<script lang="ts" setup>
import { getIconName } from '@/components/_ui/UiAttachment/helpers'
import store from '@/store'
import type Attachment from '@shared/models/Attachment'
import AbstractAttachmentBlock from './AbstractAttachmentBlock.vue'

interface Props {
  label?: string | null
  item: Attachment
}

const props = defineProps<Props>()

const iconName = getIconName(props.item.type) ?? 'documents'

const onAction = () => {
  if (props.item.url) {
    window.open(props.item.url, '_blank')
  } else {
    store.ui.notices.newWarning('Файл не найден на сервере')
  }
}
</script>
