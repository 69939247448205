import { fromJsonString } from '@shared/helpers/from-json'
import { TaskType } from '@abstract-trainer/config'
import { ValidationStatus, type IOptionWithValidation } from '@/components/_ui/types'
import type {
  SingleSelectTaskDataLocalData,
  SingleSelectTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'

import SingleSelectTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/SingleSelectTrainerTask/SingleSelectTrainerTask.vue'
import { markRaw, type Component, type UnwrapRef } from 'vue'

export default class TrainerTaskSingleSelect extends AbstractTrainerTask {
  type: TaskType.SingleSelect
  data: SingleSelectTaskDataLocalData
  component: Component

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.SingleSelect)
      throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    this.type = data.test_type

    this.data = formatData(data.data as SingleSelectTaskDataRemoteData)

    this.component = markRaw(SingleSelectTrainerTask)
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null): IOptionWithValidation[] {
    if (!answer)
      return this.data.options.map(o => ({ ...o, validationStatus: ValidationStatus.None }))
    const selectedValue = fromJsonString(answer.answer, []) as number
    return this.data.options.map(o => ({
      ...o,
      validationStatus:
        o.value !== selectedValue
          ? ValidationStatus.None
          : answer.isValid
          ? ValidationStatus.Valid
          : ValidationStatus.Invalid
    }))
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    const parsedAnswer = fromJsonString(answer?.answer, null)
    return parsedAnswer as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(
    answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null | null
  ) {
    return !!answerValue
  }
}

// Single Select
const formatData = (data: SingleSelectTaskDataRemoteData): SingleSelectTaskDataLocalData => {
  const result = {
    options: data.variant.map(o => {
      const item = {
        value: o.id,
        label: o.text,
        image: o.image ?? ''
      }
      return item
    })
  }
  return result
}
