export type SelectOptionValue = string | number | null | boolean

export interface ISelectOption<V = SelectOptionValue> {
  label: string
  value: V
  image?: string
}

export enum ValidationStatus {
  None = 'none',
  Valid = 'valid',
  Invalid = 'invalid',
  Pending = 'pending'
}

export type IOptionWithValidation<V = SelectOptionValue> = ISelectOption<V> & {
  validationStatus?: ValidationStatus
}

export interface IFile {
  label: string
  url: string
  type: string
}
