<template lang="pug">
.fileUploaderMultipleComponent
  transition-group(name="input")
    ui-file-uploader.input(
      v-for="index in counter"
      :key="index"
      v-model="modelValue[index - 1]"
      :placeholder-text="placeholderText"
      :accept="accept"
      :variant="variant"
      :type="type"
      :extensions="extensions"
      :max-size="maxSize"
    )
  .controls
    transition(name="fade")
      .text._add(
        v-if="isShowAdd"
        @click="onAddClick"
      ) + добавить ещё
    transition(name="fade")
      .text._remove(
        v-if="isShowRemove"
        @click="onRemoveClick"
      ) удалить
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

interface Props {
  modelValue: File[]
  placeholderText?: string
  variant?: 'default' | 'outlined' | 'gray' | 'white'
  type?: string
  accept?: string
  extensions?: string[]
  maxSize?: number
}

const props = withDefaults(defineProps<Props>(), {
  placeholderText: '',
  variant: 'default',
  type: 'text',
  accept: '',
  extensions: () => [],
  maxSize: 0
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: File[]): void
}>()

const counter = ref(1)

const isOnlyOne = computed(() => {
  return props.modelValue.length && counter.value === 1
})

const isShowAdd = computed(() => {
  return props.modelValue.length === counter.value
})

const isShowRemove = computed(() => {
  return isOnlyOne.value || counter.value !== 1
})

const onAddClick = () => {
  counter.value++
}

const onRemoveClick = () => {
  isOnlyOne.value ? emit('update:modelValue', []) : counter.value--
  if (props.modelValue.length !== counter.value) {
    emit('update:modelValue', props.modelValue.slice(0, -1))
  }
}
</script>

<style lang="sass" scoped>
.fileUploaderMultipleComponent
  > .input
    &:not(:first-child)
      margin-top: 2*$u

    &.input-move,
    &.input-enter-active,
    &.input-leave-active
      transition: all $appAnimationSpeed
    &.input-enter-from,
    &.input-leave-to
      opacity: 0
      height: 0
      margin: 0
    &.input-enter-to,
    &.input-leave-from
      opacity: 1
      height: 10*$u

  > .controls
    display: flex
    margin-top: 2*$u

    > .text
      @include font(t14-demibold)
      cursor: pointer
      width: min-content
      white-space: nowrap

      &._add
        color: $colorPrimary
        margin-right: auto

      &._remove
        color: $colorDanger
        margin-left: auto
</style>
