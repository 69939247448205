<template lang="pug">
.appComponent
  app-event(v-if="SHOW_EVENT_APP")
  app-header
  app-content
  app-footer
  app-modal
  app-notices
  app-audio
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import store from '@/store'
import AppHeader from '@/components/_layout/Header/AppHeader.vue'
import AppContent from '@/components/_layout/Content/AppContent.vue'
import AppFooter from '@/components/_layout/Footer/AppFooter.vue'
import AppModal from '@ui-modalQueue/components/ModalWrapper.vue'
import AppNotices from '@ui-notices/components/NoticesList/NoticesList.vue'
import AppEvent from '@/components/_layout/Event/AppEvent.vue'
import AppAudio from '@ui-audio/components/AudioWrapper.vue'
import { useRoute, useRouter } from 'vue-router'
import isAuthenticated from '@router/middleware/isAuthenticated'
import { SHOW_EVENT_APP } from './config'

onMounted(() => {
  store.ui.screen.init(window)
})

const router = useRouter()
const route = useRoute()

store.user.auth.$on('logout-success', () => {
  if (route.meta.middleware?.includes(isAuthenticated)) router.push({ name: 'Login' })
})
</script>
<style lang="sass" scoped>
.appComponent
  min-height: 100vh
  display: flex
  flex-direction: column
  overflow: hidden
</style>
