import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Course from '@courses/models/Course'
import { ADMIN_URL } from '@/config'

export default class NotificationCourseCommentAdded extends AbstractNotification {
  course: Course | null

  constructor(data: NotificationRemoteData) {
    super(data)
    this.course = data.reference_data?.course ? new Course(data.reference_data.course) : null
  }

  get title() {
    return `${this.course?.title ?? ''}`
  }

  get message() {
    return `Пользователь оставил комментарий к курсу`
  }

  onActionClick() {
    if (!!this.course) {
      window.location.href = `${ADMIN_URL}course/coursecomment/?course__pk__exact=${this.course.id}`
    } else {
      window.location.href = `${ADMIN_URL}course/coursecomment/`
    }
  }
}
