import type { CourseTopicRemoteData } from '@courses/services/types'
import CourseLesson from '@courses/models/CourseLesson'
import getStructureLabel from '@courses/helpers/course-structure-label'
import { CourseStructureItem } from '@courses/config'

interface CourseTopicConstructorData extends CourseTopicRemoteData {
  structureLabels?: string[]
  is_invariant?: boolean
}

const validate = (data: CourseTopicConstructorData) => {
  if (!data.id) throw new Error('Invalid Course Topic data.')
}

export default class CourseTopic {
  id: number
  title: string
  number: number
  structureLabel: string
  lessons: CourseLesson[]
  isInvariant: boolean

  constructor(data: CourseTopicConstructorData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
    this.number = data.number ?? 0
    this.structureLabel = getStructureLabel(data.structureLabels ?? [], CourseStructureItem.Topic)
    this.isInvariant = data.is_invariant ?? false

    this.lessons =
      data.lessons?.map(
        o =>
          new CourseLesson({
            ...o,
            structureLabels: data.structureLabels,
            is_invariant: data.is_invariant
          })
      ) ?? []
  }

  get titleFull() {
    return `${this.structureLabel} ${this.number}. ${this.title}`
  }

  get stagesCountLecture() {
    return this.lessons
      .map(o => o.stagesCountLecture)
      .reduce((prev: number, current: number) => prev + current, 0)
  }

  get stagesCountTest() {
    return this.lessons
      .map(o => o.stagesCountTest)
      .reduce((prev: number, current: number) => prev + current, 0)
  }
}
