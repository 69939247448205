import type { RouteRecordRaw } from 'vue-router'

const examplesRoutes: RouteRecordRaw[] = [
  {
    path: '/examples',
    name: 'Examples',
    component: () => import('./ExamplesHome.vue'),
    redirect: { name: 'ExamplesTypography' },
    children: [
      {
        path: 'ui',
        name: 'ExamplesUI',
        component: () => import('./ExamplesUI.vue')
      },
      {
        path: 'typography',
        name: 'ExamplesTypography',
        component: () => import('./ExamplesTypography.vue')
      },
      {
        path: 'icons',
        name: 'ExamplesIcons',
        component: () => import('./ExamplesIcons.vue')
      },
      {
        path: 'modals',
        name: 'ExamplesModals',
        component: () => import('./ExamplesModals.vue')
      },
      {
        path: 'trainer',
        name: 'ExamplesTrainer',
        component: () => import('./ExamplesTrainer.vue')
      },
      {
        path: 'emmiter',
        name: 'ExamplesEmitter',
        component: () => import('./ExamplesEmitter.vue')
      },
      {
        path: 'dadata',
        name: 'ExamplesDadata',
        component: () => import('./ExamplesDadata.vue')
      }
    ]
  }
]

export default examplesRoutes
