<template lang="pug">
abstract-attachment-block.attachmentAudioComponent(
  title="Прикреплённый аудиофaйл"
  :label="label || item.label"
  @action="onAction"
  :class="componentClasses"
  :item="item"
  :disable-check="isActive"
)

  template(v-slot:icon)
    ui-svg-icon(name="document-audio")
    
  template(v-slot:action-icon)
    ui-svg-icon(v-if="!isActive" name="play")
    ui-svg-icon(v-else name="stop")

</template>

<script lang="ts" setup>
import store from '@/store'
import type Attachment from '@shared/models/Attachment'
import { computed } from 'vue'
import AbstractAttachmentBlock from './AbstractAttachmentBlock.vue'

interface Props {
  label?: string | null
  item: Attachment
}

const props = defineProps<Props>()

const isActive = computed(
  () => props.item.url === store.ui.audio.url.value && store.ui.audio.isActive.value
)

const componentClasses = computed(() => ({ _active: isActive.value }))

const onAction = () => {
  if (props.item.url) {
    !isActive.value ? store.ui.audio.play(props.item.url) : store.ui.audio.stop()
  } else {
    store.ui.notices.newWarning('Файл не найден на сервере')
  }
}
</script>

<style lang="sass" scoped>
.attachmentAudioComponent
  &._active
    background: $colorBackground2
</style>
