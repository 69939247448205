import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Course from '@courses/models/Course'
// import router from '@/router'

export default class NotificationFlowAnnouncement extends AbstractNotification {
  course: Course
  announcementId: number

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.course) {
      throw new Error(
        'Invalid NotificationFlowAnnouncement data. `course` is empty in reference_data'
      )
    }
    if (!data.reference_data?.announcement_id) {
      throw new Error(
        'Invalid NotificationFlowAnnouncement data. Has no `announcement_id` in reference_data'
      )
    }
    this.course = new Course(data.reference_data.course)
    this.announcementId = data.reference_data.announcement_id
  }

  get title() {
    return `${this.course.title}`
  }

  get message() {
    return `Новое объявление на потоке`
  }

  onActionClick() {
    // Если текущая локация - другой курс, нужно сбросить стейт курса
    window.location.href = `/courses/${this.course.id}/announcing/${this.announcementId}/`
    // router.push({
    //   name: 'CoursesItemAnnouncingItem',
    //   params: {
    //     courseId: this.course.id,
    //     announcingId: this.announcementId
    //   }
    // })
  }
}
