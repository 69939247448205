import dayjs from 'dayjs'
import type { AppealSlotRemoteData } from '@appeals/services/types'

const validate = (data: AppealSlotRemoteData) => {
  if (!data.id || !data.date_from || !data.date_to) throw new Error('Invalid appeal slot data.')
}

export default class AppealSlot {
  id: number
  dateFrom: dayjs.Dayjs
  dateTo: dayjs.Dayjs
  constructor(data: AppealSlotRemoteData) {
    validate(data)

    this.id = data.id
    this.dateFrom = dayjs(data.date_from)
    this.dateTo = dayjs(data.date_to)
  }
}
