import type dayjs from 'dayjs'

const getDatesFormatted = (from: dayjs.Dayjs | null, to: dayjs.Dayjs | null): string | null => {
  if (from && to) {
    const fromFormat = from.year() === to.year() ? 'DD MMMM' : 'DD MMMM YYYY'
    return `${from.format(fromFormat)} - ${to.format('DD MMMM YYYY')}`
  }
  if (from && !to) {
    return `С ${from.format('DD MMMM YYYY')}`
  }
  if (!from && to) {
    return `По ${to.format('DD MMMM YYYY')}`
  }
  return null
}

export default getDatesFormatted
