<template lang="pug">
template(v-if="isWebPage(item)")
  component(
    :is="UiAttachmentWeb"
    :item="item"
  )

template(v-else)
  component(
    :is="item.isAudio ? UiAttachmentAudio : UiAttachmentFile"
    :item="item"
  )
</template>

<script lang="ts" setup>
import UiAttachmentWeb from './components/UiAttachmentWeb.vue'
import UiAttachmentFile from './components/UiAttachmentFile.vue'
import UiAttachmentAudio from './components/UiAttachmentAudio.vue'
import type Attachment from '@shared/models/Attachment'
import WebPage from '@web-pages/models/WebPage'

interface Props {
  item: Attachment | WebPage
}

defineProps<Props>()

const isWebPage = (item: Props['item']): item is WebPage => {
  return item instanceof WebPage
}
</script>
