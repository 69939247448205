import { TaskType } from '@abstract-trainer/config'
import type { TrainerTaskRemoteData } from '@abstract-trainer/services/types'
import { isTaskDataEmpty } from './AbstractTrainerTask'
import TrainerTaskDefault from './TrainerTaskDefault'
import TrainerTaskFileUpload from './TrainerTaskFileUpload'
import TrainerTaskMatch from './TrainerTaskMatch'
import TrainerTaskMultipleSelect from './TrainerTaskMultipleSelect'
import TrainerTaskSingleSelect from './TrainerTaskSingleSelect'
import TrainerTaskSort from './TrainerTaskSort'
import TrainerTaskTableFill from './TrainerTaskTableFill'
import TrainerTaskTextFill from './TrainerTaskTextFill'
import TrainerTaskTextInputFull from './TrainerTaskTextInputFull'
import TrainerTaskTextInputShort from './TrainerTaskTextInputShort'
import TrainerTaskTextSelect from './TrainerTaskTextSelect'
import TrainerTaskIframe from './TrainerTaskIframe'
import TrainerTaskImage from '@abstract-trainer/models/tasks/TrainerTaskImage'

const tasksWithPossibleEmptyData: TaskType[] = [TaskType.FileUpload, TaskType.TextInput]

export function createTrainerTask(data: TrainerTaskRemoteData) {
  if (data.test_type !== undefined) {
    if (!tasksWithPossibleEmptyData.includes(data.test_type)) {
      // Задание для ответа файлом поддерживает пустую структуру
      if (isTaskDataEmpty(data.data)) return new TrainerTaskDefault(data)
    }
  }

  switch (data.test_type) {
    case TaskType.SingleSelect:
      return new TrainerTaskSingleSelect(data)

    case TaskType.MultipleSelect:
      return new TrainerTaskMultipleSelect(data)

    case TaskType.TextInput:
      return new TrainerTaskTextInputFull(data)

    case TaskType.TextInputShort:
      return new TrainerTaskTextInputShort(data)

    case TaskType.TextFill:
      return new TrainerTaskTextFill(data)
    case TaskType.TextSelect:
      return new TrainerTaskTextSelect(data)

    case TaskType.FileUpload:
      return new TrainerTaskFileUpload(data)

    case TaskType.Image:
      return new TrainerTaskImage(data)

    case TaskType.Match:
      return new TrainerTaskMatch(data)

    case TaskType.Sort:
      return new TrainerTaskSort(data)

    case TaskType.TableFill:
      return new TrainerTaskTableFill(data)

    case TaskType.Iframe:
      return new TrainerTaskIframe(data)

    default:
      return new TrainerTaskDefault(data)
  }
}
