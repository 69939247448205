<template lang="pug">
.sortTrainerTaskComponent
  draggable.items(
    :list="localAnswer"
    item-key="value"
    :draggable="isDisabled ? 'none' : '.item'"
  )
    template(#item="{element}")
      sort-trainer-task-item.item(
        :item="getOption(element)"
        :is-disabled="isDisabled"
      )
</template>

<script lang="ts" setup>
import { ValidationStatus } from '@/components/_ui/types'
import type { IOptionWithValidation } from '@/components/_ui/types'
import type { TrainerTaskSortControllerType } from '@abstract-trainer/controllers/TrainerTaskController'
import { computed, ref, watch } from 'vue'
import draggable from 'vuedraggable'
import SortTrainerTaskItem from './SortTrainerTaskItem.vue'

interface Props {
  task: ReturnType<TrainerTaskSortControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()
const options = computed(() => props.task.optionsWithValidateStatus.value)

const localAnswer = ref<number[]>(props.task.answer.localValue.value ?? [])
watch(props.task.answer.localValue, val => val && (localAnswer.value = val), { deep: true })

const getOption = (value: number) => {
  const option = options.value.filter(o => o.value === value)[0] as IOptionWithValidation<number>
  if (!props.highlightValidation) option.validationStatus = ValidationStatus.None
  return option
}
</script>

<style lang="sass" scoped>
.sortTrainerTaskComponent
  > .items
    width: fit-content

    > .item
      &:not(:last-child)
        margin-bottom: 2*$u
</style>
