<template lang="pug">
.sortingComponent(
  @click="onClick"
  :class="sortClasses"
)
  .arrow
    ui-svg-icon(name="sort-arrow-up")
  .arrow
    ui-svg-icon(name="sort-arrow-down")
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  modelValue?: boolean | null
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const onClick = () => {
  emit('update:modelValue', !props.modelValue)
}

const sortClasses = computed(() => {
  return {
    _up: props.modelValue,
    _down: !props.modelValue && props.modelValue !== null
  }
})
</script>

<style lang="sass" scoped>
.sortingComponent
  display: flex
  cursor: pointer

  &._up
    .arrow:nth-child(1)
      opacity: 1

  &._down
    .arrow:nth-child(2)
      opacity: 1

  .arrow
    width: 2*$u
    color: $colorWhite
    opacity: 0.6
    transition: $appAnimationSpeed
</style>
