import Trainer from '@courses-trainer/models/Trainer'
import { CourseStageType, CourseStructureItem } from '@courses/config'
import type { CourseStageRemoteData } from '@courses/services/types'
import Attachment from '@shared/models/Attachment'
import CourseStageOfflineInfo from './CourseStageOfflineInfo'
import CourseStageWebinar from './CourseStageWebinar'
import WebPage from '@web-pages/models/WebPage'
import getStructureLabel from '@courses/helpers/course-structure-label'

interface CourseStageConstructorData extends CourseStageRemoteData {
  structureLabels?: string[]
  is_invariant?: boolean
}

const validate = (data: CourseStageConstructorData) => {
  if (!data.id) throw new Error('Invalid Course Stage data.')
}

export default class CourseStage {
  id: number
  title: string
  description: string
  number: number
  type: CourseStageType
  structureLabel: string
  trainer: Trainer | null
  theoryHtml: string
  videoUrl: string
  attachments: Attachment[]
  offlineInfo: CourseStageOfflineInfo | null
  webinar: CourseStageWebinar | null
  daysToDeadline: number
  materialsWeb: WebPage[]
  selfAssessmentInstructionUrl: string | null
  isInvariant: boolean | undefined
  interviewId: number | null

  constructor(data: CourseStageConstructorData) {
    validate(data)

    this.id = data.id
    this.title = data.name ?? ''
    this.description = data.description ?? ''
    this.number = data.number ?? 0
    this.type = data.stage_type ?? CourseStageType.Lecture
    this.structureLabel = getStructureLabel(data.structureLabels ?? [], CourseStructureItem.Stage)
    this.trainer = data.quizes?.length ? new Trainer(data.quizes[0]) : null
    this.theoryHtml = data.theory ?? ''
    this.videoUrl = data.embed_video ?? ''
    this.attachments = data.materials ? data.materials.map(o => new Attachment(o)) : []
    this.offlineInfo = data.offline ? new CourseStageOfflineInfo(data.offline) : null
    this.webinar = data.online ? new CourseStageWebinar(data.online) : null
    this.daysToDeadline = data.deadline || 0
    this.materialsWeb = data.materials_web?.map(o => new WebPage(o)) ?? []
    this.isInvariant = data.is_invariant

    this.selfAssessmentInstructionUrl = data.self_assessment_instruction ?? null
    this.interviewId = data.interview_id ?? null
  }

  get titleFull() {
    return `${this.structureLabel} ${this.number}. ${this.title}`
  }

  get isLecture() {
    return this.type === CourseStageType.Lecture
  }

  get isTrainerTest() {
    return this.type === CourseStageType.Trainer
  }

  get isTest() {
    return [
      CourseStageType.Test,
      CourseStageType.FinalTest,
      CourseStageType.Control,
      CourseStageType.SelfAssessment
    ].includes(this.type)
  }

  get isFinalTest() {
    return this.type === CourseStageType.FinalTest
  }

  get isCurrentControl() {
    return this.type === CourseStageType.Control
  }

  get isIntermediateTest() {
    return this.type === CourseStageType.Test
  }

  get isQuestionnaire() {
    return this.type === CourseStageType.Questionnaire
  }

  get isSelfAssessment() {
    return this.type === CourseStageType.SelfAssessment
  }
}
