import AbstractNotification from '@notifications/models/AbstractNotification'
import type { NotificationRemoteData } from '@notifications/services/types'
import { NotificationType } from '@notifications/config'

type NotificationWrongData = NotificationRemoteData & {
  title: string
  message?: string
}

export default class NotificationWrong extends AbstractNotification {
  _title: string
  _message: string

  constructor(data: NotificationWrongData) {
    super(data)
    this.type = NotificationType.Wrong
    this._title = data.title
    this._message = data.message ?? 'Уведомление с ошибкой.'
  }

  get title() {
    return this._title
  }

  get message() {
    return this._message
  }

  onActionClick() {
    return
  }
}
