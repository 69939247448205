<template lang="pug">
.inlineTextInputComponent(:class="componentClasses")
  input.input(
    ref="input"
    :value="modelValue"
    :placeholder="placeholderText"
    @input="onInput"
    :type="type"
    :disabled="isDisabled"
  )
  .ghost {{ modelValue }}
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ValidationStatus } from '@/components/_ui/types'

interface Props {
  placeholderText?: string
  modelValue: string | number | null
  variant?: 'default' | 'outlined' | 'gray' | 'white'
  type?: string
  isDisabled?: boolean
  validationStatus?: ValidationStatus | null
}

const props = withDefaults(defineProps<Props>(), {
  placeholderText: '',
  modelValue: '',
  variant: 'default',
  type: 'text',
  validationStatus: ValidationStatus.None
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const componentClasses = computed(() => [
  `_${props.variant}`,
  {
    _disabled: props.isDisabled,
    _valid: props.validationStatus === ValidationStatus.Valid,
    _invalid: props.validationStatus === ValidationStatus.Invalid
  }
])
const onInput = (e: Event) => {
  if (props.isDisabled) return
  const target = e.target as HTMLInputElement
  emit('update:modelValue', target.value)
}

const input = ref<HTMLInputElement | null>(null)
</script>

<style lang="sass" scoped>
.inlineTextInputComponent
  display: inline-block
  vertical-align: middle
  position: relative
  height: 8*$u
  max-width: 100%

  > .input
    display: block
    background-color: transparent
    border: none
    padding: 0
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border-bottom: 2px solid $colorPrimary

    &:focus + .ghost
      padding-right: 1*$u

  > .ghost
    display: block
    height: 100%
    min-width: 40px
    max-width: 100%
    visibility: hidden
    white-space: nowrap
    overflow: hidden

  &._disabled
    cursor: not-allowed

  &._valid
    > .input
      background-color: $colorCalendar3
      border-color: $colorSecondary

  &._invalid
    > .input
      background-color: $colorCalendar1
      border-color: $colorDanger
</style>
