export enum AppealsItemStatus {
  Pending = 1,
  Appointed = 2,
  Active = 3,
  Finished = 4
}

export const APPEAL_ITEM_STATUS_TITLES: Record<AppealsItemStatus, string> = {
  [AppealsItemStatus.Pending]: 'Ждет рассмотрения',
  [AppealsItemStatus.Appointed]: 'Назначена',
  [AppealsItemStatus.Active]: 'Активна',
  [AppealsItemStatus.Finished]: 'Завершена'
}

export const CENTRIFUGE_HOST = <string>import.meta.env.VITE_APP_CENTRIFUGE_HOST
