import { computed } from 'vue'
import type UserController from '@user/controllers/UserController'
import { NavigationItem } from '@nav/config'
type NavigationMenu = NavigationItem[]

const COMMON_MENU_ITEMS = [
  NavigationItem.Knowledges,
  NavigationItem.Simulators,
  NavigationItem.News,
  NavigationItem.FAQ
]

const NAVIGATION_MENU_NOT_AUTH = [
  NavigationItem.Courses,
  NavigationItem.Diagnostics,
  ...COMMON_MENU_ITEMS
]

const NAVIGATION_MENU_AUTH_STUDENT = [
  NavigationItem.CabinetDashboard,
  NavigationItem.CabinetCourses,
  NavigationItem.Diagnostics,
  NavigationItem.CabinetCalendar,
  NavigationItem.CabinetGroups,
  ...COMMON_MENU_ITEMS
]

const NAVIGATION_MENU_AUTH_CURATOR = [
  NavigationItem.CabinetDashboard,
  NavigationItem.CabinetCourses,
  NavigationItem.CabinetCalendar,
  NavigationItem.CabinetGroups,
  NavigationItem.CabinetStudents,
  ...COMMON_MENU_ITEMS
]

const NAVIGATION_MENU_AUTH_TUTOR = [
  NavigationItem.CabinetDashboard,
  NavigationItem.CabinetCourses,
  NavigationItem.CabinetCalendar,
  NavigationItem.CabinetGroups,
  NavigationItem.CabinetTasks,
  NavigationItem.CabinetAppeals,
  NavigationItem.SelfAssessments,
  ...COMMON_MENU_ITEMS
]

const NAVIGATION_MENU_AUTH_DIRECTOR = [
  NavigationItem.CabinetCourses,
  NavigationItem.CabinetGroups,
  NavigationItem.CabinetStudents,
  ...COMMON_MENU_ITEMS
]

const useNavigation = (user: ReturnType<typeof UserController>) => {
  const defineNavigationMenu = () => {
    switch (true) {
      default:
      case !user.isAuthenticated.value:
        return NAVIGATION_MENU_NOT_AUTH

      case user.profile.data.value?.isStudent:
        return NAVIGATION_MENU_AUTH_STUDENT

      case user.profile.data.value?.isCurator:
        return NAVIGATION_MENU_AUTH_CURATOR

      case user.profile.data.value?.isTutor:
        return NAVIGATION_MENU_AUTH_TUTOR

      case user.profile.data.value?.isDirector:
        return NAVIGATION_MENU_AUTH_DIRECTOR
    }
  }

  const items = computed<NavigationMenu>(defineNavigationMenu)

  const mainItems = computed<NavigationMenu>(() => items.value.filter(o => !o.inDots.value))

  const dotsItems = computed<NavigationMenu>(() => items.value.filter(o => o.inDots.value))

  return {
    items,
    mainItems,
    dotsItems
  }
}

export default useNavigation
