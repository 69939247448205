import { ref } from 'vue'
import Notice from '@ui-notices/models/Notice'
import { NoticeType, type NoticeConstructorData } from '@ui-notices/models/Notice'

export default function NoticesController() {
  const items = ref<Notice[]>([])
  let ignoreDuplicates = true
  const setIgnoreDuplicates = (value: boolean) => {
    ignoreDuplicates = value
  }

  const add = (data: NoticeConstructorData) => {
    if (ignoreDuplicates) {
      const _tempSet = new Set()
      items.value.forEach(n => {
        _tempSet.add(`${n.title}_${n.content}`)
      })
      if (_tempSet.has(`${data.title}_${data.content}`)) return
    }
    items.value.push(new Notice(data))
  }

  const newSuccess = (title: string, content?: string, options?: NoticeConstructorData) => {
    add({
      type: NoticeType.Success,
      title,
      content,
      ...options
    })
  }

  const newInfo = (title: string, content?: string, options?: NoticeConstructorData) => {
    add({
      type: NoticeType.Info,
      title,
      content,
      ...options
    })
  }

  const newWarning = (title: string, content?: string, options?: NoticeConstructorData) => {
    add({
      type: NoticeType.Warning,
      title,
      content,
      ...options
    })
  }

  const newError = (title: string, content?: string, options?: NoticeConstructorData) => {
    add({
      type: NoticeType.Error,
      title,
      content,
      duration: -1,
      ...options
    })
  }

  const remove = (id: string) => {
    items.value = items.value.filter(n => n.id !== id)
  }

  const clean = () => {
    items.value = []
  }

  return {
    items,
    setIgnoreDuplicates,
    newSuccess,
    newInfo,
    newError,
    newWarning,
    remove,
    clean
  }
}
