import dayjs from 'dayjs'
import type { AttachmentRemoteData } from './types'
import UserProfile from '@user-profile/models/UserProfile'
import type { IFile } from '@/components/_ui/types'
import { getLabelFromUrl } from '@shared/helpers/getLabelFromUrl'

const validate = (data: AttachmentRemoteData) => {
  if (!data.file) throw new Error('Invalid attachment data.')
}

export default class Attachment implements IFile {
  id: number
  url: string
  accessUrl: string | null
  label: string
  type: string
  owner: UserProfile | null
  dateCreated: dayjs.Dayjs | null
  constructor(data: AttachmentRemoteData) {
    validate(data)

    this.id = data.id ?? 0
    this.url = data.file ?? ''
    this.accessUrl = data.file_access ?? null
    this.label = data.name ?? getLabelFromUrl(this.url)
    this.type = data.file_type ?? ''
    this.owner = data.owner ? new UserProfile(data.owner) : null
    this.dateCreated = data.created ? dayjs(data.created) : null
  }

  get formattedDateCreated() {
    return this.dateCreated ? this.dateCreated.format('DD MMMM YYYY') : null
  }

  get isAudio() {
    return this.type.includes('audio/')
  }
}

export function isAttachmentRemoteData(obj: unknown): obj is AttachmentRemoteData {
  return !!(obj as AttachmentRemoteData)?.id && !!(obj as AttachmentRemoteData)?.file
}

export function createAttachment(data: AttachmentRemoteData) {
  return new Attachment(data)
}
