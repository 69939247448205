import Course from '@courses/models/Course'
import CourseFlow from '@courses/models/CourseFlow'
import type { GroupRemoteData } from '@groups/services/types'

const validate = (data: GroupRemoteData) => {
  // if (!data.id) throw new Error('Invalid group data.')
}

export default class Group {
  id: number
  title: string
  course: Course | null
  flow: CourseFlow | null
  avatarUrl: string | null
  membersCount: number

  constructor(data: GroupRemoteData) {
    validate(data)
    this.id = data.id ?? null
    this.title = data.name ?? ''
    this.course = data.course ? new Course(data.course) : null
    this.flow = data.flow ? new CourseFlow(data.flow) : null
    this.avatarUrl = data.avatar ?? null
    this.membersCount = data.member_count ?? 0
  }
}
