<template lang="pug">
.searchComponent
  input.input(
    :value="modelValue"
    :placeholder="placeholderText"
    @keyup.enter="onEnterKeyup"
    @keyup.esc="onEscKeyup"
    ref="inputRef"
  )
  .button(@click="onButtonClick")
    .icon
      ui-svg-icon(name="search")
</template>

<script lang="ts" setup>
import { ref } from 'vue'

interface Props {
  placeholderText?: string
  modelValue: string
}
withDefaults(defineProps<Props>(), {
  placeholderText: 'Поиск по названию',
  modelValue: ''
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const inputRef = ref<HTMLInputElement | null>(null)

const emitUpdate = (val: string) => {
  emit('update:modelValue', val)
}

const onEnterKeyup = () => {
  emitUpdate(inputRef.value?.value ?? '')
}
const onEscKeyup = () => {
  emitUpdate('')
}
const onButtonClick = () => {
  emitUpdate(inputRef.value?.value ?? '')
}
</script>

<style lang="sass" scoped>
.searchComponent
  display: flex
  border: 1px solid $colorGray
  background: $colorWhite
  border-radius: $brXS
  overflow: hidden

  .input
    border: 0
    width: 100%
    padding: 2.25*$u 0 2.25*$u 2.25*$u
    @include font('t14')
    color: $colorText

  .button
    display: flex
    align-items: center
    justify-content: center
    padding: 0 4.5*$u
    color: $colorGray
    cursor: pointer
    transition: $appAnimationSpeed
    &:hover
      color: $colorGray2
    .icon
      width: 5*$u
      height: 5*$u
</style>
