<template lang="pug">
.fileUploadTrainerTaskComponent
  template(v-if="!isDisabled || localAnswer")
    .title Загрузите файл-ответ
    ui-file-uploader.input(
      v-model="localAnswer"
      :is-disabled="isDisabled"
      placeholder-text="Загрузите ответ"
      :max-size="maxSize * 1024"
      :extensions="extensions"
    )
    .hint Размер файла не должен превышать {{ maxSize / 1024 }} Мб<br/>Разрешенные типы файлов: {{ formats }}
  ui-attachment-file.attachment(
    v-if="uploadedFile"
    :item="createAttachment(uploadedFile)"
    :label="uploadedFile.name ?? 'Загруженный ответ'"
  )
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type { TrainerTaskFileUploadControllerType } from '@abstract-trainer/controllers/TrainerTaskController'
import { createAttachment } from '@shared/models/Attachment'

interface Props {
  task: ReturnType<TrainerTaskFileUploadControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()

const localAnswer = ref<File | null>(null)
watch(localAnswer, props.task.answer.setLocalValue)

const uploadedFile = computed(() => props.task.answer.data.value?.uploadedFile || null)

const maxSize = computed(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return props.task.data.quizData?.max_size
})
const extensions = computed(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let quizDataTypes = props.task.data.quizData.types
  let extensions = []
  for (let i = 0; i < quizDataTypes.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    extensions[i] = {
      // 'image/jpeg': 'jpg',
      // 'image/png': 'png',
      // 'application/pdf': 'pdf',
      // 'text/plain': 'txt'
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.oasis.opendocument.text': 'odt',
      'application/pdf': 'pdf',
      'text/plain': 'txt',
      'image/jpeg': 'jpeg',
      'image/jpg': 'jpg',
      'image/png': 'png',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx'
    }[quizDataTypes[i]]
  }
  //.doc .docx .odt pdf .txt .jpeg .png .xls .xlsx .ppt .pptx
  return extensions
})
const formats = computed(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let quizDataTypes = props.task.data.quizData.types
  let formats = []
  for (let i = 0; i < quizDataTypes.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formats[i] = {
      // 'image/jpeg': 'Изображение .jpg',
      // 'image/png': 'Изображение .png',
      // 'application/pdf': 'Документ .pdf',
      // 'text/plain': 'Документ .txt'
      'application/msword': 'Документ .doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Документ .docx',
      'application/vnd.oasis.opendocument.text': 'Документ .odt',
      'application/pdf': 'Документ .pdf',
      'text/plain': 'Документ .txt',
      'image/jpeg': 'Изображение .jpeg',
      'image/jpg': 'Изображение .jpg',
      'image/png': 'Изображение .png',
      'application/vnd.ms-excel': 'Таблица .xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Таблица .xlsx',
      'application/vnd.ms-powerpoint': 'Презентация .ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'Презентация .pptx'
    }[quizDataTypes[i]]
  }
  return formats.join(', ')
})
</script>

<style lang="sass" scoped>
.fileUploadTrainerTaskComponent
  @include font(t14)

  > .title
    color: $colorPrimary
    margin-bottom: 5*$u

  > .input
    max-width: 87*$u
    margin-bottom: 5*$u

  > .hint
    color: $colorGray

  > .attachment
    margin-top: 5*$u
</style>
