import type { MiddlewareFunction } from '@router/middlewarePipeline'
import { LAST_ROUTE_STORAGE } from '@user-auth/config'

const keepLastRoute: MiddlewareFunction = ({ from, to, next, store, nextMiddleware }) => {
  if (from.fullPath) localStorage.setItem(LAST_ROUTE_STORAGE, from.fullPath)

  return nextMiddleware()
}

export default keepLastRoute
