import type { RouteRecordRaw } from 'vue-router'
import isAuthenticated from '@router/middleware/isAuthenticated'
import { accessRoles } from '@router/middleware/rolePermissions'
import { Role, STUDENT_ROLES } from '@user-profile/config'

const cabinetRoutes: RouteRecordRaw[] = [
  {
    path: '/cabinet',
    name: 'Cabinet',
    component: () => import('./CabinetHome.vue'),
    redirect: { name: 'CabinetDashboard' },
    meta: {
      middleware: [
        isAuthenticated,
        accessRoles([...STUDENT_ROLES, Role.Tutor, Role.Curator, Role.Director])
      ],
      redirectToRoute: { name: 'Home' }
    },
    children: [
      {
        path: 'dashboard',
        name: 'CabinetDashboard',
        component: () => import('./CabinetDashboard.vue')
      },
      {
        path: 'calendar',
        name: 'CabinetCalendar',
        component: () => import('./CabinetCalendar.vue')
      },
      {
        path: 'groups',
        name: 'CabinetGroups',
        component: () => import('./CabinetGroups.vue'),
        redirect: { name: 'CabinetGroupsList' },
        children: [
          {
            path: '',
            name: 'CabinetGroupsList',
            component: () => import('./CabinetGroupsList.vue')
          },
          {
            path: ':id',
            name: 'CabinetGroupsItem',
            props: route => ({ id: Number(route.params.id) }),
            component: () => import('./CabinetGroupsItem.vue')
          }
        ]
      },
      {
        path: 'students',
        name: 'CabinetStudents',
        component: () => import('./CabinetStudents.vue')
      },
      {
        path: 'tasks',
        name: 'CabinetTasks',
        component: () => import('./CabinetTasks.vue'),
        redirect: { name: 'CabinetTasksList' },
        children: [
          {
            path: '',
            name: 'CabinetTasksList',
            component: () => import('./CabinetTasksList.vue')
          },
          {
            path: ':taskId/:userId',
            name: 'CabinetTasksItem',
            props: route => ({
              taskId: Number(route.params.taskId),
              userId: Number(route.params.userId),
              taskType: route.query.type,
              quizChainStateId: Number(route.query.quizChainStateId)
            }),
            component: () => import('./CabinetTasksItem.vue')
          }
        ]
      },
      {
        path: 'appeals',
        name: 'CabinetAppeals',
        component: () => import('./CabinetAppeals.vue'),
        redirect: { name: 'CabinetAppealsList' },
        children: [
          {
            path: '',
            name: 'CabinetAppealsList',
            component: () => import('./CabinetAppealsList.vue')
          },
          {
            path: ':id',
            name: 'CabinetAppealsItem',
            props: route => ({
              id: Number(route.params.id)
            }),
            component: () => import('./CabinetAppealsItem.vue')
          }
        ]
      },
      {
        path: 'courses',
        name: 'CabinetCourses',
        component: () => import('./CabinetCourses.vue')
      }
    ]
  }
]

export default cabinetRoutes
