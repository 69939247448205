<template lang="pug">
.multipleSelectTrainerTaskComponent
  template(v-if="localAnswer !== null")
    ui-checkbox-select(
      :options="options"
      v-model="localAnswer"
      :modelValue="localAnswer"
      :is-disabled="isDisabled"
      variant="default"
      :allow-empty="true"
    )
</template>

<script lang="ts" setup>
import type { TrainerTaskMultipleSelectControllerType } from '@abstract-trainer/controllers/TrainerTaskController'
import { computed, ref, watch } from 'vue'

interface Props {
  task: ReturnType<TrainerTaskMultipleSelectControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()

const localAnswer = ref<number[]>(props.task.answer.localValue.value ?? [])
watch(props.task.answer.localValue, val => val && (localAnswer.value = val), { deep: true })
watch(localAnswer, props.task.answer.setLocalValue, { deep: true })

const options = computed(() =>
  !props.highlightValidation
    ? props.task.data.data.options
    : props.task.optionsWithValidateStatus.value
)
</script>
