import type { RouteRecordRaw } from 'vue-router'

const homepageRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./HomeLanding.vue')
  }
]

export default homepageRoutes
