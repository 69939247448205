<template lang="pug">
.tabSwitchComponent
  .items
    .item(v-for="item in items")
      tab-switch-item(
        :item="item",
        :key="item.value",
        :is-selected="modelValue === item.value",
        @select="onItemSelect"
      )
</template>

<script lang="ts" setup>
import TabSwitchItem from './UiTabSwitchItem.vue'
import type { UiTabSwitchOption } from './types'

interface Props {
  items: UiTabSwitchOption[]
  modelValue: number
}

withDefaults(defineProps<Props>(), {
  items: () => [],
  modelValue: 1
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>()

const onItemSelect = (value: number) => {
  emit('update:modelValue', value)
}
</script>

<style lang="sass" scoped>
.tabSwitchComponent
  width: fit-content
  border-radius: 99em
  background-color: $colorBackground2
  padding: .5*$u

  > .items
    display: flex
    align-items: center
    flex-wrap: wrap

    > .item
      flex: 1
</style>
