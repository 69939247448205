import { callApi } from '@core/api/apiWrapper'
import {
  AnswerType,
  type FeedbackFormData,
  type FeedbackThemeRemoteData
} from '@/modules/Feedback/services/types'

export const fetchFeedbackThemes = async () => {
  const response = await callApi<FeedbackThemeRemoteData[]>({
    method: 'get',
    url: 'feedback/theme/'
  })
  return response
}

export const sendFeedback = async (data: FeedbackFormData) => {
  const formData = new FormData()

  formData.append('theme', data.theme.toString())

  formData.append('comment', data.comment)

  if (data.answerType) formData.append('answer_type', data.answerType)

  if (data.files?.length) data.files.forEach(file => formData.append(`files`, file, file.name))

  if (data.courseTitle) formData.append('course_title', data.courseTitle)

  if (data.courseId) formData.append('course', data.courseId.toString())

  if (data.stageId) formData.append('stage', data.stageId.toString())

  if (data.regionId) formData.append('region', data.regionId.toString())

  if (data.answerType === AnswerType.Email && data.email) {
    formData.append('email', data.email)
  }

  if (data.answerType === AnswerType.Phone && data.phone) {
    const phone = data.phone[0] !== '+' ? `+${data.phone}` : data.phone
    formData.append('phone', phone)
  }

  formData.append('source_id', '0')

  formData.append('target', '0')

  const response = await callApi({
    method: 'post',
    url: 'feedback/',
    formData: true,
    data: formData
  })
  return response
}
