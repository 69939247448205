<template lang="pug">
.headerBurgerMenuLinkComponent
  router-link.link(
    @click="onLinkClick"
    :to="item.route"
    active-class="_active"
  ) {{ item.label }}
</template>

<script lang="ts" setup>
import type { NavigationItem } from '@nav/config'

interface Props {
  item: NavigationItem
}
defineProps<Props>()
const emit = defineEmits<{
  (e: 'closeBurger'): void
}>()

const onLinkClick = () => {
  emit('closeBurger')
}
</script>

<style lang="sass" scoped>
.headerBurgerMenuLinkComponent
  cursor: pointer

  .link
    @include font('t18')
    display: block
    color: $colorPrimary
    &._active
      @include font('t18-demibold')
</style>
