<template lang="pug">
.headerBurgerComponent(:class="burgerClasses")
  .line(
    v-for="index in 3"
    :key="index"
    :class="`_${index}`"
  )
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  isOpen?: boolean
  variant?: 'default' | 'white'
}

const props = withDefaults(defineProps<Props>(), {
  isOpen: false,
  variant: 'default'
})

const burgerClasses = computed(() => [{ _opened: props.isOpen }, `_${props.variant}`])
</script>

<style lang="sass" scoped>
.headerBurgerComponent
  width: 10*$u
  height: 10*$u
  cursor: pointer
  flex-direction: column
  justify-content: space-around
  display: flex

  .line
    flex-shrink: 0
    border-radius: 22*$u
    height: $u
    transition: $appAnimationSpeed
    position: relative
    top: 0
    right: 0

  &._default
    .line
      background: $colorPrimary

  &._white
    .line
      background: $colorWhite

  &._opened
    .line
      &._1
        transform: rotate(45deg)
        top: 33.33333%
      &._2
        opacity: 0
        right: -100%
      &._3
        transform: rotate(-45deg)
        top: -33.33333%
</style>
