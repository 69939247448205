import isAuthenticated from '@router/middleware/isAuthenticated'
import type { RouteRecordRaw } from 'vue-router'
import announcingRoutes from '@courses-announcing/pages/index'

const coursesRoutes: RouteRecordRaw[] = [
  {
    path: '/courses',
    name: 'Courses',
    component: () => import('./CoursesHome.vue'),
    redirect: { name: 'CoursesList' },
    children: [
      {
        path: '',
        name: 'CoursesList',
        component: () => import('./CoursesList.vue')
      },
      {
        path: ':courseId/',
        name: 'CoursesItem',
        props: route => ({
          courseId: Number(route.params.courseId),
          courseStateId: Number(route.params.courseStateId)
        }),
        component: () => import('./CoursesItem.vue'),
        redirect: { name: 'CoursesItemLanding' },
        children: [
          {
            path: '',
            name: 'CoursesItemLanding',
            component: () => import('./CoursesItemLanding.vue')
          },
          {
            path: 'stage/:stageId',
            name: 'CoursesItemStage',
            props: route => ({
              stageId: Number(route.params.stageId)
            }),
            component: () => import('./CoursesItemStage.vue'),
            meta: {
              middleware: [isAuthenticated]
            }
          },
          {
            path: 'result',
            name: 'CoursesItemResult',
            component: () => import('./CoursesItemResult.vue'),
            meta: {
              middleware: [isAuthenticated]
            }
          },
          ...announcingRoutes
        ]
      }
    ]
  }
]

export default coursesRoutes
