import type { MiddlewareFunction } from '../middlewarePipeline'

// Redirects to login page if user is not authenticated.
const isAuthenticated: MiddlewareFunction = ({ to, next, store, nextMiddleware }) => {
  const redirect = () => next(to.meta.redirectToRoute || { name: 'Login' })

  if (!store.user.isAuthenticated.value) {
    return redirect()
  }

  return nextMiddleware()
}

export default isAuthenticated
