type Key = string | number | symbol

export default function useObjectKeyConverter<A extends Key, B extends Key>(keyMap: Record<A, B>) {
  const mapForward = new Map<A, B>()
  const mapReverse = new Map<B, A>()

  for (const [key, val] of Object.entries(keyMap) as [A, B][]) {
    mapForward.set(key, val)
    mapReverse.set(val, key)
  }

  const convertForward = <K extends Key, V>(obj: Partial<Record<K, V>>) => {
    const result: Partial<Record<B, V>> = {}

    for (const [key, val] of Object.entries(obj) as [A, V][]) {
      const k = mapForward.get(key)
      if (k) result[k] = val
    }

    return result
  }

  const convertReverse = <K extends Key, V>(obj: Partial<Record<K, V>>) => {
    const result: Partial<Record<A, V>> = {}

    for (const [key, val] of Object.entries(obj) as [B, V][]) {
      const k = mapReverse.get(key)
      if (k) result[k] = val
    }

    return result
  }

  const convertKeyForward = (key: A): B => {
    return mapForward.get(key) as B
  }

  const convertKeyReverse = (key: B): A => {
    return mapReverse.get(key) as A
  }

  const isKeyForward = (key: Key): key is A => {
    return mapForward.has(key as A)
  }

  const isKeyReverse = (key: Key): key is B => {
    return mapReverse.has(key as B)
  }

  return {
    convertForward,
    convertReverse,
    convertKeyForward,
    convertKeyReverse,
    isKeyForward,
    isKeyReverse
  }
}
