import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Course from '@courses/models/Course'
import CourseStage from '@courses/models/CourseStage'
import router from '@/router'

export default class NotificationSelfAssessmentsStudent extends AbstractNotification {
  course: Course
  stateId: number
  stage: CourseStage

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.course || !data.reference_data.state || !data.reference_data.stage) {
      throw new Error('Invalid NotificationSelfAssessmentsStudent data.')
    }
    this.course = new Course(data.reference_data.course)
    this.stateId = data.reference_data.state.id
    this.stage = new CourseStage(data.reference_data.stage)
  }

  get title() {
    return null
  }

  get message() {
    return `Доступны работы для Взаимооценивания.`
  }

  // TODO - перенести courseStateId в query (после обновления платформы на Vite4)
  onActionClick() {
    router.push({
      name: 'CoursesItemStage',
      params: {
        courseId: this.course.id,
        courseStateId: this.stateId,
        stageId: this.stage.id
      }
    })
  }
}
