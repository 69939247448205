import { ValidationStatus, type IOptionWithValidation } from '@/components/_ui/types'
import { fromJsonString } from '@shared/helpers/from-json'
import { TaskType, TRAINER_TASK_TEXT_INPUT_PLACEHOLDER } from '@abstract-trainer/config'
import type {
  NodeInput,
  NodeText,
  TextFillTaskDataLocalData,
  TextFillTaskDataRemoteData,
  TrainerTask,
  TrainerTaskLocalAnswer,
  TrainerTaskRemoteData
} from '@abstract-trainer/services/types'
import { markRaw } from 'vue'
import type { UnwrapRef, Component } from 'vue'
import type TrainerTaskAnswer from '../TrainerTaskAnswer'
import AbstractTrainerTask from './AbstractTrainerTask'
import TextFillTrainerTask from '@abstract-trainer/components/TrainerTask/TrainerTaskTypeComponents/TextFillTrainerTask/TextFillTrainerTask.vue'

export default class TrainerTaskTextFill extends AbstractTrainerTask {
  type: TaskType.TextFill
  data: TextFillTaskDataLocalData
  component: Component

  constructor(data: TrainerTaskRemoteData) {
    super(data)

    if (data.test_type !== TaskType.TextFill)
      throw new Error(`Wrong Trainer Type: ${data.test_type}`)
    this.type = data.test_type

    this.data = formatData(data.data as TextFillTaskDataRemoteData)

    this.component = markRaw(TextFillTrainerTask)
  }

  get inputNodes() {
    return this.data.nodes.filter(node => node.type === 'input') as NodeInput[]
  }

  getOptionsWithValidatedStatus(answer: TrainerTaskAnswer | null): IOptionWithValidation<number>[] {
    if (!answer) return []
    return this.data.nodes
      .filter(o => o.type === 'input')
      .map((o, idx) => ({
        value: idx,
        label: '_',
        validationStatus: !answer.validatedInputs[idx]
          ? ValidationStatus.None
          : answer.validatedInputs[idx].isValid
          ? ValidationStatus.Valid
          : ValidationStatus.Invalid
      }))
  }

  getLocalAnswer<T extends TrainerTask>(answer: TrainerTaskAnswer | null) {
    const parsedAnswer = fromJsonString(answer?.answer, []) as string[]
    const result = parsedAnswer.length
      ? [...parsedAnswer]
      : new Array(this.inputNodes.length).fill(null)
    return result as UnwrapRef<TrainerTaskLocalAnswer<T>>
  }

  hasLocalAnswer<T extends TrainerTask>(answerValue: UnwrapRef<TrainerTaskLocalAnswer<T>> | null) {
    if (!answerValue) return false
    return !!(<string[]>answerValue).filter(i => !!i).length
  }
}

const formatData = (data: TextFillTaskDataRemoteData): TextFillTaskDataLocalData => {
  const resultArray: (NodeText | NodeInput)[] = []
  const textArray = data.text.split(TRAINER_TASK_TEXT_INPUT_PLACEHOLDER)
  let inputIndex = 0
  textArray.forEach((el, index, arr) => {
    resultArray.push({
      type: 'text',
      value: el
    })
    if (index < arr.length - 1) {
      const node: NodeInput = {
        type: 'input',
        index: inputIndex,
        options: []
      }
      if (data.variant)
        node.options = data.variant[inputIndex].map(o => ({ label: o.text, value: o.id }))
      resultArray.push(node)
      inputIndex++
    }
  })
  return { nodes: resultArray }
}
