import { CourseProfileId } from '@courses/config'
import type { CourseProfileRemoteData } from '@courses/services/types'

const validate = (data: CourseProfileRemoteData) => {
  if (!data.id) throw new Error('Invalid Course Profile data.')
}

export default class CourseProfile {
  id: number
  name: string

  constructor(data: CourseProfileRemoteData) {
    validate(data)

    this.id = data.id
    this.name = data.name ?? ''
  }

  get nameFormatted() {
    return '#' + this.name.charAt(0).toUpperCase() + this.name.slice(1).split(' ').join('_')
  }

  get code() {
    switch (this.id) {
      case CourseProfileId.Profile1:
        return 'course1'
      case CourseProfileId.Profile2:
        return 'course2'
      case CourseProfileId.Profile3:
        return 'course3'
      case CourseProfileId.Profile4:
        return 'course4'
      case CourseProfileId.Profile5:
        return 'course5'
      default:
        return 'course1'
    }
  }
}
