import { PUBLIC_PATH } from '@/config'

export enum TokenName {
  Auth = 'auth_token',
  Verify = 'verify_token'
}

// DPPO-1719 - Заменить соглашение о обработке персональных данных
// export const PRIVACY_POLICY_URL = 'https://education.apkpro.ru/static/themes/dppo/files/privacy.pdf'
export const PRIVACY_POLICY_URL = PUBLIC_PATH + 'assets/docs/Соглашение_Цифровая_экосистема_ДПО.pdf'

export const LAST_ROUTE_STORAGE = 'last_route_storage'

export const RETRY_CODE_TIMER = 120 // seconds
