import { CourseAccessState, CourseStageAccess, CourseStateStatus } from '@courses/config'
import generateStagesAccessMap from '@courses/helpers/course-state-stage-access-map'
import generateTrainerResultsMap, {
  type ResultGroup
} from '@courses/helpers/course-state-trainer-results-map'
import type { CourseStateStudentRemoteData } from '@courses/services/types'
import Course from './Course'
import CourseFlow from './CourseFlow'
import type { RouteLocationRaw } from 'vue-router'
import generateStructureVisibleMap, {
  type CourseVisibleStructure
} from '@courses/helpers/course-state-structure-visible-map'
import AppealRequest from '@appeals/models/AppealRequest'
import router from '@/router'

const validate = (data: CourseStateStudentRemoteData) => {
  if (!data.id) throw new Error('Course State has no ID')
  if (!data.course?.id) throw new Error('Course State has no course object')
  if (!data.flow?.id) throw new Error('Course State has no flow object')
}

export default class CourseStateStudent {
  id: number
  course: Course
  isPermitted: boolean
  currentStageId: number | null
  state: CourseStateStatus
  progress: number
  trainersCount: number
  trainersCompleted: number
  trainersCheck: number
  stagesCount: number
  stagesCompleted: number
  maxScore: number
  score: number
  accessState: CourseAccessState
  payLink: string
  showPersonalDataAlert: boolean
  stageAccessMap: Map<number, CourseStageAccess>
  trainersResultsMap: ResultGroup[]
  visibleStructure: CourseVisibleStructure
  appealRequest: AppealRequest | null
  canRequestAppeal: boolean

  flow: CourseFlow

  constructor(data: CourseStateStudentRemoteData) {
    validate(data)
    this.id = data.id
    this.course = new Course(data.course)
    this.flow = new CourseFlow(data.flow)
    this.isPermitted = data.permitted ?? false
    this.currentStageId = data.last_stage ?? null
    this.progress = data.progress ?? 0
    this.state = data.status ?? CourseStateStatus.InProgress
    this.trainersCount = data.quiz_chain_count ?? 0
    this.trainersCompleted = data.quiz_chain_passed ?? 0
    this.trainersCheck = data.quiz_check ?? 0
    this.stagesCount = data.stage_count ?? 0
    this.stagesCompleted = data.stage_passed ?? 0
    this.maxScore = data.max_value ?? 0
    this.score = data.user_value ?? 0
    this.accessState = data.access_state?.state ?? CourseAccessState.None
    this.payLink = data.access_state?.pay_link ?? ''
    this.showPersonalDataAlert = data.alert_personal_data ?? false
    this.stageAccessMap = generateStagesAccessMap(data.route ?? [], data.is_compact ?? true)
    this.trainersResultsMap = generateTrainerResultsMap(data.route ?? [], data.is_compact ?? true)

    this.visibleStructure = generateStructureVisibleMap(data.route ?? [], data.is_compact ?? true)
    this.appealRequest = data.appeal ? new AppealRequest(data.appeal) : null
    this.canRequestAppeal = !!data.can_request_appeal
  }

  get isInProgress() {
    return this.state === CourseStateStatus.InProgress
  }

  get isCompleted() {
    return (
      [CourseStateStatus.Passed, CourseStateStatus.Failed].includes(this.state) ||
      this.flow?.isFinished
    )
  }

  get isPassed() {
    return this.state === CourseStateStatus.Passed
  }

  get isFailed() {
    return (
      this.state === CourseStateStatus.Failed ||
      (this.state === CourseStateStatus.InProgress && this.flow?.isFinished)
    )
  }

  get isNew() {
    return this.progress === 0
  }

  get accessNone() {
    return this.accessState === CourseAccessState.None
  }

  get accessPending() {
    return this.accessState === CourseAccessState.Pending
  }

  get accessApproved() {
    return this.accessState === CourseAccessState.Approved
  }

  get accessNoPayment() {
    return this.accessState === CourseAccessState.NoPayment
  }

  get accessPaymentProcessing() {
    return this.accessState === CourseAccessState.PaymentProcessing
  }

  get accessPaymentSuccess() {
    return this.accessState === CourseAccessState.PaymentSuccess
  }

  get accessOpen() {
    return this.accessState === CourseAccessState.Open
  }

  get scorePercent() {
    if (this.maxScore === 0) return 0
    return Math.round((this.score / this.maxScore) * 100)
  }

  get datesFormatted(): string | null {
    return this.flow?.datesFormatted ?? null
  }

  isStageUnlocked(id: number) {
    return this.stageAccessMap.get(id) === CourseStageAccess.Unlocked
  }

  get stageAccessMapIds() {
    return [...this.stageAccessMap.keys()].map(str => +str)
  }

  getNextStageUnlocked(currentStageId: number): number | null {
    const currentStageIndex = this.stageAccessMapIds.findIndex(o => o === currentStageId)
    const nextStageId = this.stageAccessMapIds[currentStageIndex + 1]
    return nextStageId !== undefined && this.isStageUnlocked(nextStageId) ? nextStageId : null
  }

  getPrevStageUnlocked(currentStageId: number): number | null {
    const currentStageIndex = this.stageAccessMapIds.findIndex(o => o === currentStageId)
    const prevStageId = this.stageAccessMapIds[currentStageIndex - 1]
    return prevStageId !== undefined && this.isStageUnlocked(prevStageId) ? prevStageId : null
  }

  stageRoute(id: number): RouteLocationRaw | null {
    return this.isPermitted && this.isStageUnlocked(id)
      ? {
          name: 'CoursesItemStage',
          params: {
            courseId: this.course.id,
            courseStateId: this.id,
            stageId: id
          }
        }
      : null
  }

  getQuizChainStateIdByStageId(targetStageId: number) {
    if (!this.trainersResultsMap || !targetStageId) return null
    for (const resultGroup of this.trainersResultsMap) {
      for (const { stageId, quizChainStateId } of resultGroup.trainers) {
        if (stageId === targetStageId) return quizChainStateId
      }
    }
    return null
  }

  get showAnnouncing() {
    return this.isPermitted && this.flow.hasAnnouncing
  }

  get announcingRoute(): RouteLocationRaw | null {
    return this.flow.hasAnnouncing
      ? {
          name: 'CoursesItemAnnouncing',
          params: {
            courseId: this.course.id,
            courseStateId: this.id
          }
        }
      : null
  }

  goToAnnouncing() {
    if (this.announcingRoute) router.push(this.announcingRoute)
  }
}
