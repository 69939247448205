export enum NotificationType {
  TestPending = 5, // "Цепочка тестов на проверку"
  TestChecked = 6, // "Цепочка тестов проверена"
  CourseAccessStudent = 7, // "Предоставлен доступ к курсу"
  CourseAccessTutor = 8, // "Назначение тьютором на курс"
  WebinarStarted = 9, // "Начало вебинара"
  WebinarFinished = 10, // "Конец вебинара"
  WebinarVideo = 11, // "Конец вебинара с записью"
  GroupAttachment = 12, // "Загружен материал"
  CourseFinished = 13, // "Курс завершен"
  DiagnosticsPending = 14, // "Диагностика на проверку"
  DiagnosticsChecked = 15, // "Диагностика проверена"
  CourseCommenAdded = 16, // "Пользователь прокомментировал курс"
  SelfAssessmentsTutor = 17, // "Уведомление о взаимооценивании для Тьютора"
  SelfAssessmentsStudent = 18, // "Уведомление о взаимооценивании для Слушателя"
  ForumMessage = 19, // "Новое сообщение на форуме"
  NewFlowAnnouncement = 20, // "Уведомление о новом объявлении в потоке"
  Unknown = 'unknown',
  Wrong = 'wrong'
}
