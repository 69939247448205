export enum Gender {
  Female = 'F',
  Male = 'M'
}

export enum ProfileDocumentType {
  Passport = 0,
  Diploma = 1,
  Snils = 2,
  SurnameChange = 3
  // Unknown = 4
}

export enum Role {
  Admin = 'A',
  ContentManager = 'C',
  Curator = 'K',
  Manager = 'M',
  Student = 'U',
  Superadmin = 'S',
  Tutor = 'T',
  Director = 'D',
  UchOtdel = 'O',
  TechSupport = 'P'
}

export const STUDENT_ROLES = [
  Role.Admin,
  Role.Manager,
  Role.ContentManager,
  Role.Superadmin,
  Role.Student
]

export const ADMIN_ROLES = [
  Role.Admin,
  Role.Manager,
  Role.ContentManager,
  Role.Superadmin,
  Role.UchOtdel,
  Role.TechSupport
]

export const ROLE_TITLES: Record<Role, string> = {
  [Role.Admin]: 'Администратор',
  [Role.ContentManager]: 'Контент-менеджер',
  [Role.Curator]: 'Куратор',
  [Role.Manager]: 'Управляющий региона',
  [Role.Student]: 'Слушатель',
  [Role.Superadmin]: 'Супер-админ',
  [Role.Tutor]: 'Тьютор',
  [Role.Director]: 'Директор школы',
  [Role.UchOtdel]: 'Учебный отдел',
  [Role.TechSupport]: 'Техническая поддержка'
}

export enum ProfileRole {
  Pupil = 1,
  Pedagogue = 2,
  Methodist = 3,
  Director = 4,
  Teacher = 5,
  HeadTeacher = 6,
  Educator = 7,
  Student = 8,
  None = 0
}

export const PROFILE_ROLE_TITLES: Record<ProfileRole, string> = {
  [ProfileRole.Pupil]: 'Ученик',
  [ProfileRole.Pedagogue]: 'Педагог',
  [ProfileRole.Methodist]: 'Методист',
  [ProfileRole.Director]: 'Директор',
  [ProfileRole.Teacher]: 'Учитель',
  [ProfileRole.HeadTeacher]: 'Завуч',
  [ProfileRole.Educator]: 'Воспитатель',
  [ProfileRole.Student]: 'Студент',
  [ProfileRole.None]: 'Не определено'
}

export const COMMON_PROFILE_ROLES = [
  ProfileRole.Pedagogue,
  ProfileRole.Methodist,
  ProfileRole.Director,
  ProfileRole.Teacher,
  ProfileRole.HeadTeacher,
  ProfileRole.Educator,
  ProfileRole.None
]

export const PROFILE_REQUIRED_FIELDS = [
  // personal
  'firstName',
  'lastName',
  'gender',
  'birthday',
  'citizenshipId',
  'regionId',
  'municipalityId',
  'phone',
  'dataAccepted',
  // education
  'levelId',
  'diplomaSeries',
  'diplomaRegistrationNumber',
  'diplomaQualification',
  'diplomaNumber',
  'diplomaDate',
  // employment
  'organizationId',
  'position',
  'currentExperience',
  'overallExperience',
  'employeeStatusId',
  'favoriteSubjects',
  // address
  'addressPostcode',
  'addressLocality',
  'addressStreet',
  'addressHouse',
  // documents ALL
  'snils',
  'dataConfirmed',
  // 'passportDocument'
  'diplomaDocument'
]

export const MAP_PROFILE_KEYS_REMOTE_LOCAL = {
  first_name: 'firstName',
  middle_name: 'middleName',
  last_name: 'lastName',
  two_factor: 'twoFactor',
  avatar: 'avatarUrl',
  email: 'email',
  role: 'role',
  gender: 'gender',
  birthday: 'birthday',
  citizenship: 'citizenshipId',
  fo_dadata: 'federalDistrictDadata',
  region: 'regionId',
  region_dadata: 'regionDadata',
  municipalitet: 'municipalityId',
  municipalitet_dadata: 'municipalityDadata',
  pers_tel: 'phone',
  pers_tel_visible: 'isPhonePublic',
  level: 'levelId',
  diplom_series: 'diplomaSeries',
  diplom_register_number: 'diplomaRegistrationNumber',
  diplom_qualification: 'diplomaQualification',
  diplom_number: 'diplomaNumber',
  diplom_date: 'diplomaDate',
  academic_degree: 'academicDegreeId',
  academic_title: 'academicTitleId',
  diplom_first_name: 'diplomaFirstName',
  diplom_middle_name: 'diplomaMiddleName',
  diplom_last_name: 'diplomaLastName',
  organization_local_id: 'organizationId',
  organization_dadata: 'organizationDadata',
  organization_dadata_inn: 'organizationDadataINN',
  organization_dadata_full_name: 'organizationDadataFullName',
  work_experience: 'currentExperience',
  ped_experience: 'overallExperience',
  tel: 'workPhone',
  tel_visible: 'isWorkPhonePublic',
  position: 'position',
  organization_type: 'organizationType',
  employee_status: 'employeeStatusId',
  certificate_city: 'addressLocality',
  certificate_index: 'addressPostcode',
  certificate_street: 'addressStreet',
  certificate_house: 'addressHouse',
  certificate_corpus: 'addressCorpus',
  certificate_build: 'addressBuilding',
  certificate_flat: 'addressApartment',
  snils: 'snils',
  personal_data_accept: 'dataAccepted',
  personal_data_valid: 'dataConfirmed',
  vk_url: 'socialVk',
  ok_url: 'socialOk',
  documents: 'documents',
  favorite_subjects: 'favoriteSubjects',
  favorite_subjects_embed: 'favoriteSubjectsEmbed',
  profile_role: 'profileRole',
  municipalitet_type: 'municipalitetType',
  is_shnor: 'isShnor',
  action_type: 'actionType',
  civil_servant: 'civilServant',
  profile_type: 'profileType'
} as const

export const MAP_CHANGE_PASSWORD_KEYS_REMOTE_LOCAL = {
  old_password: 'oldPassword',
  new_password: 'newPassword',
  confirm_password: 'confirm'
} as const

export const SNILS_SKIP_CITIZENSHIP_IDS = [900, 901, 1]

export const PROFILE_LOCAL_KEYS = Object.values(MAP_PROFILE_KEYS_REMOTE_LOCAL)

export enum FieldComponentType {
  ProfileDocument = 0,
  Text = 1,
  Checkbox = 2,
  File = 3,
  SingleSelect = 4,
  MultipleSelect = 5,
  RadioSelect = 6,
  Date = 7,
  Number = 8,
  Dadata = 9,
  Organization = 10
}

export enum TabType {
  Personal = 0,
  Education = 1,
  Employment = 2,
  Address = 3,
  Documents = 4,
  Additional = 5
}

export enum MunicipalitetType {
  City = 1,
  Village = 2
}

export const MIN_SEARCH_SYMBOLS_REQUIRED = 10
