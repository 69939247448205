import { computed } from 'vue'
import useEventEmitter from '@core/hooks/useEventEmitter'
import ProfileController from '@user-profile/controllers/ProfileController'
import AuthController from '@user-auth/controllers/AuthController'

const UserController = (
  profileController: ReturnType<typeof ProfileController> = ProfileController(),
  authController: ReturnType<typeof AuthController> = AuthController()
) => {
  const { $on, $off } = useEventEmitter()

  const isAuthenticated = computed(() => !!profileController.data.value)

  authController.$on('login-success', () => profileController.request())
  authController.$on('logout-success', profileController.reset)

  const output = {
    id: computed(() => profileController.data.value?.id ?? null),
    profile: profileController,
    auth: authController,
    isAuthenticated,
    $on,
    $off
  }

  return output
}

export default UserController
