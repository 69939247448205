<template lang="pug">
.pageSizeComponent
  .text Показать:
  .item(
    v-for="item in items"
    :key="item.value"
    :class="{_active: item.value === props.pageSize}"
    @click="onItemClick(item.value)"
  ) {{item.label}}
</template>

<script lang="ts" setup>
interface Props {
  pageSize: number
  items?: { label: string; value: number }[]
}
const emit = defineEmits<{
  (e: 'update', value: number): void
}>()

const props = withDefaults(defineProps<Props>(), {
  items: () => [
    {
      label: '10',
      value: 10
    },
    {
      label: '20',
      value: 20
    },
    {
      label: '50',
      value: 50
    },
    {
      label: '100',
      value: 100
    },
    {
      label: 'все',
      value: 10000
    }
  ]
})

const onItemClick = (value: number) => {
  emit('update', value)
}
</script>

<style lang="sass" scoped>
.pageSizeComponent
  display: flex
  align-items: center

  > .text
    @include font(t14-demibold)
    color: $colorPrimary

  > .item
    @include font(t14)
    color: $colorPrimary
    margin-left: 3*$u
    cursor: pointer
    position: relative

    &._active
      @include font(t14-demibold)
      &:after
        content: ""
        position: absolute
        display: block
        background: $colorPrimary
        height: 2px
        width: 100%
        bottom: -0.5*$u
</style>
