import type { CourseStageOfflineInfoRemoteData } from '@courses/services/types'
import dayjs from 'dayjs'

const validate = (data: CourseStageOfflineInfoRemoteData) => {
  if (!data.start || !data.address) throw new Error('Invalid Course Stage offline info data.')
}

export default class CourseStageOfflineInfo {
  address: string
  date: dayjs.Dayjs | null
  description: string

  constructor(data: CourseStageOfflineInfoRemoteData) {
    validate(data)

    this.address = data.address ?? ''
    this.date = data.start ? dayjs(data.start) : null
    this.description = data.description ?? ''
  }
}
