import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Course from '@courses/models/Course'
import CourseStage from '@courses/models/CourseStage'
import router from '@/router'

export default class NotificationWebinarFinished extends AbstractNotification {
  course: Course
  stage: CourseStage

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.course || !data.reference_data.stage) {
      throw new Error('Invalid NotificationWebinarFinish data.')
    }
    this.course = new Course(data.reference_data.course)
    this.stage = new CourseStage(data.reference_data.stage)
  }

  get title() {
    return null
  }

  get message() {
    return `Вебинар ${this.stage.title} завершился.`
  }

  onActionClick() {
    router.push({
      name: 'CoursesItemResult',
      params: {
        courseId: this.course.id
      }
    })
  }
}
