<template lang="pug">
.audioPlayerComponent
  audio.audio(
    :src="store.ui.audio.url.value"
    controls
    autoplay
  )
  .close(@click="onClick")
    ui-svg-icon.icon(name="close" :size="4")
</template>

<script lang="ts" setup>
import store from '@/store'

const onClick = () => store.ui.audio.stop()
</script>

<style lang="sass" scoped>
.audioPlayerComponent
  position: relative
  > .audio
    display: block
    width: calc(100% - #{8*$u})

  > .close
    cursor: pointer
    position: absolute
    right: 0
    width: 7*$u
    height: 7*$u
    top: -4*$u
    border-radius: $brS
    padding: 1.5*$u
    background-color: $colorGray
    color: $colorWhite

    &:hover
      background-color: $colorPrimary
</style>
