<template lang="pug">
abstract-attachment-block.attachmentWebComponent(
  title="Прикреплённая страница"
  :label="item.title"
  :on-title-click="onClick"
  @action="onClick"
)

  template(v-slot:icon)
    ui-svg-icon.icon(
      name="documents"
      @click="onClick"
    )
    
  template(v-slot:action-icon)
    ui-svg-icon.open(name="accordion-arrow")

</template>

<script lang="ts" setup>
import type WebPage from '@web-pages/models/WebPage'
import { useRouter } from 'vue-router'
import AbstractAttachmentBlock from './AbstractAttachmentBlock.vue'

interface Props {
  item: WebPage
}

const props = defineProps<Props>()

const router = useRouter()

const onClick = () => {
  const route = router.resolve(props.item.route)
  window.open(route.href, '_blank')
}
</script>

<style lang="sass" scoped>
.attachmentWebComponent
  .open
    cursor: pointer
    grid-area: open
    color: $colorCourse3
    transform: rotate(-90deg)

  .icon
    cursor: pointer
</style>
