<template lang="pug">
.appHeaderComponent(:class="headerClasses")
  .container
    .logo(@click="goToHome")
      ui-svg-icon(:name="logoIconName" original-colors)
    .navigation
      header-navigation(
        v-if="showNavigation"
        :is-inverted-colors="isInvertedColors"
      )
      #headerNavigationAlternative(v-else)
    .user
      ui-button.loginButton(
        v-if="!isAuthenticated"
        @click="goToLogin"
        :is-loading="isLoadingLoginButton"
        :outlined="isInvertedColors"
        :color="loginButtonColor"
        icon="user"
      ) Вход в личный кабинет
      template(v-else)
        user-notifications.notifications(
          @click="closeBurgerMenu"
          :user="user"
          :variant="notificationsVariant"
        )
        user-menu.menu(
          :user="user"
          :is-inverted-colors="isInvertedColors"
        )
      header-burger.burger(
        v-if="isBurgerScreenSize"
        @click="toggleBurgerMenu"
        :is-open="isBurgerMenuOpened"
        :variant="burgerVariant"
      )

    transition(name="fade")
      header-burger-menu(
        v-if="isBurgerMenuOpened"
        @close-burger="closeBurgerMenu"
        :user="user"
        :class="burgerMenuClasses"
      )
</template>

<script lang="ts" setup>
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { computed, watch } from 'vue'
import useFlag from '@core/hooks/useFlag'
import HeaderNavigation from '@/components/_layout/Header/HeaderNavigation/HeaderNavigation.vue'
import HeaderBurger from '@/components/_layout/Header/HeaderBurger/HeaderBurger.vue'
import HeaderBurgerMenu from '@/components/_layout/Header/HeaderBurger/HeaderBurgerMenu.vue'
import UserMenu from '@user/components/UserMenu/UserMenu.vue'
import UserNotifications from '@user/components/UserNotifications/UserNotifications.vue'

const user = store.user
const isAuthenticated = computed(() => user.isAuthenticated.value)
const isInvertedColors = computed(() => route.name === 'Home')
const headerClasses = computed(() => ({ _inverted: isInvertedColors.value }))
const logoIconName = computed(() => (!isInvertedColors.value ? 'logo' : 'logo-white'))
const notificationsVariant = computed(() => (!isInvertedColors.value ? 'default' : 'white'))

// router
const route = useRoute()
const router = useRouter()
const goToHome = () => {
  closeBurgerMenu()
  router.push({ name: 'Home' })
}
const goToLogin = () => router.push({ name: 'Login' })

// loginButton
const loginButtonColor = computed(() => (isInvertedColors.value ? 'white' : 'primary'))
const isLoadingLoginButton = computed(() => user.profile.isLoading.value)

// burger
const {
  flag: isBurgerMenuOpened,
  cssClass: burgerMenuClasses,
  off: closeBurgerMenu,
  toggle: toggleBurgerMenu,
  watchFlagChange: watchBurgerChange
} = useFlag('_opened')

watchBurgerChange(store.ui.pageScroll.toggleByFlag)

const burgerVariant = computed(() => (!isInvertedColors.value ? 'default' : 'white'))

const isBurgerScreenSize = computed(() => {
  switch (store.ui.screen.size.value) {
    case 'xs':
    case 's':
      return true
    case 'm':
    case 'l':
    default:
      return false
  }
})
watch(store.ui.screen.size, value => {
  if (value === 'm') closeBurgerMenu()
})

const showNavigation = computed(() => {
  const routeNames = route.matched.map(o => o.name)
  return (
    !routeNames.includes('CoursesItemStage') &&
    !routeNames.includes('CoursesItemResult') &&
    !routeNames.includes('DiagnosticsItemTest') &&
    !routeNames.includes('DiagnosticsItemResult') &&
    !routeNames.includes('SimulatorsItemResult') &&
    !routeNames.includes('CoursesItemAnnouncingList') &&
    !routeNames.includes('CoursesItemAnnouncingItem') &&
    !routeNames.includes('ForumTopics') &&
    !routeNames.includes('ForumTopicsItem')
  )
})
</script>

<style lang="sass" scoped>
.appHeaderComponent
  background: $colorWhite
  padding: 4*$u 0
  position: relative
  height: $headerHeight
  &._inverted
    background: transparent
    color: $colorWhite

  .container
    @include appContainer
    display: flex
    align-items: center

  .logo
    height: 10*$u
    margin-right: 10*$u
    flex-shrink: 0
    cursor: pointer
    @media (max-width: $screenSizeM)
      height: initial
      width: 45*$u
      margin-right: 6*$u
    @media (max-width: $screenSizeS)
      width: 50*$u

  .navigation
    margin-right: auto
    @media (max-width: $screenSizeS)
      display: none

  .user
    margin-left: auto
    display: grid
    grid-auto-flow: column
    align-items: center
    gap: 4*$u

    .loginButton
      height: 10*$u
      @media (max-width: $screenSizeS)
        display: none

    .menu
      @media (max-width: $screenSizeS)
        display: none
</style>
