export interface FeedbackThemeRemoteData {
  id: number
  name?: string
}

export enum AnswerType {
  Email = 'email',
  Phone = 'phone'
}

export interface FeedbackFormData {
  theme: number
  comment: string
  answerType: AnswerType
  files?: File[]
  courseTitle?: string
  courseId?: number
  stageId?: number
  regionId?: number
  phone?: string | null
  email?: string | null
}
