import type {
  UserProfileAdditionalField,
  UserProfileProperties,
  UserProfileRemoteData
} from '@user-profile/services/types'
import {
  Role,
  Gender,
  STUDENT_ROLES,
  ProfileDocumentType,
  ROLE_TITLES,
  ADMIN_ROLES,
  ProfileRole,
  MunicipalitetType
} from '@user-profile/config'
import UserDocument from './UserDocument'
import Subject from '@dict/models/Subject'
import CourseStateStudent from '@courses/models/CourseStateStudent'
import Attachment, { isAttachmentRemoteData } from '@shared/models/Attachment'
import Region from '@dict/models/Region'
import Municipality from '@dict/models/Municipality'
import Organization from '@dict/models/Organization'
import FederalDistrict from '@dict/models/FederalDistrict'
import { trimSnils } from '@user-profile/helpers/trimSnils'
import BaseDictionary from '@dict/models/BaseDictionary'

const validate = (data: UserProfileRemoteData) => {
  if (!data.id) throw new Error('Invalid user data.')
  // if (!data.role) throw new Error("User's role is not set")
  // if (!Object.values(Role).includes(data?.role)) throw new Error('Invalid user role.')
  if (data.gender && !Object.values(Gender).includes(data.gender))
    throw new Error('Invalid user gender')
}

export default class UserProfile implements UserProfileProperties {
  id: number
  firstName: string | null
  middleName: string | null
  lastName: string | null
  avatarUrl: string | null
  email: string | null
  role: Role
  gender: Gender | null
  birthday: string | null
  federalDistrictDadata: string | null
  federalDistrictObj: FederalDistrict | null
  citizenshipId: number | null
  regionId: number | null
  regionObj: Region | null
  regionDadata: string | null
  municipalityId: number | null
  municipalityObj: Municipality | null
  municipalityDadata: string | null
  phone: string | null
  isPhonePublic: boolean | null
  levelId: number | null
  diplomaSeries: string | null
  diplomaRegistrationNumber: string | null
  diplomaQualification: string | null
  diplomaNumber: string | null
  diplomaDate: string | null
  academicDegreeId: number | null
  academicTitleId: number | null
  diplomaFirstName: string | null
  diplomaMiddleName: string | null
  diplomaLastName: string | null
  organizationId: number | null
  organizationObj: Organization | null
  organizationDadata: string | null
  organizationDadataINN: string | null
  organizationDadataFullName: string | null
  currentExperience: number | null
  overallExperience: number | null
  workPhone: string | null
  isWorkPhonePublic: boolean | null
  position: number | null
  positionObj: BaseDictionary | null
  organizationType: number | null
  employeeStatusId: number | null
  addressLocality: string | null
  addressPostcode: number | null
  addressStreet: string | null
  addressHouse: string | null
  addressCorpus: string | null
  addressBuilding: string | null
  addressApartment: string | null
  snils: string | null
  dataAccepted: boolean
  dataConfirmed: boolean
  socialVk: string | null
  socialOk: string | null
  documents: UserDocument[]
  favoriteSubjects: number[]
  favoriteSubjectsEmbed: Subject[]
  availableRoles: Role[]
  coursesStates: CourseStateStudent[]
  profileRole: ProfileRole
  classGrade: number | null
  twoFactor: boolean
  additional: Record<string, UserProfileAdditionalField>
  municipalitetType: MunicipalitetType | null
  isShnor: boolean | null
  actionType: number | null
  civilServant: number | null
  profileType: number | null
  alertPersonalData: boolean | null

  constructor(data: UserProfileRemoteData) {
    validate(data)
    this.id = data.id
    this.firstName = data.first_name ?? null
    this.middleName = data.middle_name ?? null
    this.lastName = data.last_name ?? null
    this.avatarUrl = data.avatar ?? null
    this.email = data.email ?? null
    this.role = data.role ?? Role.Student
    this.gender = data.gender ?? null
    this.birthday = data.birthday ?? null
    this.phone = data.pers_tel ?? null
    this.isPhonePublic = data.pers_tel_visible ?? null
    this.levelId = data.level ?? null
    this.diplomaSeries = data.diplom_series ?? null
    this.diplomaRegistrationNumber = data.diplom_register_number ?? null
    this.diplomaQualification = data.diplom_qualification ?? null
    this.diplomaNumber = data.diplom_number ?? null
    this.diplomaDate = data.diplom_date ?? null
    this.academicDegreeId = data.academic_degree ?? null
    this.academicTitleId = data.academic_title ?? null
    this.diplomaFirstName = data.diplom_first_name ?? null
    this.diplomaMiddleName = data.diplom_middle_name ?? null
    this.diplomaLastName = data.diplom_last_name ?? null
    this.currentExperience = data.work_experience ?? null
    this.overallExperience = data.ped_experience ?? null
    this.workPhone = data.tel ?? null
    this.isWorkPhonePublic = data.tel_visible ?? null
    this.position = data.position ?? null
    this.positionObj = data.position_embeded ? new BaseDictionary(data.position_embeded) : null
    this.organizationType = data.organization_type ?? null
    this.employeeStatusId = data.employee_status ?? null
    this.addressLocality = data.certificate_city ?? null
    this.addressPostcode = data.certificate_index ?? null
    this.addressStreet = data.certificate_street ?? null
    this.addressHouse = data.certificate_house ?? null
    this.addressCorpus = data.certificate_corpus ?? null
    this.addressBuilding = data.certificate_build ?? null
    this.addressApartment = data.certificate_flat ?? null
    this.snils = data.snils ? trimSnils(data.snils) : null
    this.dataAccepted = data.personal_data_accept ?? false
    this.dataConfirmed = data.personal_data_valid ?? false
    this.socialVk = data.vk_url ?? null
    this.socialOk = data.ok_url ?? null

    this.documents = data.documents ? data.documents.map(o => new UserDocument(o)) : []

    this.favoriteSubjects = data.favorite_subjects ?? []
    this.favoriteSubjectsEmbed = data.favorite_subjects_embed
      ? data.favorite_subjects_embed.map(o => new Subject(o))
      : []

    this.availableRoles = []

    this.coursesStates = data.courses ? data.courses.map(o => new CourseStateStudent(o)) : []

    this.profileRole = data.profile_role ?? ProfileRole.None

    this.classGrade = data.class_grade ? +data.class_grade : null

    this.twoFactor = data.two_factor ?? false

    this.additional = {}

    if (data.additional) {
      Object.entries(data.additional).forEach(([slug, value]) => {
        this.additional[slug] = isAttachmentRemoteData(value) ? new Attachment(value) : value
      })
    }

    this.municipalitetType = data.municipalitet_type ?? null
    this.isShnor = data.is_shnor ?? null

    this.actionType = data.action_type ?? null
    this.civilServant = data.civil_servant ?? null
    this.profileType = data.profile_type ?? null

    this.citizenshipId = data.citizenship ?? null

    // region
    this.regionId = data.region_embeded?.id ?? data.region ?? null
    this.regionObj = data.region_embeded ? new Region(data.region_embeded) : null
    this.regionDadata = this.regionObj?.nameDadata ?? null

    // municipality
    this.municipalityId = data.municipalitet_embeded?.id ?? data.municipalitet ?? null
    this.municipalityObj = data.municipalitet_embeded
      ? new Municipality(data.municipalitet_embeded)
      : null
    this.municipalityDadata = this.municipalityObj?.nameDadata ?? null

    // organization
    this.organizationId = data.organization_embeded?.id ?? data.organization ?? null
    this.organizationObj = data.organization_embeded
      ? new Organization(data.organization_embeded)
      : null
    this.organizationDadata = this.organizationObj?.title ?? null
    this.organizationDadataINN = this.organizationObj?.inn || null
    this.organizationDadataFullName = this.organizationObj?.fullTitle || null

    // federalDistrict
    this.federalDistrictObj = data.fo_embeded ? new FederalDistrict(data.fo_embeded) : null
    this.federalDistrictDadata = this.federalDistrictObj?.title ?? null

    // TRUE, if all required information in the profile is completed
    this.alertPersonalData = data.alert_personal_data || null
  }

  get fullName() {
    return `${this.lastName} ${this.firstName} ${this.middleName ?? ''}`.trim()
  }

  get isStudent() {
    return STUDENT_ROLES.includes(this.role)
  }

  get hasAccessToAdminPanel() {
    return ADMIN_ROLES.includes(this.role)
  }

  get isTutor() {
    return this.role === Role.Tutor
  }

  get isCurator() {
    return this.role === Role.Curator
  }

  get isDirector() {
    return this.role === Role.Director
  }

  get passportDocument() {
    return this.documents
      ? this.documents.find(o => o.documentType === ProfileDocumentType.Passport)
      : null
  }

  get diplomaDocument() {
    return this.documents
      ? this.documents.find(o => o.documentType === ProfileDocumentType.Diploma) ?? null
      : null
  }

  get surnameChangeDocument() {
    return this.documents
      ? this.documents.find(o => o.documentType === ProfileDocumentType.SurnameChange) ?? null
      : null
  }

  get snilsDocument() {
    return this.documents
      ? this.documents.find(o => o.documentType === ProfileDocumentType.Snils) ?? null
      : null
  }

  get canChangeRole() {
    return this.availableRoles.length >= 2
  }

  roleTitle(value?: Role) {
    return ROLE_TITLES[value ?? this.role] ?? ''
  }

  changeRole(value: Role) {
    this.role = value
  }
}
