import type { FeedbackThemeRemoteData } from '@/modules/Feedback/services/types'

const validate = (data: FeedbackThemeRemoteData) => {
  if (!data.id) throw new Error('Invalid feedback theme data.')
}
export default class FeedbackTheme {
  id: number
  label: string

  constructor(data: FeedbackThemeRemoteData) {
    validate(data)

    this.id = data.id
    this.label = data.name ?? ''
  }
}
