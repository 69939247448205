<template lang="pug">
vue-dadata.dadataInputComponent(
  ref="dadata"
  :model-value="modelValue"
  @update:model-value="updateValue"
  :model-value:suggestion="suggestion"
  @update:suggestion="updateSuggestion"
  :token="DADATA_API_KEY"
  :from-bound="fromBound"
  :to-bound="toBound"
  :location-options="locationOptions"
  :classes="inputClasses"
  :autocomplete="false"
  debounceWait="500ms"
  :placeholder="placeholderText"
  :url="url"
)

</template>

<script lang="ts" setup>
import { VueDadata } from 'vue-dadata'
import type { LocationOptions, Suggestion, BoundsType, VueDadataClasses } from 'vue-dadata'
import { DADATA_API_KEY, DADATA_API_URL } from '@/config'
import type { DadataInputType, SuggestionOrganization } from './types'
import { ref, watch } from 'vue'

interface Props {
  modelValue: string
  suggestion?: Suggestion
  fromBound?: BoundsType
  toBound?: BoundsType
  locationOptions?: LocationOptions
  variant?: 'default' | 'gray' | 'white' | 'outlined'
  placeholderText?: string
  type?: DadataInputType
}

const props = withDefaults(defineProps<Props>(), {
  suggestion: undefined,
  fromBound: undefined,
  toBound: undefined,
  locationOptions: () => ({ locations: [] }),
  variant: 'default',
  placeholderText: undefined,
  type: 'address'
})

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'update:suggestion', value: Suggestion | undefined): void
}>()

const url = props.type === 'address' ? DADATA_API_URL + '/address/' : DADATA_API_URL + '/party/'

const updateValue = (value: string) => emits('update:modelValue', value)

const inputClasses: VueDadataClasses = {
  input: `vue-dadata__input _${props.variant}`
}

// Сторонняя библиотека vue-dadata не работает с подсказками по организациям
// Надстраиваем внутренние список подсказок, чтобы у организаций выводился адрес
const dadata = ref<InstanceType<typeof VueDadata> | null>(null)

if (props.type === 'organization') {
  watch<SuggestionOrganization[] | undefined>(
    () => dadata.value?.suggestionList,
    list => {
      if (dadata.value && list) {
        list.forEach(item => {
          item.value = `${item.value} (${item.data.inn}, ${item.data.address?.value})`
        })
      }
    }
  )
}

// При выборе подсказки убираем надстройку адреса
const updateSuggestion = (suggestion: Suggestion) => {
  if (props.type === 'organization') {
    suggestion.value = suggestion.unrestricted_value
    emits('update:modelValue', suggestion.value)
  }
  emits('update:suggestion', suggestion)
}
</script>

<style lang="sass" scoped>
:deep(.vue-dadata)
  width: 100%
  user-select: none
  position: relative

:deep(.vue-dadata__input)
  width: 100%
  border: 1px solid transparent
  padding: 2.25*$u
  border-radius: $brXS
  display: flex
  align-items: center
  min-height: 10*$u
  @include font(t14)

  &._default
    background-color: $colorBackground2

  &._gray
    background-color: $colorBackground

  &._white
    background-color: $colorWhite

  &._outlined
    background-color: $colorWhite
    border: 1px solid $colorGray

// :deep(.vue-dadata__input):focus

:deep(.vue-dadata__suggestions)
  position: absolute
  z-index: $zIndexDropdownSelect
  transform: translateY(2*$u)
  width: fit-content
  display: flex
  flex-direction: column
  background-color: $colorWhite
  box-shadow: 0 0 4px rgb(0 0 0 / 35%)

:deep(.vue-dadata__suggestions-item)
  padding: 2.5*$u
  cursor: pointer
  transition: $appAnimationSpeed

:deep(.vue-dadata__suggestions-item-highlight),
:deep(.vue-dadata__suggestions-item):hover
  background-color: #ffdfbd

:deep(.vue-dadata__suggestions-item_current)
  background-color: #fff5e7
</style>
