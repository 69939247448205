import isAuthenticated from '@router/middleware/isAuthenticated'
import type { RouteRecordRaw } from 'vue-router'

const userProfileRoutes: RouteRecordRaw[] = [
  {
    path: '/profile/update',
    name: 'ProfileUpdate',
    component: () => import('./ProfileUpdate.vue'),
    props: route => ({
      isFullCompletedRequired:
        (<string>route.params.isFullCompletedRequired)?.toLocaleLowerCase() === 'true'
    }),
    meta: {
      middleware: [isAuthenticated]
    }
  },
  {
    path: '/profile/:id?',
    name: 'ProfileInfo',
    props: route => ({ userId: Number(route.params.id) }),
    component: () => import('./ProfileInfo.vue'),
    meta: {
      middleware: [isAuthenticated]
    }
  },
  {
    path: '/profile/change-password',
    name: 'PasswordChange',
    component: () => import('./ProfileChangePassword.vue'),
    meta: {
      middleware: [isAuthenticated]
    }
  }
]

export default userProfileRoutes
