<template lang="pug">
.userNotificationsDropdownItemComponent
  template(v-if="item.type !== NotificationType.Wrong")
    .date(:class="dateClasses") {{item.relativeDate}}
    .title(v-if="item.title") {{item.title}}
    .message {{item.message}}

    .actions
      ui-button.button(
        v-if="item.showActionButton"
        @click="onButtonClick"
        color="secondary"
        :outlined="true"
      ) Подробнее

      ui-button.button(
        v-if="item.showReadButton && !item.isRead"
        @click="onReadButtonClick"
        color="secondary"
        :outlined="true"
      ) Отметить прочитанным

  template(v-else)
    ui-alert(
      icon="attention"
      color="red"
      :title="item.title ?? ''"
      :message="item.message"
    )
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import NotificationsItemController from '@notifications/controllers/NotificationsItemController'
import type AbstractNotification from '@notifications/models/AbstractNotification'
import { NotificationType } from '@notifications/config'
interface Props {
  item: AbstractNotification
}
const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'action'): void
  (e: 'read'): void
}>()

const { $once, requestReadNotification } = NotificationsItemController(props.item.id)

const dateClasses = computed(() => ({ _unread: !props.item.isRead }))

const onReadButtonClick = () => {
  emit('action')
  if (!props.item.isRead) {
    requestReadNotification()
    $once('read-notification-request-success', () => emit('read'))
  }
}

const onButtonClick = () => {
  props.item.onActionClick()
  onReadButtonClick()
}
</script>

<style lang="sass" scoped>
.userNotificationsDropdownItemComponent
  > .date
    @include font('t14')
    position: relative
    color: $colorGray
    text-transform: uppercase

    &._unread
      padding-left: 4*$u

      &:before
        content: ''
        position: absolute
        top: 50%
        transform: translateY(-50%)
        left: 0
        width: 2*$u
        height: 2*$u
        background: $colorSecondary2
        border-radius: 50%

  > .title
    @include font('t14')
    color: $colorPrimary

  > .message
    margin-top: 2*$u

  > .actions
    display: flex
    flex-flow: row wrap
    align-items: center
    margin: -2*$u
    margin-top: 3*$u
    > .button
      margin: 2*$u

  @media (max-width: $screenSizeS)
    > .date
      @include font('t12')
</style>
