import { computed, ref } from 'vue'

export default function AudioController() {
  const url = ref('')
  const isActive = computed(() => !!url.value)

  const play = (value: string) => (url.value = value)

  const stop = () => (url.value = '')

  return {
    url: computed(() => url.value),
    isActive,
    play,
    stop
  }
}
