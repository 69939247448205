import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import DiagnosticsItem from '@diagnostics/models/DiagnosticsItem'
import router from '@/router'

export default class NotificationDiagnosticsChecked extends AbstractNotification {
  diagnosticsItem: DiagnosticsItem

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.diagnostic) {
      throw new Error('Invalid NotificationDiagnosticsChecked data.')
    }
    this.diagnosticsItem = new DiagnosticsItem(data.reference_data.diagnostic)
  }

  get title() {
    return null
  }

  get message() {
    return 'Диагностическое исследование проверено.'
  }

  onActionClick() {
    router.push({
      name: 'DiagnosticsItemResult',
      params: {
        id: this.diagnosticsItem.id
      }
    })
  }
}
