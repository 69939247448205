import type { App } from 'vue'
import type { RouterLink, RouterView } from 'vue-router'
import UiAlert from './UiAlert/UiAlert.vue'
import UiAttachment from './UiAttachment/UiAttachment.vue'
import UiAttachmentItem from './UiAttachment/UiAttachmentItem.vue'
import UiAttachmentFile from './UiAttachment/components/UiAttachmentFile.vue'
import UiAttachmentAudio from './UiAttachment/components/UiAttachmentAudio.vue'
import UiAttachmentWeb from './UiAttachment/components/UiAttachmentWeb.vue'
import UiAvatar from './UiAvatar/UiAvatar.vue'
import UiBackButton from './UiBackButton/UiBackButton.vue'
import UiButtonInvisible from './UiButtonInvisible/UiButtonInvisible.vue'
import UiBadge from './UiBadge/UiBadge.vue'
import UiButton from './UiButton/UiButton.vue'
import UiButtonArrow from './UiButtonArrow/UiButtonArrow.vue'
import UiChartRadar from './UiChartRadar/UiChartRadar.vue'
import UiCheckbox from './UiCheckbox/UiCheckbox.vue'
import UiErrors from './UiErrors/UiErrors.vue'
import UiFileUploader from './UiFileUploader/UiFileUploader.vue'
import UiFileUploaderMultiple from './UiFileUploaderMultiple/UiFileUploaderMultiple.vue'
import UiInput from './UiInput/UiInput.vue'
import UiLoader from './UiLoader/UiLoader.vue'
import UiMessageForm from './UiMessageForm/UiMessageForm.vue'
import UiProgress from './UiProgress/UiProgress.vue'
import UiRadialProgress from './UiRadialProgress/UiRadialProgress.vue'
import UiRadioSelect from './UiRadioSelect/UiRadioSelect.vue'
import UiCheckboxSelect from './UiCheckboxSelect/UiCheckboxSelect.vue'
import UiRangeSelect from './UiRangeSelect/UiRangeSelect.vue'
import UiSearch from './UiSearch/UiSearch.vue'
import UiSelect from './UiSelect/UiSelect.vue'
import UiSlider from './UiSlider/UiSlider.vue'
import UiSliderContainer from './UiSlider/UiSliderContainer.vue'
import { SwiperSlide as UiSliderSlide } from 'swiper/vue'
import UiSvgIcon from './UiSvgIcon/UiSvgIcon.vue'
import UiSorting from './UiSorting/UiSorting.vue'
import UiTabSwitch from './UiTabSwitch/UiTabSwitch.vue'
import UiTextarea from './UiTextarea/UiTextarea.vue'
import UiPagination from './UiPagination/UiPagination.vue'
import UiPageSize from './UiPageSize/UiPageSize.vue'
import UiYoutubeVideo from './UiYoutubeVideo/UiYoutubeVideo.vue'
import UiHtmlText from './UiHtmlText/UiHtmlText.vue'
import UiDadataInput from './UiDadataInput/UiDadataInput.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    RouterLink: typeof RouterLink
    RouterView: typeof RouterView
    UiAlert: typeof UiAlert
    UiAttachment: typeof UiAttachment
    UiAttachmentItem: typeof UiAttachmentItem
    UiAttachmentFile: typeof UiAttachmentFile
    UiAttachmentAudio: typeof UiAttachmentAudio
    UiAttachmentWeb: typeof UiAttachmentWeb
    UiAvatar: typeof UiAvatar
    UiBackButton: typeof UiBackButton
    UiButtonInvisible: typeof UiButtonInvisible
    UiBadge: typeof UiBadge
    UiButton: typeof UiButton
    UiButtonArrow: typeof UiButtonArrow
    UiChartRadar: typeof UiChartRadar
    UiCheckbox: typeof UiCheckbox
    UiErrors: typeof UiErrors
    UiFileUploader: typeof UiFileUploader
    UiFileUploaderMultiple: typeof UiFileUploaderMultiple
    UiInput: typeof UiInput
    UiLoader: typeof UiLoader
    UiMessageForm: typeof UiMessageForm
    UiProgress: typeof UiProgress
    UiRadialProgress: typeof UiRadialProgress
    UiRadioSelect: typeof UiRadioSelect
    UiCheckboxSelect: typeof UiCheckboxSelect
    UiRangeSelect: typeof UiRangeSelect
    UiSearch: typeof UiSearch
    UiSelect: typeof UiSelect
    UiSlider: typeof UiSlider
    UiSliderContainer: typeof UiSliderContainer
    UiSliderSlide: typeof UiSliderSlide
    UiSvgIcon: typeof UiSvgIcon
    UiSorting: typeof UiSorting
    UiTabSwitch: typeof UiTabSwitch
    UiTextarea: typeof UiTextarea
    UiPagination: typeof UiPagination
    UiPageSize: typeof UiPageSize
    UiYoutubeVideo: typeof UiYoutubeVideo
    UiHtmlText: typeof UiHtmlText
    UiDadataInput: typeof UiDadataInput
  }
}

export default function initializeUiKit(app: App): void {
  app.component('UiAlert', UiAlert)
  app.component('UiAttachment', UiAttachment)
  app.component('UiAttachmentItem', UiAttachmentItem)
  app.component('UiAttachmentFile', UiAttachmentFile)
  app.component('UiAttachmentAudio', UiAttachmentAudio)
  app.component('UiAttachmentWeb', UiAttachmentWeb)
  app.component('UiAvatar', UiAvatar)
  app.component('UiBackButton', UiBackButton)
  app.component('UiButtonInvisible', UiButtonInvisible)
  app.component('UiBadge', UiBadge)
  app.component('UiButton', UiButton)
  app.component('UiButtonArrow', UiButtonArrow)
  app.component('UiChartRadar', UiChartRadar)
  app.component('UiCheckbox', UiCheckbox)
  app.component('UiErrors', UiErrors)
  app.component('UiFileUploader', UiFileUploader)
  app.component('UiFileUploaderMultiple', UiFileUploaderMultiple)
  app.component('UiInput', UiInput)
  app.component('UiLoader', UiLoader)
  app.component('UiMessageForm', UiMessageForm)
  app.component('UiProgress', UiProgress)
  app.component('UiRadialProgress', UiRadialProgress)
  app.component('UiRadioSelect', UiRadioSelect)
  app.component('UiCheckboxSelect', UiCheckboxSelect)
  app.component('UiRangeSelect', UiRangeSelect)
  app.component('UiSearch', UiSearch)
  app.component('UiSelect', UiSelect)
  app.component('UiSlider', UiSlider)
  app.component('UiSliderContainer', UiSliderContainer)
  app.component('UiSliderSlide', UiSliderSlide)
  app.component('UiSvgIcon', UiSvgIcon)
  app.component('UiSorting', UiSorting)
  app.component('UiTabSwitch', UiTabSwitch)
  app.component('UiTextarea', UiTextarea)
  app.component('UiPagination', UiPagination)
  app.component('UiPageSize', UiPageSize)
  app.component('UiYoutubeVideo', UiYoutubeVideo)
  app.component('UiHtmlText', UiHtmlText)
  app.component('UiDadataInput', UiDadataInput)
}
