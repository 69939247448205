export enum DiagnosticsItemStatus {
  InProgress = 0,
  Finished = 1,
  Pending = 3
}

export enum DiagnosticsAquirementCode {
  Certificate = 'certificate',
  Analysis = 'analysis'
}

export const DIAGNOSTICS_AQUIREMENTS_DATA = {
  [DiagnosticsAquirementCode.Certificate]: {
    code: DiagnosticsAquirementCode.Certificate,
    icon: 'aquirement-certificate',
    title: 'Электронный сертификат',
    text: 'Сертификат от организации о пройденной диагностике профессиональных компетенций'
  },
  [DiagnosticsAquirementCode.Analysis]: {
    code: DiagnosticsAquirementCode.Analysis,
    icon: 'aquirement-analysis',
    title: 'Развернутый анализ исследования',
    text: 'По всем тематикам диагностики. Узнаете свои сильные и слабые стороны в исследуемой области.'
  }
}

export const MAP_CATALOG_PARAMS_KEYS_REMOTE_LOCAL = {
  profile_type: 'profileId',
  competence: 'competenceId',
  expert: 'expert',
  min_minutes: 'durationMin',
  max_minutes: 'durationMax',
  organization: 'organizationId',
  search_query: 'search',
  ordering: 'order',
  page: 'page',
  page_size: 'pageSize'
} as const
