<template lang="pug">
.textFillTrainerTaskComponent
  template(
    v-for="node in nodes"
    :key="node.index"
  )
    template(v-if="node.type === 'text'")
      .text {{ node.value }}
    template(v-else-if="node.type === 'input'")
      inline-select.select(
        v-model="localAnswer[node.index]"
        :key="node.index"
        :is-disabled="isDisabled"
        :options="node.options"
        :validation-status="getNodeValidationStatus(node)"
        placeholder-text="Выберите ответ..."
        :is-multiple="task.data.hasMultipleAnswer"
      )
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type { TrainerTaskTextSelectControllerType } from '@abstract-trainer/controllers/TrainerTaskController'
import type { NodeInput } from '@abstract-trainer/services/types'
import { ValidationStatus } from '@/components/_ui/types'
import InlineSelect from './InlineSelect.vue'

interface Props {
  task: ReturnType<TrainerTaskTextSelectControllerType>
  highlightValidation?: boolean
  isDisabled?: boolean
}
const props = defineProps<Props>()

const nodes = computed(() => props.task.data.data.nodes)

const localAnswer = ref<number[][]>(props.task.answer.localValue.value ?? [])
watch(props.task.answer.localValue, val => val && (localAnswer.value = val), { deep: true })
watch(localAnswer, props.task.answer.setLocalValue, { deep: true })

const statuses = computed(() => props.task.optionsWithValidateStatus.value)

const getNodeValidationStatus = (node: NodeInput) => {
  if (!props.highlightValidation) return ValidationStatus.None
  const statusObj = statuses.value.find(o => o.value === node.index)
  return !statusObj ? ValidationStatus.Invalid : statusObj.validationStatus || ValidationStatus.None
}
</script>

<style lang="sass" scoped>
.textFillTrainerTaskComponent
  > .text
    display: inline
    line-height: 200%
  > .select
    display: inline-block
    position: relative
    top: -1px
    margin: 0 1*$u
</style>
