<template lang="pug">
.footerSmallContainerComponent
  .text Портал ДПО
  .text._link(@click="showFeedbackModal") Заполнить форму обратной связи
  .text © ДПО, {{ year }}. Все права защищены.
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import store from '@/store'
import FeedbackModal from '@feedback/components/Modals/FeedbackModal.vue'

const year = computed(() => new Date().getFullYear())

const showFeedbackModal = () => {
  store.ui.modals.push({
    key: 'feedback-modal',
    component: FeedbackModal
  })
}
</script>

<style lang="sass" scoped>
.footerSmallContainerComponent
  @include appContainer
  display: flex
  justify-content: space-between

  > .text
    @include font('t16-demibold')
    color: $colorPrimary

    &._link
      cursor: pointer
      text-decoration: underline

  @media (max-width: $screenSizeM)
    flex-wrap: wrap

    > .text
      @include font('t14-demibold')

      &:last-child
        width: 100%
        margin-top: 3*$u

  @media (max-width: $screenSizeS)
    > .text
      @include font('t12-demibold')
</style>
