import dayjs from 'dayjs'
import type { TutorTaskRemoteData } from '@expert/services/types'
import Course from '@courses/models/Course'
import Group from '@groups/models/Group'
import UserProfile from '@user-profile/models/UserProfile'
import type { RouteLocationRaw, RouteQueryAndHash } from 'vue-router'
import { LearnType } from '@shared/config'
import DiagnosticsItem from '@diagnostics/models/DiagnosticsItem'

const validate = (data: TutorTaskRemoteData) => {
  if (!data) throw new Error('Invalid tutor task data.')
}

export default class TutorTask {
  id: number | null
  course: Course | null
  diagnosticsItem: DiagnosticsItem | null
  group: Group | null
  user: UserProfile | null
  title: string | undefined
  date: dayjs.Dayjs | null
  maxScore: number
  score: number
  maxAttempts: number
  attempts: number
  managerVisited: dayjs.Dayjs | null
  quizChainStateId: number | null

  constructor(data: TutorTaskRemoteData) {
    validate(data)

    this.id = data.quiz_chain ?? null
    this.course = data.course ? new Course(data.course) : null
    this.diagnosticsItem = data.diagnostic ? new DiagnosticsItem(data.diagnostic) : null
    this.group = data.group ? new Group(data.group) : null
    this.user = data.course_user ? new UserProfile(data.course_user) : null
    this.title = data.stage?.name ?? data.stage_name ?? data.diagnostic?.name ?? ''
    this.date = data.created ? dayjs(data.created) : null
    this.maxScore = data.max_value ?? 0
    this.score = data.user_value ?? 0
    this.maxAttempts = data.max_attempts ?? 0
    this.attempts = data.attempts ?? 0
    this.managerVisited = data.manager_visited ? dayjs(data.manager_visited) : null
    this.quizChainStateId = data.quiz_chain_state_id ?? null
  }

  get type() {
    return this.diagnosticsItem ? LearnType.Diagnostic : LearnType.Course
  }

  get singleRoute(): RouteLocationRaw {
    const query: RouteQueryAndHash['query'] = { type: this.type }
    if (this.quizChainStateId) query.quizChainStateId = this.quizChainStateId
    return {
      name: 'CabinetTasksItem',
      params: {
        taskId: this.id,
        userId: this.user?.id ?? 0
      },
      query
    }
  }
}
