<template lang="pug">
.uiSelectComponent(
  v-click-outside="onClickOutside"
  :class="[openedClass, `_${variant}`]"
)
  .handle(@click="onHandleClick")
    ui-loader(v-if="isLoading" :size="5")
    template(v-else)
      .text {{ handleText }}
      .chevron
        ui-svg-icon(name="chevron")
  transition(name="fade")
    .dropdown(v-if="isOpened")
      .inner
        template(v-if="withSearch")
          .search
            ui-input(
              variant="outlined"
              placeholder-text="Поиск...",
              v-model="searchQuery"
            )
        .options
          .option(
            v-for="(option, index) in filteredOptions"
            @click="onOptionClick(option)"
          )
            ui-select-option(
              :key="index"
              :option="option"
              :is-selected="isSelected(option)"
              :is-disabled="isDisabled || isLoading"
              :is-multiple="isMultiple"
            )
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type { SelectOptionValue, ISelectOption } from '@/components/_ui/types'
import UiSelectOption from './UiSelectOption.vue'
import useFlag from '@core/hooks/useFlag'

interface Props {
  modelValue?: number | string | boolean | SelectOptionValue | SelectOptionValue[]
  options?: ISelectOption[]
  isDisabled?: boolean
  isLoading?: boolean
  placeholderText?: string
  isMultiple?: boolean
  withSearch?: boolean
  variant?: 'default' | 'gray' | 'white' | 'outlined'
  allowEmpty?: boolean
}

const searchQuery = ref<string>('')

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  options: () => [],
  isDisabled: false,
  isLoading: false,
  placeholderText: 'Выберите...',
  isMultiple: false,
  withSearch: false,
  variant: 'default',
  allowEmpty: false
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: SelectOptionValue | SelectOptionValue[]): void
}>()

if (props.isMultiple && props.modelValue === null) emit('update:modelValue', [])

const filteredOptions = computed(() => {
  if (!props.withSearch || !searchQuery.value) return props.options
  return props.options.filter(o => o.label.toLowerCase().includes(searchQuery.value.toLowerCase()))
})

// Dropodown Logic.
const { flag: isOpened, cssClass: openedClass, off: close, toggle } = useFlag('_opened')

const onHandleClick = () => toggle()
const onClickOutside = () => close()

watch(isOpened, val => {
  if (!val) searchQuery.value = ''
})

// Select Logic.
const isSelected = (option: ISelectOption) => {
  if (props.isMultiple) {
    return !!(props.modelValue as SelectOptionValue[] | null)?.includes(option.value)
  }
  return props.modelValue === option.value
}

const selectOption = (option: ISelectOption) => {
  if (props.isMultiple) {
    const newValue = [...((props.modelValue as SelectOptionValue[] | null) ?? []), option.value]
    emit('update:modelValue', newValue)
  } else {
    emit('update:modelValue', option.value)
  }
}

const deselectOption = (option: ISelectOption) => {
  if (props.isMultiple && props.modelValue) {
    const newValue = (props.modelValue as SelectOptionValue[]).filter(o => o !== option.value)
    if (props.allowEmpty || newValue.length > 0) emit('update:modelValue', newValue)
  } else {
    if (props.allowEmpty) emit('update:modelValue', null)
  }
}

const toggleOption = (option: ISelectOption) => {
  isSelected(option) ? deselectOption(option) : selectOption(option)
}

const onOptionClick = (option: ISelectOption) => {
  if (!props.isDisabled && !props.isLoading) toggleOption(option)
  if (!props.isMultiple) close()
}

const handleText = computed(() => {
  if (props.isMultiple) {
    const modelValue = props.modelValue as SelectOptionValue[] | null
    return modelValue?.length
      ? props.options
          .filter(o => modelValue.includes(o.value))
          .map(o => o.label)
          .join(', ')
      : props.placeholderText
  }
  return props.options.find(o => isSelected(o))?.label ?? props.placeholderText
})
</script>

<style lang="sass" scoped>
.uiSelectComponent
  position: relative
  user-select: none
  width: 100%

  > .handle
    border: 1px solid transparent
    padding: 2.25*$u
    border-radius: $brXS
    display: flex
    align-items: center
    cursor: pointer
    min-height: 10*$u

    > .text
      @include font(t14)
      flex: 1
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    > .chevron
      width: 3*$u
      height: 3*$u
      flex: 0 0 3*$u
      margin-left: 3*$u
      transition: $appAnimationSpeed

  > .dropdown
    position: absolute
    top: calc(100% + #{4*$u})
    left: 50%
    transform: translateX(-50%)
    z-index: $zIndexDropdownSelect
    max-width: 100%

    > .inner
      position: relative
      background-color: $colorWhite
      width: max-content
      border-radius: $brXS
      box-shadow: 0 0 4px rgb(0 0 0 / 35%)
      min-width: 50*$u
      max-width: 100%

      &::before
        content: ''
        width: 4*$u
        height: 4*$u
        background: $colorWhite
        position: absolute
        top: -2*$u
        left: 50%
        transform: translateX(-50%) rotate(45deg)
        box-shadow: 0 0 4px rgb(0 0 0 / 35%)
        z-index: 1

      &::after
        content: ''
        width: 7*$u
        height: 3*$u
        background: $colorWhite
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        z-index: 2

      > .search
        padding: 2*$u
        position: relative
        z-index: 9

      > .options
        padding: 2*$u 0
        @include font(t14)
        max-height: 50*$u
        overflow-y: auto
        position: relative
        z-index: 9

  &._default
    > .handle
      background-color: $colorBackground2

  &._gray
    > .handle
      background-color: $colorBackground

  &._white
    > .handle
      background-color: $colorWhite

  &._outlined
    > .handle
      background-color: $colorWhite
      border: 1px solid $colorGray

  &._opened
    > .handle
      > .chevron
        transform: rotate(180deg)
</style>
