import type { NotificationRemoteData } from '@notifications/services/types'
import AbstractNotification from '@notifications/models/AbstractNotification'
import Group from '@groups/models/Group'
import router from '@/router'

export default class NotificationGroupAttachment extends AbstractNotification {
  group: Group

  constructor(data: NotificationRemoteData) {
    super(data)
    if (!data.reference_data?.group) {
      throw new Error('Invalid NotificationGroupAttachment data.')
    }
    this.group = new Group(data.reference_data.group)
  }

  get title() {
    return null
  }

  get message() {
    return `В группу ${this.group.title} добавлен материал.`
  }

  onActionClick() {
    router.push({
      name: 'CabinetGroupsItem',
      params: {
        id: this.group.id
      }
    })
  }
}
